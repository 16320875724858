import { Badge, Icon, Typography } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { AddOnItem as AddOnItemType } from "@/redux/slices/addOn/types";
import { clsx } from "@/utils";
import { format, isAfter, isEqual } from "date-fns";
import { renderAddOnIcon } from "./helpers";


interface AddOnItemProps extends React.HTMLAttributes<HTMLDivElement> {
  item: AddOnItemType;
  title: string;
  description?: string;
}

export const AddOnItem = ({ item, title, description = "", className, ...props }: AddOnItemProps) => {
  const { dateFormat } = useProfile();
  const { identifier, enabled, freeTrialPeriod, freeTrialExpiry, available, featured } = item;
  const expiryDate = new Date(freeTrialExpiry);
  const currentDate = new Date();
  const isFreeTrialValid = freeTrialExpiry && (isEqual(expiryDate, currentDate) || isAfter(expiryDate, currentDate));

  const renderBadge = () => (
    <div className="flex flex-row items-center gap-x-1">
      {!available && <Badge type="disabled" size="sm" message="Coming Soon" />}
      {featured && <Badge type="warning" size="sm" message="Recommended" />}
      {(freeTrialPeriod > 0 && (!freeTrialExpiry || isFreeTrialValid)) && <Badge type="info" size="sm" message="Free Trial Available" />}
    </div>
  );

  const renderStatus = () => {
    if (enabled) {
      return (
        <div className="flex flex-row items-center mt-1 gap-x-1.5 text-success">
          <Icon name="TickCircle" size="sm" variant="Bold" />
          <Typography variant="action">Active{isFreeTrialValid && ` (Free until ${format(expiryDate, dateFormat)})`}</Typography>
        </div>
      );     
    }

    return (
      <div className="flex flex-row items-center mt-1 gap-x-1.5 text-neutral-dark-gray">
        <Icon name="MinusCircle" size="sm" variant="Bold" />
        <Typography variant="action">Inactive</Typography>
      </div>
    );
  };

  return (
    <div className={clsx("flex flex-col group rounded-lg cursor-pointer p-2.5 gap-y-2 hover:bg-neutral-surface-gray", className)} {...props}>
      <div className="flex flex-row gap-x-2">
        <div className="flex flex-1">
          <div>
            <div className="flex items-center justify-center bg-primary-dark rounded-lg p-3 group-hover:bg-primary">
              {renderAddOnIcon(identifier)}
            </div>
          </div>
          <div className="flex flex-col ml-4">
            <div className="flex flex-row items-center gap-x-2">
              <Typography variant="action" className="group-hover:text-primary">{title}</Typography>
              {renderBadge()}
            </div>
            <Typography variant="paragraph" className="text-neutral-dark-gray group-hover:text-primary">{description}</Typography>
            {renderStatus()}
          </div>
        </div>
      </div>
    </div>
  );
};
