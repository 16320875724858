import { ReactComponent as Amex } from "@/assets/icons/amex.svg";
import { ReactComponent as AppBooking } from "@/assets/icons/app-booking.svg";
import { ReactComponent as AppGooglePlaystore } from "@/assets/icons/app-google-playstore.svg";
import { ReactComponent as AppIos } from "@/assets/icons/app-ios.svg";
import { ReactComponent as ArrowDown } from "@/assets/icons/arrow-down.svg";
import { ReactComponent as ArrowLeft2 } from "@/assets/icons/arrow-left-2.svg";
import { ReactComponent as ArrowRight2 } from "@/assets/icons/arrow-right-2.svg";
import { ReactComponent as BanBold } from "@/assets/icons/ban-bold.svg";
import { ReactComponent as BookingAcceptedNetwork } from "@/assets/icons/booking-accepted-network.svg";
import { ReactComponent as BookingAcceptedPrivateNetwork } from "@/assets/icons/booking-accepted-private-network.svg";
import { ReactComponent as BookingOffloadedNetwork } from "@/assets/icons/booking-offloaded-network.svg";
import { ReactComponent as BookingOffloadedPrivateNetwork } from "@/assets/icons/booking-offloaded-private-network.svg";
import { ReactComponent as CarSeat } from "@/assets/icons/car-seat.svg";
import { ReactComponent as Check } from "@/assets/icons/check.svg";
import { ReactComponent as Close } from "@/assets/icons/close.svg";
import { ReactComponent as DeveloperTools } from "@/assets/icons/developer-tools.svg";
import { ReactComponent as Diamond } from "@/assets/icons/diamond.svg";
import { ReactComponent as Document } from "@/assets/icons/document.svg";
import { ReactComponent as DoubleArrow } from "@/assets/icons/double-arrow.svg";
import { ReactComponent as Drag } from "@/assets/icons/drag.svg";
import { ReactComponent as EnvelopeApproved } from "@/assets/icons/envelope-approved.svg";
import { ReactComponent as EnvelopeSent } from "@/assets/icons/envelope-sent.svg";
import { ReactComponent as EnvelopeVerified } from "@/assets/icons/envelope-verified.svg";
import { ReactComponent as Envelope } from "@/assets/icons/envelope.svg";
import { ReactComponent as ExtrasBabySeat } from "@/assets/icons/extras-baby-seat.svg";
import { ReactComponent as ExtrasLuggageOrBag } from "@/assets/icons/extras-luggage-or-bag.svg";
import { ReactComponent as ExtrasOthers } from "@/assets/icons/extras-others.svg";
import { ReactComponent as ExtrasPhoneBooking } from "@/assets/icons/extras-phone-booking.svg";
import { ReactComponent as ExtrasStopOver } from "@/assets/icons/extras-stop-over.svg";
import { ReactComponent as FileJpg } from "@/assets/icons/file-jpg.svg";
import { ReactComponent as FilePdf } from "@/assets/icons/file-pdf.svg";
import { ReactComponent as FilePng } from "@/assets/icons/file-png.svg";
import { ReactComponent as FlightTracking } from "@/assets/icons/flight-tracking.svg";
import { ReactComponent as GoogleDoc } from "@/assets/icons/google-doc.svg";
import { ReactComponent as HelpCircle } from "@/assets/icons/help-circle.svg";
import { ReactComponent as Jcb } from "@/assets/icons/jcb.svg";
import { ReactComponent as Location } from "@/assets/icons/location.svg";
import { ReactComponent as Mastercard } from "@/assets/icons/mastercard.svg";
import { ReactComponent as Options } from "@/assets/icons/options.svg";
import { ReactComponent as PdfSelect } from "@/assets/icons/pdf-select.svg";
import { ReactComponent as PreferredDriver } from "@/assets/icons/preferred-driver.svg";
import { ReactComponent as QrCodeStickers } from "@/assets/icons/qr-code-stickers.svg";
import { ReactComponent as RmLogoEmblem } from "@/assets/icons/rm-logo-emblem.svg";
import { ReactComponent as SmartphoneSent } from "@/assets/icons/smartphone-sent.svg";
import { ReactComponent as SmartphoneVerified } from "@/assets/icons/smartphone-verified.svg";
import { ReactComponent as Smartphone } from "@/assets/icons/smartphone.svg";
import { ReactComponent as Thermometer } from "@/assets/icons/thermometer.svg";
import { ReactComponent as UnionPay } from "@/assets/icons/union-pay.svg";
import { ReactComponent as VehicleTypeBus } from "@/assets/icons/vehicle-type-bus.svg";
import { ReactComponent as VehicleTypeMinibus } from "@/assets/icons/vehicle-type-minibus.svg";
import { ReactComponent as VehicleTypeSedan } from "@/assets/icons/vehicle-type-sedan.svg";
import { ReactComponent as VehicleTypeSuv } from "@/assets/icons/vehicle-type-suv.svg";
import { ReactComponent as VehicleTypeTruck } from "@/assets/icons/vehicle-type-truck.svg";
import { ReactComponent as VehicleTypeVan } from "@/assets/icons/vehicle-type-van.svg";
import { ReactComponent as Visa } from "@/assets/icons/visa.svg";
import { ReactComponent as Excel } from "@/assets/icons/excel.svg";
import { ReactComponent as Rocket } from "@/assets/icons/rocket.svg";
import { IconProps, iconSizes } from "./Icon";

export const CustomIcon = ({ name, size = "md", color, ...props }: IconProps) => {
  const svgProps = {
    height: iconSizes[size] || size,
    width: iconSizes[size] || size,
    color,
  };

  switch (name) {
    case "app-booking":
      return <AppBooking {...svgProps} {...props} />;
    case "app-google-playstore":
      return <AppGooglePlaystore {...svgProps} {...props} />;
    case "app-ios":
      return <AppIos {...svgProps} {...props} />;
    case "arrow-down":
      return <ArrowDown {...svgProps} {...props} />;
    case "ban-bold":
      return <BanBold {...svgProps} {...props} />;
    case "close":
      return <Close {...svgProps} {...props} />;
    case "developer-tools":
      return <DeveloperTools {...svgProps} {...props} />;
    case "document":
      return <Document {...svgProps} {...props} />;
    case "drag":
      return <Drag {...svgProps} {...props} />;
    case "envelope-approved":
      return <EnvelopeApproved {...svgProps} {...props} />;
    case "envelope-sent":
      return <EnvelopeSent {...svgProps} {...props} />;
    case "envelope-verified":
      return <EnvelopeVerified {...svgProps} {...props} />;
    case "envelope":
      return <Envelope {...svgProps} {...props} />;
    case "extras-baby-seat":
      return <ExtrasBabySeat {...svgProps} {...props} />;
    case "extras-luggage-or-bag":
      return <ExtrasLuggageOrBag {...svgProps} {...props} />;
    case "extras-others":
      return <ExtrasOthers {...svgProps} {...props} />;
    case "extras-phone-booking":
      return <ExtrasPhoneBooking {...svgProps} {...props} />;
    case "extras-stop-over":
      return <ExtrasStopOver {...svgProps} {...props} />;
    case "file-jpg":
      return <FileJpg {...svgProps} {...props} />;
    case "file-pdf":
      return <FilePdf {...svgProps} {...props} />;
    case "file-png":
      return <FilePng {...svgProps} {...props} />;
    case "google-doc":
      return <GoogleDoc {...svgProps} {...props} />;
    case "help-circle":
      return <HelpCircle {...svgProps} {...props} />;
    case "options":
      return <Options {...svgProps} {...props} />;
    case "pdf-select":
      return <PdfSelect {...svgProps} {...props} />;
    case "qr-code-stickers":
      return <QrCodeStickers {...svgProps} {...props} />;
    case "rm-logo-emblem":
      return <RmLogoEmblem {...svgProps} {...props} />;
    case "smartphone-sent":
      return <SmartphoneSent {...svgProps} {...props} />;
    case "smartphone-verified":
      return <SmartphoneVerified {...svgProps} {...props} />;
    case "smartphone":
      return <Smartphone {...svgProps} {...props} />;
    case "car-seat":
      return <CarSeat {...svgProps} {...props} />;
    case "thermometer":
      return <Thermometer {...svgProps} {...props} />;
    case "booking-accepted-network":
      return <BookingAcceptedNetwork {...svgProps} {...props} />;
    case "booking-accepted-private-network":
      return <BookingAcceptedPrivateNetwork {...svgProps} {...props} />;
    case "booking-offloaded-network":
      return <BookingOffloadedNetwork {...svgProps} {...props} />;
    case "booking-offloaded-private-network":
      return <BookingOffloadedPrivateNetwork {...svgProps} {...props} />;
    case "check":
      return <Check {...svgProps} {...props} />;
    case "double-arrow":
      return <DoubleArrow {...svgProps} {...props} />;
    case "mastercard":
      return <Mastercard {...svgProps} {...props} />;
    case "visa":
      return <Visa {...svgProps} {...props} />;
    case "amex":
      return <Amex {...svgProps} {...props} />;
    case "jcb":
      return <Jcb {...svgProps} {...props} />;
    case "union-pay":
      return <UnionPay {...svgProps} {...props} />;
    case "location":
      return <Location {...svgProps} {...props} />;
    case "flight-tracking":
      return <FlightTracking {...svgProps} {...props} />;
    case "preferred-driver":
      return <PreferredDriver {...svgProps} {...props} />;
    case "diamond":
      return <Diamond {...svgProps} {...props} />;
    case "arrow-right-2":
      return <ArrowRight2 {...svgProps} {...props} />;
    case "arrow-left-2":
      return <ArrowLeft2 {...svgProps} {...props} />;
    case "excel":
      return <Excel {...svgProps} {...props} />;
    case "vehicle-type-sedan":
      return <VehicleTypeSedan {...svgProps} {...props} />;
    case "vehicle-type-suv":
      return <VehicleTypeSuv {...svgProps} {...props} />;
    case "vehicle-type-van":
      return <VehicleTypeVan {...svgProps} {...props} />;
    case "vehicle-type-bus":
      return <VehicleTypeBus {...svgProps} {...props} />;
    case "vehicle-type-minibus":
      return <VehicleTypeMinibus {...svgProps} {...props} />;
    case "vehicle-type-truck":
      return <VehicleTypeTruck {...svgProps} {...props} />;
    case "rocket":
      return <Rocket {...svgProps} {...props} />;
    default:
      return null;
  }
};
