import { SiteConfig } from "@/redux/slices/config/types";
import { getAssetUrl } from "@/utils";
import { LegacySiteConfig } from "./hooks/useLegacySiteConfig";

export const transformLegacySiteConfig = ({ siteInfo, operator }: LegacySiteConfig): SiteConfig => ({
  name: operator.name,
  images: {
    logo: operator.images?.logo || "",
    emblem: operator.images?.emblem || "",
  },
  flags: {
    clientSignUpsEnabled: operator.flags.clientSignUpsEnabled || false,
    driverSignUpsEnabled: operator.flags.driverSignUpsEnabled || false,
  },
  urls: {
    contact: operator.urls.contact,
    terms: operator.urls.terms,
    privacy: operator.urls.privacy,
  },
  branding: {
    primary: operator.branding.primary,
    dark: operator.branding.dark,
    light: operator.branding.light,
  },
  //siteInfo
  domain: siteInfo.key.toLowerCase(),
  apiUrl: siteInfo.apiUrl,
  baseUrl: siteInfo.url,
  isMultiTenant: siteInfo.isMultiTenant,
});

export const transformRMConfig = ({ site: siteInfo, provider: operator }: RMConfig): SiteConfig => ({
  name: operator.name,
  images: {
    logo: getAssetUrl(operator.assets_cdn.logo),
    emblem: getAssetUrl(operator.assets_cdn.emblem),
  },
  flags: {
    clientSignUpsEnabled: operator.flags.client_signups_enabled,
    driverSignUpsEnabled: operator.flags.driver_signups_enabled,
  },
  urls: {
    contact: operator.urls.contact_us,
    terms: operator.urls.terms,
    privacy: operator.urls.privacy_policy,
  },
  branding: {
    primary: operator.branding.primary_color,
    dark: operator.branding.secondary_color,
    light: operator.branding.tertiary_color,
  },
  //siteInfo
  domain: siteInfo.domain_key.toLowerCase(),
  apiUrl: siteInfo.url.api,
  baseUrl: siteInfo.url.domain,
  isMultiTenant: siteInfo.metadata.is_multi_tenant,
});
