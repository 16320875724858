import { BookingsHeader, BookingsPanel } from "@/components/organisms";
import { BookingFiltersProvider } from "@/components/organisms/Bookings/hooks/useBookingFiltersV2";

export const Bookings = () => {
  return (
    <BookingFiltersProvider>
      <BookingsHeader />
      <BookingsPanel />
    </BookingFiltersProvider>
  );
};
