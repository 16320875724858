import camelcaseKeys from "camelcase-keys";
import { GetVehiclesResponse } from "@/redux/slices/vehicle/types";
import { GetVehiclesRawResponse, RawVehicle } from "./types";

export const transformGetVehiclesResponse = (response: GetVehiclesRawResponse): GetVehiclesResponse => {
  return {
    vehicles: response._embedded.vehicle.map((vehicle: RawVehicle) => camelcaseKeys(vehicle, { deep: true })),
    page: {
      current: response.page,
      count: response.page_count,
      size: response.page_size,
      total: response.total_items,
    },
  };
};
