import { useLocalStorageValue } from "@react-hookz/web";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ArrayParam, BooleanParam, DecodedValueMap, StringParam, useQueryParams } from "use-query-params";
import { useOperator, usePagination } from "@/hooks";
import { BookingsFilterParams } from "@/redux/apis/booking/types";
import { BookingFilters } from "@/redux/slices/booking/types";

export type BookingFilterKey = keyof Omit<BookingFilters, "search" | "provider">;
export type BookingFilterValue = string | boolean | (string | null)[] | null | undefined;

const DEFAULT_FILTERS: Record<BookingFilterKey, undefined> = {
  assignedVehicle: undefined,
  client: undefined,
  company: undefined,
  driver: undefined,
  passenger: undefined,
  onlyShowUnvalidated: undefined,
  status: undefined,
  transferType: undefined,
  state: undefined,
  vehicleType: undefined,
  offload: undefined,
};

const filterParams = {
  assignedVehicle: StringParam,
  client: StringParam,
  company: StringParam,
  driver: StringParam,
  passenger: StringParam,
  onlyShowUnvalidated: BooleanParam,
  status: ArrayParam,
  transferType: StringParam,
  state: ArrayParam,
  vehicleType: ArrayParam,
  offload: StringParam,
};

interface IBookingFiltersContext {
  params: BookingsFilterParams;
  sortedFilters: [BookingFilterKey, BookingFilterValue][];
  filters: DecodedValueMap<typeof filterParams>;
  setFilter: (key: BookingFilterKey, value?: BookingFilterValue) => void;
  setFilters: (newFilters: Record<BookingFilterKey, BookingFilterValue>) => void;
  clearFilters: () => void;
  resetFilters: () => void;
}

const BookingFiltersContext = createContext<IBookingFiltersContext | null>(null);

export const BookingFiltersProvider = ({ children }: React.PropsWithChildren) => {
  const { id: operatorId } = useOperator();
  const { clearPagination } = usePagination("bookings", 100);
  const [filters, setFilters] = useQueryParams(filterParams);
  const { set: setPersistedFilters, value: persistedFilters } = useLocalStorageValue<BookingFilters>(`${operatorId}:bookings-filter`, {
    defaultValue: DEFAULT_FILTERS,
  });
  const [filterSorting, setFilterSorting] = useState<BookingFilterKey[]>([]);

  useEffect(() => {
    if (!persistedFilters || !!filters) return;
    setFilters(persistedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedFilters = useMemo(() => {
    const mergedFilters = Object.keys(filters).reduce((acc, key) => {
      if (!filterSorting.includes(key as BookingFilterKey) && filters[key as BookingFilterKey] !== undefined) {
        acc.push(key as BookingFilterKey);
      }
      return acc;
    }, filterSorting);

    return (mergedFilters || []).map((key) => [key, filters[key]] as const);
  }, [filterSorting, filters]);

  const params: BookingsFilterParams = useMemo(
    () => ({
      assigned_vehicle: filters.assignedVehicle ?? undefined,
      client: filters.client ?? undefined,
      company: filters.company ?? undefined,
      driver: filters.driver ?? undefined,
      passenger: filters.passenger ?? undefined,
      is_validated: filters.onlyShowUnvalidated ? "0" : undefined,
      status: filters.status ? (filters.status.filter(Boolean) as string[]) : undefined,
      transfer_type: filters.transferType ?? undefined,
      state: filters.state ? (filters.state.filter(Boolean) as string[]) : undefined,
      vehicle_type: filters.vehicleType ? (filters.vehicleType.filter(Boolean) as string[]) : undefined,
      offload: filters.offload && filters.offload !== "all" ? filters.offload : undefined,
    }),
    [filters]
  );

  const clearFilters = useCallback(() => {
    clearPagination();
    const clearedFilters = Object.entries(filters)
      .filter(([_, value]) => value !== undefined)
      .reduce((acc, [key]) => {
        acc[key as BookingFilterKey] = null;
        return acc;
      }, {} as BookingFilters);

    setFilters(clearedFilters);
    setPersistedFilters(clearedFilters);
  }, [clearPagination, filters, setFilters, setPersistedFilters]);

  const resetFilters = useCallback(() => {
    clearPagination();
    setFilterSorting([]);
    setFilters(DEFAULT_FILTERS);
    setPersistedFilters(DEFAULT_FILTERS);
  }, [clearPagination, setFilterSorting, setFilters, setPersistedFilters]);

  const setFilter = useCallback(
    (key: BookingFilterKey, value?: BookingFilterValue) => {
      if (value) {
        clearPagination();
      }

      setFilters((prev) => ({ ...prev, [key]: value }));
      setPersistedFilters((prev) => ({ ...prev, [key]: value }));
      setFilterSorting((prev = []) => {
        if (value === undefined) {
          return prev.filter((i) => i !== key);
        }
        return prev.includes(key) ? prev : [...prev, key];
      });
    },
    [setFilters, setPersistedFilters, setFilterSorting, clearPagination]
  );

  const setAllFilters = useCallback(
    (newFilters: Record<BookingFilterKey, BookingFilterValue>) => {
      clearPagination();
      setFilters(newFilters as BookingFilters);
      setPersistedFilters(newFilters as BookingFilters);
      setFilterSorting(Object.keys(newFilters) as BookingFilterKey[]);
    },
    [setFilters, setPersistedFilters, setFilterSorting, clearPagination]
  );

  const value = {
    params,
    sortedFilters: sortedFilters as [BookingFilterKey, BookingFilterValue][],
    filters,
    setFilter,
    setFilters: setAllFilters,
    clearFilters,
    resetFilters,
  };

  return <BookingFiltersContext.Provider value={value}>{children}</BookingFiltersContext.Provider>;
};

export const useBookingFiltersV2 = () => {
  const context = useContext(BookingFiltersContext);
  if (!context) {
    throw new Error("useBookingFiltersV2 must be used within a BookingFiltersProvider");
  }

  return context;
};
