import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { CheckboxInput, Typography } from "@/components/atoms";
import { useGetBookingFormOptionsQuery } from "@/redux/apis/booking/bookingApi";
import { useAppSelector } from "@/redux/hooks";
import { bookingFormOptionsSelector } from "@/redux/slices/booking/selectors";
import { useBookingFiltersV2 } from "../../../hooks/useBookingFiltersV2";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";

const schema = z.object({
  vehicleType: z.array(z.string()),
});

type FormData = z.infer<typeof schema>;

export const VehicleTypeFilter = () => {
  const { vehicleTypes } = useAppSelector(bookingFormOptionsSelector);
  const {
    filters: { vehicleType },
    setFilter,
  } = useBookingFiltersV2();

  const value = useMemo(() => {
    if (!vehicleType) return undefined;

    return vehicleTypes
      .filter((type) => vehicleType.includes(type.value))
      .map((type) => type.name)
      .join(", ");
  }, [vehicleType, vehicleTypes]);

  const onClose = () => setFilter("vehicleType", undefined);

  return (
    <FilterPopover name="vehicleType" value={value} label="Vehicle type" onClose={onClose}>
      <VehicleTypeFilterForm />
    </FilterPopover>
  );
};

interface VehicleTypeFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const VehicleTypeFilterDrawer = ({ open, onOpenChange }: VehicleTypeFilterDrawerProps) => {
  const { vehicleTypes } = useAppSelector(bookingFormOptionsSelector);

  const {
    filters: { vehicleType },
    setFilter,
  } = useBookingFiltersV2();

  const value = useMemo(() => {
    if (!vehicleType) return undefined;

    return vehicleTypes
      .filter((vehicleType) => vehicleType.value === vehicleType.value)
      .map((vehicleType) => vehicleType.name)
      .join(", ");
  }, [vehicleType, vehicleTypes]);

  const onClose = () => setFilter("vehicleType", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="Vehicle type" onClose={onClose}>
      <VehicleTypeFilterForm />
    </FilterDrawer>
  );
};

const VehicleTypeFilterForm = () => {
  const { isFetching } = useGetBookingFormOptionsQuery();
  const { vehicleTypes } = useAppSelector(bookingFormOptionsSelector);
  const { setFilter, filters } = useBookingFiltersV2();

  const { control, setValue } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { vehicleType: (filters.vehicleType as string[]) ?? [] },
  });

  const handleCheckAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;

      if (checked) {
        setFilter(
          "vehicleType",
          vehicleTypes.map((vehicleType) => vehicleType.value)
        );
        setValue(
          "vehicleType",
          vehicleTypes.map((vehicleType) => vehicleType.value)
        );
      } else {
        setFilter("vehicleType", null);
        setValue("vehicleType", []);
      }
    },
    [setFilter, setValue, vehicleTypes]
  );

  const isAllVehicleTypesSelected = useMemo(() => {
    if (!filters.vehicleType) return false;
    return filters.vehicleType.length === vehicleTypes.length;
  }, [filters.vehicleType, vehicleTypes.length]);

  if (isFetching) return null;

  return (
    <div className="contents">
      <Typography className="mb-4">Vehicle types</Typography>
      <CheckboxInput label="All Vehicle types" onChange={handleCheckAll} variant="check" checked={isAllVehicleTypesSelected} />
      <div className="mt-4 grid gap-4 ">
        <Controller
          control={control}
          name="vehicleType"
          render={({ field }) => (
            <>
              {vehicleTypes.map((vehicleType) => (
                <CheckboxInput
                  key={vehicleType.value}
                  label={vehicleType.name}
                  value={vehicleType.value}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const newVehicleType = [...field.value, e.target.value];
                      field.onChange(newVehicleType);
                      setFilter("vehicleType", newVehicleType.length > 0 ? newVehicleType : null);
                    } else {
                      const newVehicleType = field.value.filter((i) => i !== e.target.value);
                      field.onChange(newVehicleType);
                      setFilter("vehicleType", newVehicleType.length > 0 ? newVehicleType : null);
                    }
                  }}
                  variant="check"
                  checked={field.value.includes(vehicleType.value)}
                />
              ))}
            </>
          )}
        />
      </div>
    </div>
  );
};
