import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Spinner, Typography } from "@/components/atoms";
import { getAssetUrl } from "@/utils";
import { useAddVehicleContext } from "./context";
import { FormData } from "./form";

export const CheckEligibility = () => {
  const { setStep } = useAddVehicleContext();
  const { watch } = useFormContext<FormData>();
  const model = watch("model");
  const make = watch("make");

  useEffect(() => {
    const timeout = setTimeout(() => {
      setStep("select-verification");
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mt-14">
      <div className="flex max-w-[500px] flex-col items-center gap-3">
        <img
          src={getAssetUrl(make.avatar, {
            width: 56,
            height: 56,
            fit: "contain",
            background: "transparent",
          })}
          alt={model.name}
          className="h-14 w-14 object-contain"
        />
        <Typography variant="title" className="leading-8">
          {make.name} {model.name}
        </Typography>
        <Typography className="text-center">
          Hold on, we’re verifying the network eligibility. This helps the system match your vehicle accurately within the platform and with
          external networks, ensuring proper job allocation.
        </Typography>
        <Spinner className="mt-5 h-10 w-10" />
      </div>
    </div>
  );
};
