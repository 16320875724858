import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithProviderCountry } from "@/redux/baseQuery";
import { transformAddOns } from "./helpers";
import { GetAddOnResponse, ToggleAddOnParams } from "./types";

export const addOnApi = createApi({
  reducerPath: "addOnApi",
  baseQuery: baseQueryWithProviderCountry("add-on"),
  endpoints: (builder) => ({
    getPremiumAddOns: builder.query<GetAddOnResponse, void>({
      query: () => "?premium=1",
      providesTags: ["AddOns"],
      transformResponse: transformAddOns,
    }),
    getAddOns: builder.query<GetAddOnResponse, void>({
      query: () => "?premium=0",
      providesTags: ["AddOns"],
      transformResponse: transformAddOns,
    }),
    toggleAddOn: builder.mutation<void, ToggleAddOnParams>({
      query: ({ id, enabled }) => ({
        url: `/${id}`,
        method: "POST",
        body: { enabled },
      }),
      invalidatesTags: ["AddOns"],
    }),
  }),
  tagTypes: ["AddOns"],
});

export const { useGetPremiumAddOnsQuery, useGetAddOnsQuery, useToggleAddOnMutation } = addOnApi;
