import cx from "classnames";
import { createRef, useState } from "react";
import ReactDropzone, { DropzoneRef, FileRejection } from "react-dropzone";
import { Typography } from "..";
import { getHumanFileSize, validateFileType } from "./helpers";
import { DropzoneProps } from "./types";

export const Dropzone = (dropzoneProps: DropzoneProps) => {
  const { accept, maxSize, disabled, detail, upload, className, ...props } = dropzoneProps;
  const [fileError, setFileError] = useState("");
  const dropzoneRef = createRef<DropzoneRef>();

  const openFileBrowser = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const onDropAccepted = async (files: File[]) => {
    const fileSelected = files?.[0];

    if (!fileSelected) {
      return;
    }

    setFileError("");

    // Validate file type of the upload
    const isValidFileType = await validateFileType({
      ...dropzoneProps,
      file: fileSelected,
    });

    if (!isValidFileType) {
      setFileError(`Invalid file type. Select a ${Object.values(accept).join(", ")} file, and try again.`);
      return;
    }

    if ((await validateUpload(fileSelected)) && upload) {
      upload(fileSelected);
    }
  };

  const validateUpload = async (file: File): Promise<boolean> => {
    if (!file || !(await validateFileInput(file))) {
      setFileError("File not found. Try again.");
      return false;
    }

    return true;
  };

  const validateFileInput = async (file: File) => {
    const isValid = await new Promise((resolve) => {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        resolve(true);
      };

      fileReader.onerror = (event) => {
        if (event.target?.error?.name === "NotFoundError") {
          resolve(false);
        }
      };

      fileReader.readAsText(file as File);
    });

    return isValid;
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const onDropRejected = (fileRejections: FileRejection[]) => {
    const errors = fileRejections[0].errors;
    const invalidType = errors.some((e: any) => e.code === "file-invalid-type");
    if (invalidType) {
      setFileError(`Invalid file type. Select a ${Object.values(accept).join(", ")} file, and try again.`);
      return;
    }
    const invalidSize = errors.some((e: any) => e.code === "file-too-large");
    if (invalidSize) {
      const humanReadableFileSize = getHumanFileSize(maxSize, true, 0);
      setFileError(`Maximum file size exceeded. Check the file is below ${humanReadableFileSize}, and try again.`);
      return;
    }
  };

  const renderFileError = () => {
    if (fileError) {
      return (
        <Typography variant="muted" className="mt-3 flex text-danger">
          {fileError}
        </Typography>
      );
    }
  };

  return (
    <>
      {disabled ? (
        <div className="h-full cursor-not-allowed rounded-lg border border-dashed border-neutral-mid-gray text-neutral-mid-gray">
          {detail}
        </div>
      ) : (
        <ReactDropzone
          ref={dropzoneRef}
          accept={accept}
          maxSize={maxSize}
          onDropAccepted={onDropAccepted}
          onDropRejected={onDropRejected}
          multiple={false}
          {...props}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              className={cx(
                className,
                "cursor-pointer rounded-lg border border-dashed",
                isDragActive ? "border-primary text-primary" : "border-neutral-dark-gray text-neutral-dark-gray"
              )}
              {...getRootProps({
                onClick: (event) => {
                  event.stopPropagation();
                  openFileBrowser();
                },
              })}
            >
              <input data-testid="file-input" {...getInputProps()} />
              {detail}
            </div>
          )}
        </ReactDropzone>
      )}
      {renderFileError()}
    </>
  );
};
