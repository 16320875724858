import { Icon, Tooltip, Typography } from "@/components/atoms";
import { getOldBookingUrl } from "@/helpers/bookingHelpers";
import { clsx, getAssetUrl } from "@/utils";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";
import { BookingFlightTracking } from "../../BookingFlightTracking/BookingFlightTracking";

const FALLBACK_IMAGE = "raw/images/maps/placeholder.png";

export const Destinations = ({ className }: React.ComponentProps<"div">) => {
  const { id: bookingId, account, stops, routeImage } = useSelectedBooking();
  const flight = stops.find((stop) => stop.flight)?.flight;
  const redirectUrl = account ? getOldBookingUrl(account.id, bookingId, "trip-log", true) : undefined;

  const handleClick = () => {
    if (redirectUrl) {
      window.open(redirectUrl, "_blank");
    }
  };

  return (
    <div className={clsx("relative flex overflow-hidden rounded-lg !p-0", redirectUrl && "cursor-pointer", className)}>
      <div className="inline-flex h-full bg-neutral-gray">
        <img src={getAssetUrl(routeImage || FALLBACK_IMAGE)} className="aspect-route object-cover" />
      </div>

      <div
        onClick={handleClick}
        className="absolute bottom-0 left-0 h-full w-full bg-[linear-gradient(180deg,_rgba(21,_21,_22,_0.00)_0%,_rgba(27,_27,_27,_0.90)_104.23%)]"
      ></div>
      <div className="absolute bottom-0 left-0 flex w-full flex-col p-3 text-white xl:p-4">
        <div className="connector flex flex-1 flex-col gap-4 max-lg:gap-0 ">
          <div className="flex gap-2 max-lg:flex-col max-lg:gap-1 max-lg:truncate max-lg:py-2">
            <Typography variant="small" className="relative z-10 flex min-w-[100px] gap-2">
              <Icon name="LocationDiscover" variant="Bold" className="inline text-info" size="sm" />
              Pick-up:
            </Typography>
            <div className="flex flex-col items-start space-y-1 max-lg:pl-6">
              <Tooltip content={stops[0].address} maxWidth={400} placement="top">
                <Typography variant="small">{stops[0].address}</Typography>
              </Tooltip>
              {flight && (
                <div className="hidden xl:flex">
                  <BookingFlightTracking flight={flight} />
                </div>
              )}
            </div>
          </div>
          <div className={clsx({ hidden: stops.slice(1, stops.length - 1).length < 1 })}>
            {stops.slice(1, stops.length - 1).map((i, idx) => (
              <div className="flex gap-2 max-lg:flex-col max-lg:gap-1 max-lg:truncate max-lg:py-2" key={idx}>
                <Typography variant="small" className="relative z-10 flex min-w-[100px] gap-2">
                  <Icon name="Location" variant="Bold" className="inline" size="sm" />
                  Stop {idx + 1}:
                </Typography>
                <Tooltip content={i.address} maxWidth={400} placement="top">
                  <Typography variant="small" className="max-lg:pl-6">
                    {i.address}
                  </Typography>
                </Tooltip>
              </div>
            ))}
          </div>
          <div className="flex gap-2 max-lg:flex-col max-lg:gap-1 max-lg:truncate max-lg:py-2">
            <Typography variant="small" className="relative z-10 flex min-w-[100px] gap-2">
              <Icon name="Location" variant="Bold" className="inline text-managing" size="sm" />
              Destination:
            </Typography>
            <Tooltip content={stops[stops.length - 1].address} maxWidth={400} placement="top">
              <Typography variant="small" className="max-lg:pl-6">
                {stops[stops.length - 1].address}
              </Typography>
            </Tooltip>
          </div>
        </div>
        {flight && <BookingFlightTracking flight={flight} className="xl:hidden" />}
      </div>
    </div>
  );
};
