import { Controller, useFormContext } from "react-hook-form";
import { DropdownSelect, DropdownSelectOption, ErrorMessage, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { ClientsAddFormData } from "../types";

export const HowDidTheClientHearAboutUsField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<ClientsAddFormData>();

  const options: DropdownSelectOption[] = [
    { name: "A friend/colleague referred me to you", value: "A friend/colleague referred me to you" },
    {
      name: "We used to use you in my old place of work",
      value: "We used to use you in my old place of work",
    },
    { name: "Opal Travel App", value: "Opal Travel App" },
    { name: "transportnsw.info Website", value: "transportnsw.info Website" },
    { name: "Media / Newspapers/ Online articles", value: "Media / Newspapers/ Online articles" },
    { name: "Google", value: "Google" },
    { name: "Facebook", value: "Facebook" },
    { name: "Instagram", value: "Instagram" },
    { name: "Linkedin", value: "Linkedin" },
    { name: "A link from another website", value: "A link from another website" },
    { name: "Tripadvisor", value: "Tripadvisor" },
    { name: "QR Code", value: "QR Code" },
    { name: "Other", value: "Other" },
  ];

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="paragraph">How did the client hear about us?</Typography>
      <Controller
        name="howDidTheClientHearAboutUs"
        control={control}
        render={({ field }) => (
          <DropdownSelect
            className="w-full"
            hasError={!!errors.howDidTheClientHearAboutUs}
            placeholder="Select Answer"
            options={options}
            value={field.value}
            onChange={(value) => field.onChange(value)}
          />
        )}
      />
      <ErrorMessage errors={errors} name="howDidTheClientHearAboutUs" />
    </div>
  );
};
