import { RouteObject } from "react-router-dom";
import { BookingDriverActivity, BookingOffer, NotFound, PayInvoice, RouteError } from "@/components/pages";
import { Public } from "@/containers";

export const publicRoutes: RouteObject = {
  path: "/",
  element: <Public />,
  children: [
    {
      path: "booking-offer/:providerId/:accountId/:jobId/:jobKey",
      errorElement: <BookingOffer.NotFound />,
      children: [
        {
          index: true,
          element: <BookingOffer />,
        },
        {
          path: "rejected",
          element: <BookingOffer.Rejected />,
        },
        {
          path: "*",
          element: <BookingOffer.NotFound />,
        },
      ],
    },
    {
      path: "trip-log/:jobId/:hash",
      errorElement: <NotFound />,
      children: [
        {
          index: true,
          element: <BookingDriverActivity />,
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
    {
      path: "pay-invoice/:providerId/:companyId/:invoiceId/:invoiceKey",
      errorElement: <RouteError />,
      children: [
        {
          index: true,
          element: <PayInvoice />,
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ],
};
