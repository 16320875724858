import { Container } from "@/components/atoms";
import { GeneralFooter, GeneralHeader, SignUpSteps } from "@/components/organisms";
import { useOperator } from "@/hooks";
import { useSearchParams } from "react-router-dom";
import { Restricted } from "../Restricted/Restricted";

export const SignUp = () => {
  const [params] = useSearchParams();
  const { singleTenant, flags: { clientSignUpsEnabled, driverSignUpsEnabled } } = useOperator();
  const paramsUserType = params.get("type");
  const isClientType = paramsUserType === "client";
  const signUpEnabled = singleTenant ? (isClientType && !clientSignUpsEnabled) ? false : clientSignUpsEnabled || driverSignUpsEnabled : true;

  return (
    <div className="flex flex-col min-h-screen overflow-hidden md:flex-row">
      <div className="flex flex-1 flex-col relative">
        <GeneralHeader action="login" />
          {signUpEnabled ? (
            <Container className="flex flex-1 justify-center w-full max-w-[1040px] gap-5 py-8 md:py-[100px] px-5">
              <SignUpSteps />
            </Container>
          ) : <Restricted />}
        <GeneralFooter />
      </div>
    </div>
  );
};