import { isEmpty } from "ramda";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import RMLogo from "@/assets/images/rm-logo-emblem.svg";
import Driver from "@/assets/images/signup/driver.svg";
import Operator from "@/assets/images/signup/operator.svg";
import Passenger from "@/assets/images/signup/passenger.svg";
import { Alert, Button, Loading, OTPInput, Panel, PasswordInput, TextInput, Typography } from "@/components/atoms";
import { getPhpHostUrl } from "@/helpers/apiHelpers";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useOperator } from "@/hooks";
import { useLoginMutation, useVerifyEmailAddressMutation } from "@/redux/apis/auth/authApi";
import { RawErrorResponse } from "@/redux/types";

type FormData = {
  emailAddress: string;
  password?: string;
  otp?: string;
};

type VerifyData = {
  otpEnabled: boolean;
  passwordChangeRequired: boolean;
  role: string;
};

type Steps = "email" | "password" | "otp";

export const LoginForm = () => {
  const navigate = useNavigate();
  const {
    singleTenant,
    flags: { clientSignUpsEnabled, driverSignUpsEnabled },
  } = useOperator();

  const { register, handleSubmit, watch, reset, setValue } = useForm<FormData>();
  const values = watch();

  const [login, { isLoading: isLoadingLogin }] = useLoginMutation();
  const [verifyEmailAddress, { isLoading: isLoadingVerifyEmailAddress }] = useVerifyEmailAddressMutation();

  const [errors, setErrors] = useState<string[]>([]);
  const [infos, setInfos] = useState<string[]>([]);
  const [step, setStep] = useState<Steps>("email");
  const [verifyData, setVerifyData] = useState<VerifyData | null>(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const isLoading = isLoadingLogin || isLoadingVerifyEmailAddress || isLoggingIn;
  const signUpEnabled = singleTenant ? clientSignUpsEnabled || driverSignUpsEnabled : true;

  const emailPhpRef = useRef<HTMLInputElement>(null);
  const passwordPhpRef = useRef<HTMLInputElement>(null);
  const submitPhpRef = useRef<HTMLInputElement>(null);

  const onSubmit = handleSubmit(async (data) => {
    setErrors([]);
    setInfos([]);
    const { emailAddress, password, otp } = data;

    switch (step) {
      case "password": {
        if (verifyData) {
          if (verifyData.otpEnabled) {
            setStep("otp");
          } else {
            if (password) {
              setIsLoggingIn(true);
              login({ username: emailAddress, password })
                .unwrap()
                .then(() => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  if (import.meta.env.MODE === "production") loginPhp(emailAddress, password);
                  else navigate(`${verifyData.passwordChangeRequired ? "/profile/secure-account" : "/"}`);
                })
                .catch(handleError);
            }
          }
        }
        break;
      }
      case "otp": {
        if (otp) {
          if (password) {
            setIsLoggingIn(true);
            login({ username: emailAddress, password, otp })
              .unwrap()
              .then(() => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (import.meta.env.MODE === "production") loginPhp(emailAddress, password);
                else navigate("/");
              })
              .catch(handleError);
          }
        } else setErrors(["Please fill out this field."]);
        break;
      }
      default: {
        verifyEmailAddress(emailAddress)
          .unwrap()
          .then((res) => {
            setVerifyData({
              otpEnabled: res.otp_enabled,
              passwordChangeRequired: res.password_change_required,
              role: res.role,
            });
            setStep("password");
          })
          .catch((e) => {
            if (e.data.error === "ACCOUNT_NOT_FOUND") setErrors(["This account does not exist."]);
            else setErrors(getErrorMessages(e));
          });
      }
    }
  });

  const loginPhp = (emailAddress: string, password: string) => {
    const emailInputElement = emailPhpRef.current;
    const passwordInputElement = passwordPhpRef.current;
    const submitElement = submitPhpRef.current;

    if (emailInputElement && passwordInputElement && submitElement) {
      emailInputElement.value = emailAddress;
      passwordInputElement.value = password;

      submitElement.click();
    }
  };

  const resetLogin = () => {
    reset();
    setErrors([]);
    setInfos([]);
    setIsLoggingIn(false);
    setStep("email");
    setVerifyData(null);
  };

  const handleError = (error: RawErrorResponse) => {
    const { data } = error;
    setIsLoggingIn(false);

    if (data.error && data.error === "INACTIVE_ACCOUNT_PENDING_QUALIFICATION") {
      setInfos(["Your account is currently under review. Please reach out to your operator for any updates."]);
    } else if (data.title === "invalid_grant") {
      setErrors(["The password you entered is incorrect. Please try again."]);
      setStep("password");
    } else setErrors(getErrorMessages(error));
  };

  const renderHeader = () => {
    switch (step) {
      case "password":
        return (
          <div>
            <Typography variant="h1">Login to your account</Typography>
            <Typography variant="paragraph">
              Welcome back! {values.emailAddress}{" "}
              <span className="cursor-pointer text-info" onClick={resetLogin}>
                Not you?
              </span>
            </Typography>
          </div>
        );
      case "otp":
        return (
          <div>
            <Typography variant="h1">Secure Login: Enter Your OTP</Typography>
            <Typography variant="paragraph">
              Welcome back! {values.emailAddress}{" "}
              <span className="cursor-pointer text-info" onClick={resetLogin}>
                Not you?
              </span>
            </Typography>
          </div>
        );
      default:
        return (
          <div>
            <Typography variant="h1">Login to your account</Typography>
            <Typography variant="paragraph">Welcome back! Please enter your email</Typography>
          </div>
        );
    }
  };

  const renderRole = () => {
    const role = verifyData ? verifyData.role : "";
    switch (role) {
      case "RideMinder":
        return (
          <div className="flex flex-row gap-x-4">
            <div className="flex flex-shrink-0 items-start">
              <div className="flex items-center justify-center rounded-full bg-neutral-gray p-2">
                <img src={RMLogo} alt="transport-business-owner" className="h-10 w-10" />
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <Typography variant="action">{values.emailAddress}</Typography>
              <Typography variant="paragraph">RideMinder</Typography>
            </div>
          </div>
        );
      case "Operator":
        return (
          <div className="flex flex-row gap-x-4">
            <div className="flex flex-shrink-0 items-start">
              <div className="flex items-center justify-center rounded-full bg-neutral-gray p-2">
                <img src={Operator} alt="transport-business-owner" className="h-10 w-10" />
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <Typography variant="action">{values.emailAddress}</Typography>
              <Typography variant="paragraph">Transport Business Owner</Typography>
            </div>
          </div>
        );
      case "Passenger":
        return (
          <div className="flex flex-row gap-x-4">
            <div className="flex flex-shrink-0 items-start">
              <div className="flex items-center justify-center rounded-full bg-neutral-gray p-2">
                <img src={Passenger} alt="customer-rider" className="h-10 w-10" />
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <Typography variant="action">{values.emailAddress}</Typography>
              <Typography variant="paragraph">Customer / Rider</Typography>
            </div>
          </div>
        );
      case "Driver":
        return (
          <div className="flex flex-row gap-x-4">
            <div className="flex flex-shrink-0 items-start">
              <div className="flex items-center justify-center rounded-full bg-neutral-gray p-2">
                <img src={Driver} alt="driver" className="h-10 w-10" />
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <Typography variant="action">{values.emailAddress}</Typography>
              <Typography variant="paragraph">Driver</Typography>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const renderFields = () => {
    switch (step) {
      case "password":
        return (
          <div className="flex flex-col gap-y-4">
            {renderRole()}
            <div>
              <label htmlFor="password" className="mb-1">
                <Typography>Password</Typography>
              </label>
              <PasswordInput
                id="password"
                data-testid="login-password"
                required
                autoComplete="current-password"
                placeholder="Enter password"
                hasError={!isEmpty(errors)}
                {...register("password", { required: true })}
              />
            </div>
          </div>
        );
      case "otp":
        return (
          <div className="flex flex-col gap-y-4">
            {renderRole()}
            <Alert type="info" message="Enter the OTP generated from your authenticator app" />
            <OTPInput
              value={values.otp || ""}
              onChange={(value) => setValue("otp", value, { shouldDirty: true })}
              inputClassName="!w-full"
            />
          </div>
        );
      default:
        return (
          <div>
            <label htmlFor="email-address" className="mb-1">
              <Typography>Email</Typography>
            </label>
            <TextInput
              id="email-address"
              data-testid="login-email-address"
              type="email"
              autoComplete="email"
              required
              placeholder="your.email@gmail.com"
              autoFocus
              hasError={!isEmpty(errors)}
              {...register("emailAddress", { required: true })}
            />
          </div>
        );
    }
  };

  const renderButtons = () => {
    switch (step) {
      case "password":
        return (
          <div className="mt-6 flex flex-col gap-y-4">
            <Link to={`/forgot-password?email=${values.emailAddress}`}>
              <Typography variant="paragraph" className="text-info">
                Forgot Password?
              </Typography>
            </Link>
            <div className="relative">
              {isLoading && <Loading />}
              <Button type="submit" size="lg" data-testid="login-button" className="w-full">
                Login account
              </Button>
            </div>
          </div>
        );
      case "otp":
        return (
          <div className="relative mt-6 flex flex-col gap-y-4">
            {isLoading && <Loading />}
            <Button type="submit" size="lg" data-testid="login-button" className="w-full">
              Verify and Log In
            </Button>
          </div>
        );
      default:
        return (
          <div className="mt-6 flex flex-col gap-y-4">
            {signUpEnabled && errors.includes("This account does not exist.") && (
              <Button
                variant="secondary"
                size="lg"
                onClick={() => navigate("/signup")}
                className="h-auto w-full whitespace-normal break-all"
              >
                <span className="text-info">Sign up</span> an account for {values.emailAddress}
              </Button>
            )}
            <div className="relative">
              {isLoading && <Loading />}
              <Button type="submit" size="lg" data-testid="login-button" className="w-full">
                Continue
              </Button>
            </div>
          </div>
        );
    }
  };

  return (
    <Panel>
      <div className="flex w-full flex-col">
        {renderHeader()}
        <form className="relative mt-4 w-full" onSubmit={onSubmit} data-testid="login-form">
          {renderFields()}
          {infos.map((i) => (
            <Alert key={i} type="info" message={i} className="mt-4" />
          ))}
          {errors.map((e) => (
            <Alert key={e} type="danger" message={e} className="mt-4" />
          ))}
          {renderButtons()}
        </form>
        <form
          id="phpForm"
          method="post"
          action={`${getPhpHostUrl()}/login?referrer=dashboard${
            verifyData && verifyData.passwordChangeRequired ? "&redirect=/dashboard/profile/secure-account" : ""
          }`}
        >
          <input type="hidden" name="email" ref={emailPhpRef} />
          <input type="hidden" name="password" ref={passwordPhpRef} />
          <input className="hidden" type="submit" ref={submitPhpRef} />
        </form>
      </div>
    </Panel>
  );
};
