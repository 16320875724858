import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "@/redux/baseQuery";
import { Operator } from "@/redux/slices/operator/types";
import { transformOperator } from "./helpers";
import {
  AddAccreditationRawResponse,
  AuthenticationResponse,
  Credentials,
  RegisterClientParams,
  RegisterDriverParams,
  RegisterOperatorParams,
  RegisterOperatorRawResponse,
  RegisterProviderClientParams,
  RegisterUserParams,
  VerifyProviderParams,
  VerifyProviderTokenRawResponse,
  VerifyEmailAddressRawResponse,
} from "./types";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery,
  endpoints: (builder) => ({
    login: builder.mutation<AuthenticationResponse, Credentials>({
      query: (credentials: Credentials) => {
        credentials.grant_type = "password";
        credentials.scope = "web-app";
        credentials.client_id = "web-app";

        return {
          url: "/oauth",
          method: "POST",
          body: credentials,
        };
      },
      invalidatesTags: ["Auth"],
    }),
    resetPasswordRequest: builder.mutation<void, string>({
      query: (email) => ({
        url: "/v3/auth/password/reset",
        method: "POST",
        body: { email },
      }),
      invalidatesTags: ["Auth"],
    }),
    resetPassword: builder.mutation<void, { token: string; password: string }>({
      query: ({ token, password }) => ({
        url: `/v3/auth/password/reset/${token}`,
        method: "POST",
        body: { password },
      }),
      invalidatesTags: ["Auth"],
    }),
    verifyPasswordToken: builder.query<void, string>({
      query: (token) => ({
        url: "/v3/auth/verify/reset-password-token",
        method: "POST",
        body: { token },
      }),
    }),
    verifyProvider: builder.query<Operator, VerifyProviderParams>({
      query: ({ providerCode, forDriver, apiUrl }) => ({
        url: `${apiUrl}/v3/auth/verify/provider`,
        method: "POST",
        body: { provider_code: providerCode, ...(forDriver && { verify_for: "driver" }) },
      }),
      transformResponse: transformOperator,
    }),
    verifyProviderToken: builder.query<VerifyProviderTokenRawResponse, string>({
      query: (token) => ({
        url: "/v3/auth/verify/provider-invitation-token",
        method: "POST",
        body: { token },
      }),
    }),
    registerProviderClient: builder.mutation<void, RegisterProviderClientParams>({
      query: ({ providerId, ...body }) => ({
        url: `/v3/auth/register/${providerId}/client`,
        method: "POST",
        body,
      }),
    }),
    registerClient: builder.mutation<void, RegisterClientParams>({
      query: ({ apiUrl, ...body }) => ({
        url: `${apiUrl}/v3/auth/register/client`,
        method: "POST",
        body,
      }),
    }),
    registerDriver: builder.mutation<void, RegisterDriverParams>({
      query: ({ apiUrl, ...body }) => ({
        url: `${apiUrl}/v3/auth/register/driver`,
        method: "POST",
        body,
      }),
    }),
    addAccreditation: builder.mutation<AddAccreditationRawResponse, FormData>({
      query: (body) => ({
        url: "/v3/auth/register/provider/accreditation",
        method: "POST",
        body,
      }),
    }),
    registerOperator: builder.mutation<RegisterOperatorRawResponse, RegisterOperatorParams>({
      query: (body) => ({
        url: "/v3/auth/register/provider",
        method: "POST",
        body,
      }),
    }),
    getProvider: builder.query<Operator, string>({
      query: (providerId) => ({
        url: `/v3/provider/${providerId}`,
        method: "GET",
      }),
      transformResponse: transformOperator,
    }),
    registerUser: builder.mutation<void, RegisterUserParams>({
      query: ({ apiUrl, inviteToken, ...body }) => ({
        url: `${apiUrl}/v3/auth/register/user/${inviteToken}`,
        method: "POST",
        body,
      }),
    }),
    verifyEmailAddress: builder.mutation<VerifyEmailAddressRawResponse, string>({
      query: (emailAddress) => ({
        url: "/v3/auth/verify/email-address",
        method: "POST",
        body: { email_address: emailAddress },
      }),
    }),
  }),
  tagTypes: ["Auth"],
});

export const {
  useLoginMutation,
  useResetPasswordRequestMutation,
  useResetPasswordMutation,
  useLazyVerifyPasswordTokenQuery,
  useLazyVerifyProviderQuery,
  useVerifyProviderTokenQuery,
  useRegisterClientMutation,
  useRegisterProviderClientMutation,
  useRegisterDriverMutation,
  useAddAccreditationMutation,
  useRegisterOperatorMutation,
  useLazyGetProviderQuery,
  useGetProviderQuery,
  useRegisterUserMutation,
  useVerifyEmailAddressMutation,
} = authApi;
