import { GetInvoicePreviewRawResponse, GetInvoicePreviewResponse } from "./types";

export const transformInvoicePreview = (res: GetInvoicePreviewRawResponse) => {
  const response: GetInvoicePreviewResponse = {
    data: {
      previewInvoice: {
        id: res.uuid,
        fileType: res.file_type,
        status: res.status,
        filename: res.filename,
      }
    }
  };

  return response;
};
