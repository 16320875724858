import { Button, Loading, Panel, Typography } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { ReactComponent as PlatformPreview } from "@/assets/images/config/platform-preview.svg";
import { useState } from "react";
import ColorPicker from "@/components/atoms/ColorPicker/ColorPicker";
import { addToast } from "@/utils";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useUpdateBrandingMutation } from "@/redux/apis/config/branding";

export const AppearancePanel = () => {
  const { branding } = useOperator();
  const [updateBranding, { isLoading }] = useUpdateBrandingMutation();
  const [mode, setMode] = useState<"view" | "edit">("view");
  const [primaryColor, setPrimaryColor] = useState(branding.primary);
  const [darkColor, setDarkColor] = useState(branding.dark);
  const [lightColor, setLightColor] = useState(branding.light);

  const handleCancel = () => {
    setMode("view");
    setPrimaryColor(branding.primary);
    setDarkColor(branding.dark);
    setLightColor(branding.light);
  };

  const handleSave = () => {
    updateBranding({
      primary_color: primaryColor,
      secondary_color: darkColor,
      tertiary_color: lightColor,
    })
      .unwrap()
      .then(() => { 
        addToast("success", "Successfully Updated Appearance");
        setTimeout(() => window.location.reload(), 1250);
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const renderButtons = () => {
    if (mode === "view") {
      return (
        <div>
          <Button startIcon="Edit2" onClick={() => setMode("edit")} className="w-full">
            Edit
          </Button>
        </div>
      );
    } else if (mode === "edit") {
      return (
        <div className="flex flex-col lg:flex-row items-center gap-x-4 gap-y-2">
          <Button variant="secondary" onClick={handleCancel} className="w-full">
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave} className="w-full">
            Save
          </Button>
        </div>
      );
    }

    return null;
  };

  return (
    <Panel className="relative flex flex-1 flex-col">
      {isLoading && <Loading />}
      <div className="flex flex-row items-center gap-4">
        <div className="flex-1">
          <Typography variant="h3">Appearance</Typography>
          <Typography className="text-neutral-dark-gray">
            Customize your own brand colour into your booking page.
          </Typography>
        </div>
        {renderButtons()}
      </div>
      <div className="flex flex-col lg:flex-row mt-8 gap-x-[86px] gap-y-10">
        <div className="flex flex-col gap-y-8">
          <div className="flex flex-col">
            <Typography variant="action">Primary Brand Color</Typography>
            <Typography variant="paragraph" className="text-neutral-dark-gray mb-2">Main color of the business</Typography>
            {mode == "edit" ? <ColorPicker hexCode={primaryColor} onChange={(color) => setPrimaryColor(color.hex)} /> : (
              <div className="flex flex-row items-center gap-x-3">
                <div className="h-8 w-8 rounded bg-primary" />
                <Typography variant="paragraph">{branding.primary}</Typography>
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <Typography variant="action">Secondary Brand Color</Typography>
            <Typography variant="paragraph" className="text-neutral-dark-gray mb-2">Preferably a darker shade</Typography>
            {mode == "edit" ? <ColorPicker hexCode={darkColor} onChange={(color) => setDarkColor(color.hex)} /> : (
              <div className="flex flex-row items-center mt-2 gap-x-3">
                <div className="h-8 w-8 rounded bg-primary-dark" />
                <Typography variant="paragraph">{branding.dark}</Typography>
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <Typography variant="action">Tertiary Brand Color</Typography>
            <Typography variant="paragraph" className="text-neutral-dark-gray mb-2">Preferably a lighter shade</Typography>
            {mode == "edit" ? <ColorPicker hexCode={lightColor} onChange={(color) => setLightColor(color.hex)} /> : (
              <div className="flex flex-row items-center mt-2 gap-x-3">
                <div className="h-8 w-8 rounded bg-primary-light" />
                <Typography variant="paragraph">{branding.light}</Typography>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-1 items-center justify-center">
          <div className="flex flex-col items-center lg:gap-y-5 pt-5 lg:px-[72px] rounded-lg bg-neutral-surface-gray max-h-[346px] w-full">
            <Typography variant="action" className="text-neutral-dark-gray">Platform Preview</Typography>
            <PlatformPreview style={{ "--primary": primaryColor, "--dark": darkColor, "--light": lightColor } as React.CSSProperties} className="w-full" />
          </div>
        </div>
      </div>
    </Panel>
  );
};