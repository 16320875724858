export const getTimeMultiplier = (timeframe: string) => {
  switch (timeframe) {
    case "hour":
      return 60;
    case "day":
      return 60 * 24;
    case "week":
      return 60 * 24 * 7;
    default:
      return 1;
  }
};
