import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { CheckboxInput, Typography } from "@/components/atoms";
import { useGetBookingFormOptionsQuery } from "@/redux/apis/booking/bookingApi";
import { useAppSelector } from "@/redux/hooks";
import { bookingFormOptionsSelector } from "@/redux/slices/booking/selectors";
import { BookingFilters } from "@/redux/slices/booking/types";
import { BookingStatusIcon } from "../../../common/BookingStatusIcon/BookingStatusIcon";
import { openStatuses, closedStatuses, statusMapping } from "../../../fixtures";
import { useBookingFiltersV2 } from "../../../hooks/useBookingFiltersV2";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";

const schema = z.object({
  statuses: z.array(z.string()),
});

type FormData = z.infer<typeof schema>;

const getValue = (status: BookingFilters["status"]) => {
  if (!status) return status;

  const isValueOpenStatusOnly = openStatuses.every((i) => status.includes(i.id)) && closedStatuses.every((i) => !status.includes(i.id));
  if (isValueOpenStatusOnly) return "Open Jobs";

  return status.map((status) => statusMapping[status].legend).join(", ");
};

export const StatusFilter = () => {
  const {
    filters: { status },
    setFilter,
  } = useBookingFiltersV2();

  const value = useMemo(() => getValue(status as BookingFilters["status"]), [status]);
  const onClose = () => setFilter("status", undefined);

  return (
    <FilterPopover name="status" value={value} label="Status" onClose={onClose}>
      <StatusFilterForm />
    </FilterPopover>
  );
};

interface StatusFilterFormProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const StatusFilterDrawer = ({ open, onOpenChange }: StatusFilterFormProps) => {
  const {
    filters: { status },
    setFilter,
  } = useBookingFiltersV2();

  const value = useMemo(() => getValue(status as BookingFilters["status"]), [status]);
  const onClose = () => setFilter("status", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="Status" onClose={onClose}>
      <StatusFilterForm />
    </FilterDrawer>
  );
};

const StatusFilterForm = () => {
  const { statuses: availableStatuses } = useAppSelector(bookingFormOptionsSelector);
  const { isFetching } = useGetBookingFormOptionsQuery();
  const { setFilter, filters } = useBookingFiltersV2();
  const { watch, control, setValue } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { statuses: (filters.status as string[]) ?? [] },
  });

  const selectedStatuses = watch("statuses").map((status) => statusMapping[status]);

  const [openStatusOptions, closedStatusOptions] = useMemo(() => {
    const open = openStatuses.filter((status) => availableStatuses.some((availableStatus) => availableStatus.value === status.id));
    const closed = closedStatuses.filter((status) => availableStatuses.some((availableStatus) => availableStatus.value === status.id));

    return [open, closed];
  }, [availableStatuses]);

  const handleCheckAllOpenStatus = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedClosedStatuses = selectedStatuses?.filter((status) =>
        closedStatuses.some((closedStatus) => closedStatus.id === status.id)
      );
      const isAllOpenStatusSelected = openStatusOptions.every((i) => selectedStatuses.some((status) => status.id === i.id));
      if (e.target.checked && !isAllOpenStatusSelected) {
        const newStatuses = [...openStatusOptions, ...selectedClosedStatuses].map((i) => i.id);

        if (newStatuses.length === 0) {
          setFilter("status", null);
        } else {
          setFilter("status", newStatuses);
          setValue("statuses", newStatuses);
        }
      } else {
        const newStatuses = selectedClosedStatuses.map((i) => i.id);
        if (newStatuses.length === 0) {
          setFilter("status", null);
          setValue("statuses", newStatuses);
        } else {
          setFilter("status", newStatuses);
          setValue("statuses", newStatuses);
        }
      }
    },
    [openStatusOptions, selectedStatuses, setFilter, setValue]
  );

  const isAllOpenStatusSelected = useMemo(() => {
    const statusMap = selectedStatuses?.map((status) => status.id);

    return openStatusOptions.every((i) => statusMap.includes(i.id));
  }, [openStatusOptions, selectedStatuses]);

  if (isFetching) return null;

  return (
    <div className="contents">
      <Typography className="mb-4">Select Status</Typography>
      <CheckboxInput label="Open Jobs" onChange={handleCheckAllOpenStatus} variant="check" checked={isAllOpenStatusSelected} />
      <div className="mt-3 grid gap-5 pl-4">
        <Controller
          control={control}
          name="statuses"
          render={({ field }) => (
            <>
              {openStatusOptions.map((i) => (
                <CheckboxInput
                  key={i.id}
                  id={i.id}
                  label={`${i.legend} - ${i.name}`}
                  value={i.id}
                  startIcon={
                    <BookingStatusIcon
                      status={{
                        color: i.color,
                        icon: i.icon,
                      }}
                    />
                  }
                  checked={Boolean(field.value?.includes(i.id))}
                  onChange={({ target }) => {
                    if (target.checked) {
                      const newStatuses = [...(field.value || []), target.value];
                      field.onChange(newStatuses);
                      setFilter("status", newStatuses.length > 0 ? newStatuses : null);
                    } else {
                      const newStatuses = field.value.filter((value) => value !== target.value);
                      field.onChange(newStatuses);
                      setFilter("status", newStatuses.length > 0 ? newStatuses : null);
                    }
                  }}
                  variant="check"
                />
              ))}
            </>
          )}
        />
      </div>
      <div className="mt-5 grid gap-4">
        <Controller
          control={control}
          name="statuses"
          render={({ field }) => (
            <>
              {closedStatusOptions.map((i) => (
                <CheckboxInput
                  key={i.id}
                  id={i.id}
                  label={`${i.legend} - ${i.name}`}
                  value={i.id}
                  startIcon={<BookingStatusIcon status={{ color: i.color, icon: i.icon }} />}
                  checked={Boolean(field.value?.includes(i.id))}
                  onChange={({ target }) => {
                    if (target.checked) {
                      const newStatuses = [...(field.value || []), target.value];
                      field.onChange(newStatuses);
                      setFilter("status", newStatuses.length > 0 ? newStatuses : null);
                    } else {
                      const newStatuses = field.value.filter((value) => value !== target.value);
                      field.onChange(newStatuses);
                      setFilter("status", newStatuses.length > 0 ? newStatuses : null);
                    }
                  }}
                  variant="check"
                />
              ))}
            </>
          )}
        />
      </div>
    </div>
  );
};
