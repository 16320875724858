import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { permissionsApi } from "@/redux/apis/permissions/permissionsApi";
import { PermissionsState } from "./types";

const defaultPermissions = {
  hasAdminConfigAccess: false,
  hasClientsAccess: false,
  hasConfigAccess: false,
  hasDriversAccess: false,
  hasPrivateNetworkAccess: false,
  hasPricingConfigAccess: false,
  hasTeamDirectoryAccess: false,
  hasVehiclesAccess: false,
  hasAccreditationAccess: false,
  hasAccreditationActionAccess: false,
};

const initialState: PermissionsState = {
  activePermissions: defaultPermissions,
  permissions: [],
};

export const permissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    setActivePermissions: (state, action: PayloadAction<string>) => {
      state.activePermissions = state.permissions.find((p) => p.id === action.payload)?.permissions || defaultPermissions;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(permissionsApi.endpoints.getPermissions.matchFulfilled, (state, { payload }) => {
      state.permissions = payload.data.permissions;
    });
  },
});

export const { setActivePermissions } = permissionSlice.actions;
export default permissionSlice.reducer;
