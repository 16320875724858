import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Panel, Spinner, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { EditVerifiedVehicleParams, EditUnverifiedVehicleParams } from "@/redux/apis/vehicle/types";
import { useAddVehicleDocumentMutation, useUpdateVehicleMutation } from "@/redux/apis/vehicle/vehicleApi";
import { addToast } from "@/utils";
import { PageHeader } from "../../PageHeader/PageHeader";
import { VerificationSidebar } from "./VerificationSidebar";
import { EditVehicleProvider, useEditVehicleContext } from "./context";
import { VehicleDetails } from "./fields/VehicleDetails";
import { VehicleImage } from "./fields/VehicleImage";
import { VehicleRegistration } from "./fields/VehicleRegistration";
import { EditUnverifiedVehicleFormData, EditVerifiedVehicleFormData, unverifiedSchema, verifiedSchema } from "./form";

export const EditVehiclePanel = () => {
  return (
    <EditVehicleProvider>
      <PageHeader.Actions />
      <div className="flex items-start gap-3 p-5">
        <Panel className="flex w-full flex-col gap-5">
          <div className="flex flex-col">
            <Typography variant="h3">Vehicle Details</Typography>
            <Typography className="text-neutral-dark-gray">
              Fill up the last few necessary information to complete your vehicle details
            </Typography>
          </div>
          <Forms />
        </Panel>
        <VerificationSidebar />
      </div>
    </EditVehicleProvider>
  );
};

const Forms = () => {
  const { vehicle } = useEditVehicleContext();

  return vehicle.metadata.isVerified ? <VerifiedVehicleEditForm /> : <UnverifiedVehicleEditForm />;
};

const UnverifiedVehicleEditForm = () => {
  const { vehicle } = useEditVehicleContext();
  const [editVehicle, { isLoading }] = useUpdateVehicleMutation();
  const [addVehicleDocument, { isLoading: isUploading }] = useAddVehicleDocumentMutation();

  const defaults = useMemo(() => {
    if (!vehicle) return undefined;

    return {
      avatar: vehicle.avatar,
      make: {
        name: vehicle.model.make,
        avatar: vehicle.model.avatar,
      },
      model: {
        id: vehicle.model.uuid,
        name: vehicle.model.model,
        class: vehicle.model.class,
      },
      registration: {
        color: vehicle.color ?? undefined,
        expiry: vehicle.metadata.verifiedExpiry ? new Date(vehicle.metadata.verifiedExpiry) : new Date(),
        number: vehicle.registrationNumber,
        state: vehicle.state.uuid,
        vin: vehicle.vin,
        year: vehicle.year ? vehicle.year.toString() : undefined,
      },
      details: {
        bags: vehicle.capacity.bags,
        capacity: vehicle.capacity.pax,
        categories: vehicle.categories.map((category) => category.uuid),
        vehicleOwnerFee: 0,
        // TODO: Add these fields once clarified
        // vehicleOwnerFee: data.own
      },
      documents: {
        //todo: add these fields once api is added
        insuranceExpiry: null,
        accreditationDocuments: [],
        insuranceDocuments: [],
        registrationPdf: [],
      },
    };
  }, [vehicle]);

  const form = useForm<EditUnverifiedVehicleFormData>({
    resolver: zodResolver(unverifiedSchema),
    defaultValues: defaults ?? undefined,
    shouldFocusError: true,
  });

  const handleSave = async (data: EditUnverifiedVehicleFormData) => {
    const documents: EditUnverifiedVehicleParams["documents"] = [];

    if (data.documents.insuranceDocuments && data.documents.insuranceDocuments.length > 0) {
      await Promise.all(
        data.documents.insuranceDocuments.map((file) => {
          const formData = new FormData();
          formData.append("file", file);

          return addVehicleDocument({
            id: vehicle.uuid,
            body: formData,
          })
            .unwrap()
            .then(({ uuid }) => {
              documents.push({
                uuid: uuid,
                document_type: "INSURANCE",
                expiry_date: data.documents.insuranceExpiry ? format(data.documents.insuranceExpiry, "yyyy-MM-dd") : undefined,
              });
            });
        })
      );
    }

    if (data.documents.registrationPdf && data.documents.registrationPdf.length > 0) {
      await Promise.all(
        data.documents.registrationPdf.map((file) => {
          const formData = new FormData();
          formData.append("file", file);

          return addVehicleDocument({
            id: vehicle.uuid,
            body: formData,
          })
            .unwrap()
            .then(({ uuid }) => {
              documents.push({
                uuid: uuid,
                document_type: "REGISTRATION",
                expiry_date: data.registration.expiry ? format(data.registration.expiry, "yyyy-MM-dd") : undefined,
              });
            });
        })
      );
    }

    if (data.documents.accreditationDocuments && data.documents.accreditationDocuments.length > 0) {
      await Promise.all(
        data.documents.accreditationDocuments.map((file) => {
          const formData = new FormData();
          formData.append("file", file);

          return addVehicleDocument({
            id: vehicle.uuid,
            body: formData,
          })
            .unwrap()
            .then(({ uuid }) => {
              documents.push({ uuid: uuid, document_type: "ACCREDITATION", expiry_date: undefined });
            });
        })
      );
    }

    editVehicle({
      id: vehicle.uuid,
      data: {
        state_uuid: data.registration.state,
        registration_number: data.registration.number,
        year: Number(data.registration.year),
        color: data.registration.color ?? undefined,
        capacity_pax: data.details.capacity,
        capacity_bags: data.details.bags,
        vin: data.registration.vin,
        categories: data.details.categories.map((category) => ({ uuid: category })),
        documents: documents,
        maintenance_fee: 0,
      } satisfies EditUnverifiedVehicleParams,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Vehicle successfully updated");
      })
      .catch((e) => {
        getErrorMessages(e).forEach((m) => addToast("danger", m));
      });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSave)} id="edit-vehicle-form" className="flex flex-col gap-5">
        <VehicleImage vehicle={vehicle} />
        {!vehicle.metadata.isVerified && <VehicleRegistration />}
        <VehicleDetails vehicle={vehicle} />
        <div className="flex justify-end gap-4">
          <Button
            variant="primary"
            size="lg"
            type="submit"
            form="edit-vehicle-form"
            disabled={isUploading || isLoading || !form.formState.isDirty}
          >
            {isLoading ? <Spinner /> : "Save Changes"}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

const VerifiedVehicleEditForm = () => {
  const { vehicle } = useEditVehicleContext();
  const [editVehicle, { isLoading }] = useUpdateVehicleMutation();
  const [addVehicleDocument, { isLoading: isUploading }] = useAddVehicleDocumentMutation();

  const defaults = useMemo(() => {
    if (!vehicle) return undefined;

    return {
      avatar: vehicle.avatar,
      details: {
        bags: vehicle.capacity.bags,
        capacity: vehicle.capacity.pax,
        categories: vehicle.categories.map((category) => category.uuid),
        vehicleOwnerFee: 0,
        // TODO: Add these fields once clarified
        // vehicleOwnerFee: data.own
      },
      documents: {
        //todo: add these fields once api is added
        insuranceExpiry: null,
        accreditationDocuments: [],
        insuranceDocuments: [],
        registrationPdf: [],
      },
    };
  }, [vehicle]);

  const form = useForm<EditVerifiedVehicleFormData>({
    resolver: zodResolver(verifiedSchema),
    defaultValues: defaults ?? undefined,
    shouldFocusError: true,
  });

  const handleSave = async (data: EditVerifiedVehicleFormData) => {
    const documents: EditVerifiedVehicleParams["documents"] = [];

    if (data.documents.insuranceDocuments && data.documents.insuranceDocuments.length > 0) {
      await Promise.all(
        data.documents.insuranceDocuments.map((file) => {
          const formData = new FormData();
          formData.append("file", file);

          return addVehicleDocument({
            id: vehicle.uuid,
            body: formData,
          })
            .unwrap()
            .then(({ uuid }) => {
              documents.push({
                uuid: uuid,
                document_type: "INSURANCE",
                expiry_date: data.documents.insuranceExpiry ? format(data.documents.insuranceExpiry, "yyyy-MM-dd") : undefined,
              });
            });
        })
      );
    }

    if (data.documents.accreditationDocuments && data.documents.accreditationDocuments.length > 0) {
      await Promise.all(
        data.documents.accreditationDocuments.map((file) => {
          const formData = new FormData();
          formData.append("file", file);

          return addVehicleDocument({
            id: vehicle.uuid,
            body: formData,
          })
            .unwrap()
            .then(({ uuid }) => {
              documents.push({ uuid: uuid, document_type: "ACCREDITATION", expiry_date: undefined });
            });
        })
      );
    }

    editVehicle({
      id: vehicle.uuid,
      data: {
        capacity_pax: data.details.capacity,
        capacity_bags: data.details.bags,
        categories: data.details.categories.map((category) => ({ uuid: category })),
        documents: documents,
        maintenance_fee: 0,
      } satisfies EditVerifiedVehicleParams,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Vehicle successfully updated");
      })
      .catch((e) => {
        getErrorMessages(e).forEach((m) => addToast("danger", m));
      });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSave)} id="edit-vehicle-form" className="flex flex-col gap-5">
        <VehicleImage vehicle={vehicle} />
        <VehicleDetails vehicle={vehicle} />
        <div className="flex justify-end gap-4">
          <Button
            variant="primary"
            size="lg"
            type="submit"
            form="edit-vehicle-form"
            disabled={isUploading || isLoading || !form.formState.isDirty}
          >
            {isLoading ? <Spinner /> : "Save Changes"}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
