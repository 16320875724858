import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Loading } from "@/components/atoms";
import { GeneralFooter, GeneralHeader } from "@/components/organisms";
import { useOperator } from "@/hooks";
import { useLazyGetProviderQuery, useVerifyProviderTokenQuery } from "@/redux/apis/auth/authApi";
import { useAppDispatch } from "@/redux/hooks";
import { setActiveOperator } from "@/redux/slices/operator/operatorSlice";
import { Restricted, Expired } from "..";
import { SignUpFormInvite } from "@/components/organisms/SignUp/forms/SignUpInviteForm";

export const SignUpInvite = () => {
  const dispatch = useAppDispatch();
  const { token } = useParams();
  const { singleTenant, flags: { clientSignUpsEnabled, driverSignUpsEnabled } } = useOperator();
  const { isLoading: isVerifying, isSuccess: isValidToken, data, isError: isErrorVerify } = useVerifyProviderTokenQuery(token || "");
  const [getProvider, { isLoading, isError: isErrorGet }] = useLazyGetProviderQuery();
  const signUpEnabled = singleTenant ? (clientSignUpsEnabled || driverSignUpsEnabled) : true;
  const isError = isErrorVerify || isErrorGet;

  useEffect(() => {
    if (isValidToken && data) {
      getProvider(data.provider_uuid)
        .unwrap()
        .then((operator) => dispatch(setActiveOperator(operator)));
    }    
  }, [token, getProvider, data, isValidToken, dispatch]);

  if (isVerifying || isLoading) return <Loading />;
  if (!token || isError)
  return (
    <Expired
      title="Invitation Expired"
      description="Sorry, the link invitation is not valid anymore. Please contact the person who invited you."
    />
  );

  return (
    <div className="flex flex-col min-h-screen overflow-hidden md:flex-row">
      <div className="flex flex-1 flex-col relative">
        <GeneralHeader action="login" />
          {signUpEnabled && data ? (
            <Container className="flex flex-1 justify-center w-full max-w-[1040px] gap-5 py-8 md:py-[100px] px-5">
              <SignUpFormInvite email={data.email} inviteToken={token} />
            </Container>
          ) : <Restricted />}
        <GeneralFooter />
      </div>
    </div>
  );
};
