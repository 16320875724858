import { GetAddOnRawResponse, GetAddOnResponse } from "./types";


export const transformAddOns = (res: GetAddOnRawResponse) => {
  const { _embedded: { add_on } } = res;

  const response: GetAddOnResponse = {
    data: { 
      addOns: add_on.map(i => ({
        identifier: i.identifier,
        name: i.name,
        cost: i.cost,
        costType: i.cost_type,
        currency: i.currency,
        type: i.type,
        freeTrialPeriod: i.free_trial_period,
        freeTrialExpiry: i.free_trial_expiry,
        enabled: i.enabled,
        featured: i.featured,
        available: i.available,
        premium: i.premium,
      }))
    },
  };

  return response;
};