import { ComponentProps } from "react";
import { Button, Typography } from "@/components/atoms";
import { clsx } from "@/utils";

interface VehicleModelButtonProps extends Omit<ComponentProps<typeof Button>, "type" | "iconName"> {
  name: string;
  onClick: () => void;
}

export const VehicleModelButton = ({ onClick, className, name, ...props }: VehicleModelButtonProps) => {
  return (
    <Button
      onClick={onClick}
      variant="tertiary"
      className={clsx("group !h-12 w-full !justify-start rounded-lg border border-neutral-gray bg-white pt-5", className)}
      {...props}
    >
      <Typography variant="action" className="capitalize group-hover:text-primary">
        {name}
      </Typography>
    </Button>
  );
};
