export type AdminConfigState = {
  businessDetails: BusinessDetailsState;
  websiteDetails: WebsiteDetailsState;
  emailNotifications: EmailNotificationsState;
  smsVoiceNotifications: SMSVoiceNotificationsState;
  billingSettings: BillingSettingsState;
  accessControl: AccessControlState;
  dispatchSettings: DispatchSettingsState;
  waitingTimes: WaitingTimesState;
  displayOptions: DisplayOptionsState;
};

export type BusinessDetails = {
  businessName: string;
  businessTagline: string;
  businessAddress: string;
  businessRegistrationType: string;
  businessRegistrationId: string;
  businessPhoneNumberLocal: string;
  businessPhoneNumberInternational: string;
  businessSupportEmail: string;
};

export type BusinessDetailsState = {
  details: BusinessDetails;
  errors: string[];
};

export const BusinessDetailsInitialState: BusinessDetailsState = {
  details: {
    businessName: "",
    businessTagline: "",
    businessAddress: "",
    businessRegistrationType: "",
    businessRegistrationId: "",
    businessPhoneNumberLocal: "",
    businessPhoneNumberInternational: "",
    businessSupportEmail: "",
  },
  errors: [],
};

export type WebsiteDetails = {
  brochureUrl: string;
  termsAndConditionsUrl: string;
  meetingPointsUrl: string;
  priceGuideUrl: string;
  findYourDriverUrl: string;
  googleReviewsUrl: string;
};

export type WebsiteDetailsState = {
  details: WebsiteDetails;
  errors: string[];
};

export const WebsiteDetailsInitialState: WebsiteDetailsState = {
  details: {
    brochureUrl: "",
    termsAndConditionsUrl: "",
    meetingPointsUrl: "",
    priceGuideUrl: "",
    findYourDriverUrl: "",
    googleReviewsUrl: "",
  },
  errors: [],
};

export type EmailNotifications = {
  phoneDriverHotline: string;
  emailDriverHotline: string;
  enabledBccAllEmails: boolean;
  emailAddressBccAll: string;
  enabledBookingNotifications: boolean;
  emailBookingNotifications: string;
  enabledDriverNotifications: boolean;
  emailDriverNotifications: string;
  enabledLowJobRatingNotifications: boolean;
  emailLowJobNotifications: string;
  enabledMemberRegistrationNotifications: boolean;
  emailMemberRegistrationNotifications: string;
  enabledFailedPaymentNotifications: boolean;
  emailFailedPaymentNotifications: string;
  emailQuoteNotifications: string;
  enabledQuoteNotifications: boolean;
};

export type EmailNotificationsState = {
  details: EmailNotifications;
};

export const EmailNotificationsInitialState: EmailNotificationsState = {
  details: {
    phoneDriverHotline: "",
    emailDriverHotline: "",
    enabledBccAllEmails: false,
    emailAddressBccAll: "",
    enabledBookingNotifications: false,
    emailBookingNotifications: "",
    enabledDriverNotifications: false,
    emailDriverNotifications: "",
    enabledLowJobRatingNotifications: false,
    emailLowJobNotifications: "",
    enabledMemberRegistrationNotifications: false,
    emailMemberRegistrationNotifications: "",
    enabledFailedPaymentNotifications: false,
    emailFailedPaymentNotifications: "",
    emailQuoteNotifications: "",
    enabledQuoteNotifications: false,
  },
};

export type SMSVoiceNotifications = {
  enabledUnallocatedClosePickupNotifications: boolean;
  unallocatedClosePickupPhoneNumber: string;
  enabledDriverNotOnWayNotifications: boolean;
  driverNotOnWayPhoneNumber: string;
  driverNotOnWayEmailAddress: string;
  enabledSmsCustomerReminderAndOpchangeNotifications: boolean;
};

export type SMSVoiceNotificationsState = {
  details: SMSVoiceNotifications;
  errors: string[];
};

export const SMSVoiceNotificationsInitialState: SMSVoiceNotificationsState = {
  details: {
    enabledUnallocatedClosePickupNotifications: false,
    unallocatedClosePickupPhoneNumber: "",
    enabledDriverNotOnWayNotifications: false,
    driverNotOnWayPhoneNumber: "",
    driverNotOnWayEmailAddress: "",
    enabledSmsCustomerReminderAndOpchangeNotifications: false,
  },
  errors: [],
};

export type BillingSettings = {
  defaultOperatorCommissionRate: string;
  enabledPassOnCreditCardFees: boolean;
  defaultServiceFeeRate: string;
  enabledOperatorPaysOwnDrivers: boolean;
  enabledPriceInGuestEmail: boolean;
  closeJobAutoApproveValue: string;
  defaultUniversalPricingAdjustmentRate: string;
};

export type BillingSettingsState = {
  details: BillingSettings;
  errors: string[];
};

export const BillingSettingsInitialState: BillingSettingsState = {
  details: {
    defaultOperatorCommissionRate: "",
    enabledPassOnCreditCardFees: false,
    defaultServiceFeeRate: "",
    enabledOperatorPaysOwnDrivers: false,
    enabledPriceInGuestEmail: false,
    closeJobAutoApproveValue: "",
    defaultUniversalPricingAdjustmentRate: "",
  },
  errors: [],
};

export type AccessControl = {
  enabledDriverSignup: boolean;
  enabledClientSignup: boolean;
};

export type AccessControlState = {
  details: AccessControl;
  errors: string[];
};

export const AccessControlInitialState: AccessControlState = {
  details: {
    enabledDriverSignup: false,
    enabledClientSignup: false,
  },
  errors: [],
};

export type TimeFrame = "minute" | "hour" | "day" | "";

export type DispatchSettings = {
  enabledAutomaticDispatch: boolean;
  // dispatchTier0Time: string;
  // dispatchTier0Timeframe: TimeFrame;
  dispatchTier1Time: string;
  dispatchTier1Timeframe: TimeFrame;
  dispatchTier2Time: string;
  dispatchTier2Timeframe: TimeFrame;
  dispatchTier3Time: string;
  dispatchTier3Timeframe: TimeFrame;
  dispatchSpecialEventTier0Time: string;
  dispatchSpecialEventTier0Timeframe: TimeFrame;
  dispatchSpecialEventTier1Time: string;
  dispatchSpecialEventTier1Timeframe: TimeFrame;
  dispatchSpecialEventTier2Time: string;
  dispatchSpecialEventTier2Timeframe: TimeFrame;
  dispatchSpecialEventTier3Time: string;
  dispatchSpecialEventTier3Timeframe: TimeFrame;
  enabledAutomaticDispatchPrivateNetwork: boolean;
  dispatchAutoPrivateNetworkTime: string;
  dispatchAutoPrivateNetworkTimeframe: TimeFrame;
  enabledAutomaticDispatchPublicNetwork: boolean;
  dispatchAutoPublicNetworkTime: string;
  dispatchAutoPublicNetworkTimeframe: TimeFrame;
  dispatchAutoOwnFleetRetryCount: string;
  dispatchAutoPrivateNetworkRetryCount: string;
  enabledHoldBeforeDispatch: boolean;
  dispatchHoldBeforeDispatchTime: string;
  dispatchHoldBeforeDispatchTimeframe: TimeFrame;
  dispatchAsapHoldBeforeDispatchTime: string;
  dispatchAsapHoldBeforeDispatchTimeframe: TimeFrame;
};

export type DispatchSettingsState = {
  details: DispatchSettings;
  errors: string[];
};

export const DispatchSettingsInitialState: DispatchSettingsState = {
  details: {
    enabledAutomaticDispatch: false,
    // dispatchTier0Time: "",
    // dispatchTier0Timeframe: "",
    dispatchTier1Time: "",
    dispatchTier1Timeframe: "",
    dispatchTier2Time: "",
    dispatchTier2Timeframe: "",
    dispatchTier3Time: "",
    dispatchTier3Timeframe: "",
    dispatchSpecialEventTier0Time: "",
    dispatchSpecialEventTier0Timeframe: "",
    dispatchSpecialEventTier1Time: "",
    dispatchSpecialEventTier1Timeframe: "",
    dispatchSpecialEventTier2Time: "",
    dispatchSpecialEventTier2Timeframe: "",
    dispatchSpecialEventTier3Time: "",
    dispatchSpecialEventTier3Timeframe: "",
    enabledAutomaticDispatchPrivateNetwork: false,
    dispatchAutoPrivateNetworkTime: "",
    dispatchAutoPrivateNetworkTimeframe: "",
    enabledAutomaticDispatchPublicNetwork: false,
    dispatchAutoPublicNetworkTime: "",
    dispatchAutoPublicNetworkTimeframe: "",
    dispatchAutoOwnFleetRetryCount: "",
    dispatchAutoPrivateNetworkRetryCount: "",
    enabledHoldBeforeDispatch: false,
    dispatchHoldBeforeDispatchTime: "",
    dispatchHoldBeforeDispatchTimeframe: "",
    dispatchAsapHoldBeforeDispatchTime: "",
    dispatchAsapHoldBeforeDispatchTimeframe: "",
  },
  errors: [],
};

export type WaitingTimes = {
  internationalAirport: string;
  domesticAirport: string;
  standard: string;
};

export type WaitingTimesState = {
  details: WaitingTimes;
  errors: string[];
};

export const WaitingTimesInitialState: WaitingTimesState = {
  details: {
    internationalAirport: "",
    domesticAirport: "",
    standard: "",
  },
  errors: [],
};

export type DisplayOptions = {
  bookingMaxNumberOfStops: string;
  enabledBookingHistoryDisplayOperatorIdentifier: boolean;
  enabledBookingHistoryUseOptimizedWideDisplay: boolean;
  enabledBookingManagerDashboardNoteFunctionality: boolean;
};

export type DisplayOptionsState = {
  details: DisplayOptions;
  errors: string[];
};

export const DisplayOptionsInitialState: DisplayOptionsState = {
  details: {
    bookingMaxNumberOfStops: "",
    enabledBookingHistoryDisplayOperatorIdentifier: false,
    enabledBookingHistoryUseOptimizedWideDisplay: false,
    enabledBookingManagerDashboardNoteFunctionality: false,
  },
  errors: [],
};
