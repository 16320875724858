import Assistant from "@/assets/images/signup/assistant.svg";
import Driver from "@/assets/images/signup/driver.svg";
import Manager from "@/assets/images/signup/manager.svg";
import Operator from "@/assets/images/signup/operator.svg";
import Passenger from "@/assets/images/signup/passenger.svg";
import { ImageSelectItem, Panel, Typography } from "@/components/atoms";
import { useOperator, useSiteConfig } from "@/hooks";
import { useSearchParams } from "react-router-dom";
import { useSignUpContext } from "../context";
import { userTypes } from "../fixtures";
import { UserTypeData } from "../types";

const userTypeImage: Record<string, string> = {
  passenger: Passenger,
  assistant: Assistant,
  manager: Manager,
  driver: Driver,
  operator: Operator,
};

export const UserSelectForm = () => {
  const { domain } = useSiteConfig();
  const {
    name,
    singleTenant,
    registerLinks: { driver },
    domainDriver,
    flags: { clientSignUpsEnabled, driverSignUpsEnabled },
  } = useOperator();
  const { setStep, setUserTypeData } = useSignUpContext();
  const [params] = useSearchParams();
  const paramsUserType = params.get("type");
  const isClientType = paramsUserType === "client";

  const handleSelectUserType = (userTypeData: UserTypeData) => {
    setUserTypeData(userTypeData);
    switch (userTypeData.type) {
      case "operator":
        setStep("signup");
        break;
      case "driver":
        if (domainDriver === domain && !singleTenant) setStep("provider-select");
        else if (domainDriver === domain && singleTenant) setStep("signup");
        else window.location.replace(driver);
        break;
      case "assistant":
      case "passenger":
      case "manager":
        if (!singleTenant) setStep("provider-select");
        else setStep("signup");
        break;
      default:
        setStep("provider-select");
    }
  };

  const enabledUserTypes = Object.values(userTypes).filter((o) => {
    if (singleTenant) {
      if (o.type === "driver" && (!driverSignUpsEnabled || isClientType)) return false;
      if ((o.type === "passenger" || o.type === "assistant" || o.type === "manager") && !clientSignUpsEnabled) return false;
    } else if (o.type === "driver" && isClientType) return false;
    
    return true;
  });

  let options = Object.values(enabledUserTypes);
  if (singleTenant || isClientType) options = options.filter((options) => options.type !== "operator");

  return (
    <div>
      <Panel>
        <div className="flex flex-col gap-y-1.5 mb-7">
          <Typography variant="h1">Sign up</Typography>
        </div>
        <div className="flex flex-col gap-y-4">
          <Typography variant="title">{`How are you primarily using ${name}?`}</Typography>
          <div className="grid grid-cols-2 gap-2.5 md:grid-cols-3 lg:grid-cols-5 md:gap-5">
            {options.map((o) => (
              <ImageSelectItem
                key={o.type}
                src={userTypeImage[o.type]}
                label={o.name}
                onClick={() => handleSelectUserType(o)}
              />
            ))}
          </div>
        </div>
      </Panel>
    </div>
  );
};