import { useNavigate } from "react-router-dom";
import { PageHeader } from "../../PageHeader/PageHeader";
import { CheckEligibility } from "./CheckEligibility";
import { SelectMake } from "./SelectMake";
import { SelectModel } from "./SelectModel";
import { SelectVehicleType } from "./SelectVehicleType";
import { SelectVerification } from "./SelectVerification";
import { AddVehicleProvider, useAddVehicleContext } from "./context";

export const AddVehiclePanel = () => {
  return (
    <AddVehicleProvider>
      <Header />
      <div className="flex flex-col items-center p-5">
        <Steps />
      </div>
    </AddVehicleProvider>
  );
};

const Header = () => {
  const navigate = useNavigate();
  const { step, setStep } = useAddVehicleContext();

  const handleBack = () => {
    switch (step) {
      case "select-type":
        navigate("../");
        break;
      case "select-make":
        setStep("select-type");
        break;
      case "select-model":
        setStep("select-make");
        break;
      case "model-not-showing":
        setStep("select-model");
        break;
      case "check-eligibility":
        setStep("select-model");
        break;
      case "select-verification":
        setStep("select-model");
        break;
      case "auto-verification":
        setStep("select-verification");
        break;
      default:
        throw new Error("Invalid step");
    }
  };

  return <PageHeader.Actions onBack={handleBack} />;
};

const Steps = () => {
  const { step } = useAddVehicleContext();

  switch (step) {
    case "select-type":
      return <SelectVehicleType />;
    case "select-make":
      return <SelectMake />;
    case "select-model":
    case "model-not-showing":
      return <SelectModel />;
    case "check-eligibility":
      return <CheckEligibility />;
    case "select-verification":
    case "auto-verification":
    case "manual-verification":
      return <SelectVerification />;

    default:
      return;
  }
};
