import { createApi } from "@reduxjs/toolkit/dist/query/react";
import camelcaseKeys from "camelcase-keys";
import { baseQueryWithProvider } from "@/redux/baseQuery";
import { GetVehiclesResponse, Vehicle } from "@/redux/slices/vehicle/types";
import { transformGetVehiclesResponse } from "./helpers";
import {
  CreateVehicleParams,
  EditUnverifiedVehicleParams,
  EditVerifiedVehicleParams,
  GetVehicleCategoriesRawResponse,
  RawVehicle,
} from "./types";

export const vehicleApi = createApi({
  baseQuery: baseQueryWithProvider("vehicle"),
  reducerPath: "vehicleApi",
  endpoints: (builder) => ({
    getVehicle: builder.query<Vehicle, string>({
      query: (id) => `/${id}`,
      transformResponse: (response: RawVehicle) => camelcaseKeys(response, { deep: true }),
    }),
    getVehicles: builder.query<GetVehiclesResponse, void>({
      query: () => "",
      transformResponse: transformGetVehiclesResponse,
      providesTags: ["Vehicle"],
    }),
    archiveVehicle: builder.mutation<void, string>({
      query: (id) => `/${id}`,
      extraOptions: { method: "DELETE" },
      invalidatesTags: (_, __, id) => [{ type: "Vehicle", id }],
    }),
    createVehicle: builder.mutation<Vehicle, CreateVehicleParams>({
      query: (data) => ({
        url: "",
        method: "POST",
        body: data,
      }),
      transformResponse: (response: RawVehicle) => camelcaseKeys(response, { deep: true }),
      invalidatesTags: ["Vehicle"],
    }),
    addAvatar: builder.mutation<{ avatar: string }, { id: string; body: FormData }>({
      query: ({ id, body }) => ({
        url: `/${id}/avatar`,
        method: "POST",
        body: body,
      }),
    }),
    updateVehicle: builder.mutation<Vehicle, { id: string; data: EditVerifiedVehicleParams | EditUnverifiedVehicleParams }>({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response: RawVehicle) => camelcaseKeys(response, { deep: true }),
      invalidatesTags: (_, __, { id }) => [{ type: "Vehicle", id }],
    }),
    getVehicleCategoryOptions: builder.query<GetVehicleCategoriesRawResponse, string>({
      query: (id: string) => `/${id}/options`,
    }),
    addVehicleDocument: builder.mutation<{ uuid: string }, { id: string; body: FormData }>({
      query: ({ id, body }) => ({
        url: `/${id}/attachment`,
        method: "POST",
        body,
      }),
    }),
  }),
  tagTypes: ["Vehicle"],
});

export const {
  useUpdateVehicleMutation,
  useGetVehiclesQuery,
  useArchiveVehicleMutation,
  useCreateVehicleMutation,
  useGetVehicleQuery,
  useAddAvatarMutation,
  useGetVehicleCategoryOptionsQuery,
  useAddVehicleDocumentMutation,
} = vehicleApi;
