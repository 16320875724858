import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { AddOnTextInput, CheckboxInput, DropdownCheckbox, DropdownSelect, Icon, Tooltip, Typography } from "@/components/atoms";
import { useGetVehicleCategoryOptionsQuery } from "@/redux/apis/vehicle/vehicleApi";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { EditUnverifiedVehicleFormData } from "../form";
import { VehicleDocuments } from "./VehicleDocuments";

export const VehicleDetails = ({ vehicle }: { vehicle: Vehicle }) => {
  const {
    register,
    control,
    formState: { errors },
    watch,
  } = useFormContext<EditUnverifiedVehicleFormData>();

  const { isFetching, data } = useGetVehicleCategoryOptionsQuery(vehicle.uuid);

  const categoryOptions = useMemo(() => data?.categories.map((category) => ({ name: category.name, value: category.uuid })) ?? [], [data]);

  const categories = watch("details.categories");
  const maxCapacity = vehicle.model.maxCapacity;

  return (
    <div className="grid grid-cols-2 gap-5 gap-y-6">
      <label htmlFor="registration.number" className="flex flex-col">
        <Typography className="relative flex items-center gap-2 leading-[2rem]">
          Select a Vehicle Category
          <Tooltip content="Select the category of the vehicle" placement="top">
            <Icon name="InfoCircle" variant="Bold" size="md" className="relative z-10 rotate-180 text-neutral-dark-gray" />
          </Tooltip>
        </Typography>
        <DropdownCheckbox
          className="w-full [&_.dropdown-panel]:z-20"
          placeholder="Select Vehicle Category"
          selectedCount={categories?.length}
          getSelectedLabel={(selected) => (selected ? `${selected} Vehicle Categories selected` : "")}
          hasError={!!errors.details?.categories}
          aria-disabled={isFetching}
        >
          {categoryOptions.map((i, idx) => (
            <CheckboxInput key={idx} label={i.name} value={i.value} {...register("details.categories")} variant="check" />
          ))}
        </DropdownCheckbox>
      </label>
      <label htmlFor="details.capacity" className="flex flex-col">
        <Typography className="leading-[2rem]">Capacity</Typography>
        <Controller
          control={control}
          name="details.capacity"
          render={({ field }) => (
            <DropdownSelect
              className="w-full [&_.dropdown-panel]:z-20"
              defaultValue="0"
              placeholder="Select Maximum Capacity"
              options={Array.from({ length: maxCapacity }, (_, i) => (i + 1).toString())}
              value={field.value?.toString()}
              onChange={(value: string) => {
                field.onChange(+value);
              }}
            />
          )}
        />
      </label>
      <label htmlFor="details.bags" className="flex flex-col">
        <Typography className="leading-[2rem]">No. of Bags</Typography>
        <Controller
          control={control}
          name="details.bags"
          render={({ field }) => (
            <DropdownSelect
              className="w-full [&_.dropdown-panel]:z-20"
              defaultValue="0"
              placeholder="Select Maximum Number of Bags"
              options={Array.from({ length: 100 }, (_, i) => i.toString())}
              value={field.value?.toString()}
              onChange={(value: string) => {
                field.onChange(+value);
              }}
            />
          )}
        />
      </label>
      <label htmlFor="details.vehicleOwnerFee" className="flex flex-col">
        <Typography className="leading-[2rem]">Vehicle Owner Fee</Typography>
        <AddOnTextInput
          {...register("details.vehicleOwnerFee", { valueAsNumber: true })}
          id="details.vehicleOwnerFee"
          className="[&>div]:w-full [&_input]:w-full [&_input]:text-right"
          type="number"
          min={0}
          max={100}
          step="0.01"
          placeholder="-"
          endAddOn="%"
          hasError={!!errors.details?.vehicleOwnerFee}
        />
      </label>
      <VehicleDocuments vehicle={vehicle} />
    </div>
  );
};
