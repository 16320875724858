import { format } from "date-fns";
import { Alert, Button, Icon, Panel, Tooltip, Typography } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { getAssetUrl } from "@/utils";
import { useEditVehicleContext } from "./context";

export const VerificationSidebar = () => {
  const {
    vehicle: {
      model,
      metadata: { isVerified },
    },
  } = useEditVehicleContext();

  return (
    <Panel className="w-[350px] shrink-0">
      <Alert type="info" message="We’ve updated your information to match our verified records." />
      <Typography className="mt-3 text-neutral-dark-gray">
        Below are the details automatically filled by the system based on your selections
      </Typography>

      <div className="mt-3 flex items-center gap-3 rounded-lg bg-neutral-surface-gray px-3 py-3">
        <div className="flex items-center justify-center rounded-full border border-solid border-neutral-mid-gray p-2">
          <img
            src={getAssetUrl(model.avatar, { height: 56, width: 56, background: "transparent" })}
            className="h-[28px] w-[28px] rounded-full object-contain"
          />
        </div>
        <Typography variant="title" className="text-neutral-black">
          {model.make} {model.model}
        </Typography>
      </div>
      {isVerified ? <VerifiedTable /> : <NonVerifiedTable />}
    </Panel>
  );
};

const NonVerifiedTable = () => {
  const {
    vehicle: { model },
  } = useEditVehicleContext();
  return (
    <div className="mt-3 grid grid-cols-2 gap-3">
      <div className="col-span-1 flex items-center">
        <Typography className="flex items-center text-neutral-dark-gray">
          Network Eligibility
          <Tooltip
            content={
              <div className="max-w-[295px] font-medium">
                This helps the system match your vehicle accurately within the platform and with external networks, ensuring proper job
                allocations. <br />
                <br />
                <a href="#" className="underline">
                  {" "}
                  Learn more about Network Eligibility
                </a>
              </div>
            }
            placement="top"
          >
            <Icon name="InfoCircle" variant="Bold" className="ml-1 inline-block -rotate-180 text-neutral-dark-gray" size="sm" />
          </Tooltip>
        </Typography>
      </div>
      <div className="col-span-1 flex items-center justify-end">
        <Button variant="custom" className="inline-block !p-0 text-info underline">
          Needs Verification
        </Button>
      </div>
      <div className="col-span-1 flex items-center">
        <Typography className="flex items-center  text-neutral-dark-gray">Engine Type</Typography>
      </div>
      <div className="col-span-1 flex items-center justify-end">
        <Typography className="inline-block capitalize text-neutral-black">{model.engine.toLowerCase()}</Typography>
      </div>
    </div>
  );
};

const VerifiedTable = () => {
  const { vehicle } = useEditVehicleContext();
  const { dateFormat } = useProfile();

  return (
    <div className="mt-3 grid grid-cols-2 gap-3">
      <div className="col-span-1 flex items-center">
        <Typography className="flex items-center text-neutral-dark-gray">Year</Typography>
      </div>
      <div className="col-span-1 flex items-center justify-end">
        <Typography className="inline-block capitalize text-neutral-black">{vehicle.year}</Typography>
      </div>
      <div className="col-span-1 flex items-center">
        <Typography className="flex items-center text-neutral-dark-gray">Color</Typography>
      </div>
      <div className="col-span-1 flex items-center justify-end">
        <Typography className="inline-block capitalize text-neutral-black">{vehicle.color}</Typography>
      </div>
      <div className="col-span-1 flex items-center">
        <Typography className="flex items-center text-neutral-dark-gray">
          Network Eligibility
          <Tooltip
            content={
              <div className="max-w-[295px] font-medium">
                This helps the system match your vehicle accurately within the platform and with external networks, ensuring proper job
                allocations. <br />
                <br />
                <a href="#" className="underline">
                  {" "}
                  Learn more about Network Eligibility
                </a>
              </div>
            }
            placement="top"
          >
            <Icon name="InfoCircle" variant="Bold" className="ml-1 inline-block -rotate-180 text-neutral-dark-gray" size="sm" />
          </Tooltip>
        </Typography>
      </div>
      <div className="col-span-1 flex items-center justify-end">
        <Typography className="inline-block capitalize text-neutral-black">{vehicle.model.class.toLowerCase()}</Typography>
      </div>
      <div className="col-span-1 flex items-center">
        <Typography className="flex items-center text-neutral-dark-gray">VIN number</Typography>
      </div>
      <div className="col-span-1 flex items-center justify-end">
        <Typography className="inline-block capitalize text-neutral-black">{vehicle.vin}</Typography>
      </div>
      {vehicle.metadata.verifiedExpiry && (
        <>
          <div className="col-span-1 flex items-center">
            <Typography className="flex items-center text-neutral-dark-gray">Registration Expiry</Typography>
          </div>
          <div className="col-span-1 flex items-center justify-end">
            <Typography className="inline-block capitalize text-neutral-black">
              {format(new Date(vehicle.metadata.verifiedExpiry), dateFormat)}
            </Typography>
          </div>
        </>
      )}
      <div className="col-span-1 flex items-center">
        <Typography className="flex items-center text-neutral-dark-gray">State</Typography>
      </div>
      <div className="col-span-1 flex items-center justify-end">
        <Typography className="inline-flex gap-1 capitalize text-neutral-black">
          <img
            src={getAssetUrl(vehicle.state.image, {
              height: 60,
              width: 60,
              fit: "cover",
            })}
            alt={vehicle.state.name}
            className="mr-1 h-[30px] w-[30px]"
          />
          {vehicle.state.abbreviation}
        </Typography>
      </div>
    </div>
  );
};
