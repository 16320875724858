import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithAccount } from "@/redux/baseQuery";
import { transformPermissions } from "./helpers";
import { GetPermissionsResponse } from "./types";

export const permissionsApi = createApi({
  reducerPath: "permissionsApi",
  baseQuery: baseQueryWithAccount(),
  endpoints: (builder) => ({
    getPermissions: builder.query<GetPermissionsResponse, void>({
      query: () => `/permissions`,
      transformResponse: transformPermissions,
      providesTags: ["Permissions"],
    }),
  }),
  tagTypes: ["Permissions"],
});

export const { useLazyGetPermissionsQuery } = permissionsApi;
