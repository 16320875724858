import { z } from "zod";

const registrationFields = z.object({
  number: z.string(),
  year: z
    .string({
      message: "Vehicle year is required",
    })
    .optional(),
  expiry: z.date({
    message: "Vehicle registration expiry is required",
  }),
  vin: z
    .string({
      message: "Vehicle VIN is required",
    })
    .length(17, "The input must be 17 characters long"),
  state: z.string({
    message: "Vehicle state is required",
  }),
  color: z
    .string({
      message: "Vehicle color is required",
    })
    .optional(),
});

const requiredFields = {
  avatar: z.string().optional(),
  documents: z.object({
    insuranceExpiry: z.date().nullable(),
    insuranceDocuments: z.array(z.instanceof(File)).nullable(),
    registrationPdf: z.array(z.instanceof(File)).nullable(),
    accreditationDocuments: z.array(z.instanceof(File)).nullable(),
  }),
  details: z.object({
    categories: z.array(z.string()),
    capacity: z.number(),
    bags: z.number(),
    vehicleOwnerFee: z.number().min(0).max(100),
  }),
  make: z.object({
    name: z.string(),
    avatar: z.string(),
  }),
  model: z.object({
    id: z.string(),
    name: z.string(),
    class: z.string(),
  }),
};

export const verifiedSchema = z.object({
  ...requiredFields,
});

export const unverifiedSchema = z.object({
  ...requiredFields,
  registration: registrationFields,
});

export type EditVerifiedVehicleFormData = z.infer<typeof verifiedSchema>;
export type EditUnverifiedVehicleFormData = z.infer<typeof unverifiedSchema>;
