import { createPortal } from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { AbilityProvider, ability } from "./casl";
import { store } from "./redux/store";
import { routes } from "./routes";

const basename = import.meta.env.VITE_RIDEMINDER_BASE_NAME || "";

export default function App() {
  const router = createBrowserRouter(routes, { basename: `/${basename}` });

  return (
    <>
      <HelmetProvider>
        <Provider store={store}>
          <AbilityProvider value={ability}>
            <RouterProvider router={router} />
          </AbilityProvider>
        </Provider>
      </HelmetProvider>
      {createPortal(<Toaster />, document.body)}
    </>
  );
}
