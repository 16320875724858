import { Button, Icon, Panel, SearchInput, Spinner, Typography } from "@/components/atoms";
import { useSiteConfig } from "@/hooks";
import { useLazyVerifyProviderQuery } from "@/redux/apis/auth/authApi";
import { useAppDispatch } from "@/redux/hooks";
import { useSignUpContext } from "../context";
import { useEffect, useState } from "react";
import { Operator } from "@/redux/slices/operator/types";
import { setActiveOperator } from "@/redux/slices/operator/operatorSlice";
import { OperatorEmblem } from "../contents";
import { addToast } from "@/utils";

export const ProviderSelectForm = () => {
  const dispatch = useAppDispatch();
  const { apiUrl } = useSiteConfig();
  const { setStep, userTypeData } = useSignUpContext();
  const [verifyProvider, { isFetching }] = useLazyVerifyProviderQuery();
  const [isProviderInvalid, setIsProviderInvalid] = useState<boolean>(false);
  const [selectedOperator, setSelectedOperator] = useState<Operator>();
  const [search, setSearch] = useState<string>();

  useEffect(() => {
    if (search) {
      verifyProvider({ providerCode: search, apiUrl, forDriver: userTypeData?.type === "driver" })
        .unwrap()
        .then((operator) => {
          setIsProviderInvalid(false);
          setSelectedOperator(operator);
        })
        .catch(() => {
          setIsProviderInvalid(true);
          setSelectedOperator(undefined);
        });
    } else {
      setIsProviderInvalid(false);
      setSelectedOperator(undefined);
    };
  }, [search, verifyProvider, apiUrl, userTypeData]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedOperator && userTypeData) {
      const { singleTenant, flags: { clientSignUpsEnabled, driverSignUpsEnabled } } = selectedOperator;
      const isClientType = userTypeData.type === "passenger" || userTypeData.type === "assistant" || userTypeData.type === "manager";
      const isDriverType = userTypeData.type === "driver";

      if (singleTenant) {
        if ((isDriverType && driverSignUpsEnabled) || (isClientType && clientSignUpsEnabled)) {
          dispatch(setActiveOperator(selectedOperator));
          setStep("signup");
        } else addToast("danger", "Sign up is disabled for this Operator. Please contact your administrator.");
      } else {
        dispatch(setActiveOperator(selectedOperator));
        setStep("signup");
      }
    }
  };

  const renderProvider = () => {
    if (isFetching) {
      return (
        <div className="flex flex-col items-center justify-center pt-8 md:pt-[60px] pb-[30px] px-7 md:px-14 gap-y-4">
          <Spinner className="h-12 w-12" />
          <Typography variant="paragraph">Checking Provider Code...</Typography>
        </div>
      );
    }

    if (isProviderInvalid) {
      return (
        <div className="flex flex-col items-center justify-center pt-8 md:pt-[60px] pb-[30px] px-7 md:px-14 gap-y-4">
          <Icon variant="Bold" name="Danger" className="text-warning" />
          <Typography variant="paragraph" className="text-center">We couldn&apos;t find the Transport Provider Code that you&apos;ve entered. Please contact your provider</Typography>
        </div>
      );
    } else if (selectedOperator) {
      return (
        <div className="flex flex-col items-center justify-center pt-8 md:pt-[60px] pb-[30px] px-7 md:px-14 gap-y-4">
          <OperatorEmblem name={selectedOperator.name} emblem={selectedOperator.images.emblem} />
          <Typography variant="action" className="text-center">{selectedOperator.name}</Typography>
        </div>
      );
    }
  };

  const renderButtons = () => ( 
    <div className="flex flex-col md:flex-row md:justify-end gap-4 mt-6">
      <div className="hidden md:block">
        <Button
          variant="secondary"
          startIcon="ArrowLeft"
          size="lg"
          onClick={() => setStep("user-select")}
        >
          Back
        </Button>
      </div>
      <Button
        variant="primary"
        type="submit"
        endIcon="ArrowRight"
        size="lg"
        disabled={!selectedOperator}
        className="w-full md:w-auto"
      >
        Next
      </Button>
      <Button
        variant="secondary"
        startIcon="ArrowLeft"
        size="lg"
        onClick={() => setStep("user-select")}
        className="w-full md:hidden"
      >
        Back
      </Button>
    </div>
  );

  return (
    <form onSubmit={onSubmit} className="w-full max-w-[510px]">
      <Panel>
        <div className="flex flex-col gap-y-1.5 mb-7">
          <Typography variant="h1">Sign up</Typography>
          <Typography variant="paragraph">Join your preferred operator</Typography>
        </div>
        <div className="flex flex-col">
          <Typography variant="h3">Transport Provider Code</Typography>
          <SearchInput 
            className="mt-4" 
            search={search} 
            onSearch={(value) => setSearch(value)} 
            placeholder="Enter your Transport Provider Code" 
            isLoading={isFetching}
          />
          <Typography variant="small" className="text-neutral-dark-gray mt-1">Use the transport provider code to connect with a specific operator. Simply ask the operator for the code.</Typography>
        </div>
        {renderProvider()}
      </Panel>
      {renderButtons()}
    </form>
  );
};