import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithAccount } from "@/redux/baseQuery";
import { transformOperators } from "./helpers";
import { GetOperatorsResponse } from "./types";

export const operatorApi = createApi({
  reducerPath: "operatorApi",
  baseQuery: baseQueryWithAccount(),
  endpoints: (builder) => ({
    getOperators: builder.query<GetOperatorsResponse, void>({
      query: () => "providers?page_size=50",
      transformResponse: transformOperators,
      providesTags: ["Operators"],
    }),
  }),
  tagTypes: ["Operators"],
});

export const { useLazyGetOperatorsQuery } = operatorApi;
