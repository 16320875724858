import { BookingStatus } from "@/redux/slices/booking/types";
import { BookingOffer, BookingOfferDocument } from "@/redux/slices/bookingOffer/types";
import { GetBookingOfferDocumentsRawResponse, GetBookingOfferDocumentsResponse, RawBookingOffer } from "./types";

export const transformBookingOffer = (res: RawBookingOffer) => {
  const {
    uuid,
    job_number,
    reference_external,
    dates,
    passenger,
    vehicle_type,
    stops,
    supplier,
    notes,
    extras,
    transfer_type: { duration },
  } = res;

  const response: BookingOffer = {
    id: uuid,
    jobNumber: job_number,
    status: res.status as BookingStatus,
    referenceExternal: reference_external,
    pickupTime: dates.pickup_time,
    acceptedAt: dates.accepted_at ?? undefined,
    timezone: dates.timezone,
    passenger: passenger
      ? {
          name: passenger.name,
          phone: passenger.phone,
          passengerCount: passenger.count,
        }
      : undefined,
    vehicleType: {
      name: vehicle_type.name,
    },
    stops: stops.map((i) => ({
      address: i.address,
      long: i.long ?? undefined,
      lat: i.lat ?? undefined,
      flight: i.flight ?? undefined,
    })),
    duration: duration ?? undefined,
    supplier: {
      name: supplier.name,
      avatar: supplier.avatar ?? "",
      provider: {
        providerKey: supplier.provider.provider_key,
        name: supplier.provider.name,
        phoneNumber: supplier.provider.phone_number,
      },
      type: supplier.type,
    },
    notes: {
      driver: notes.driver,
      passengerPreference: notes.passenger_preference,
      admin: notes.admin,
      external: notes.external,
    },
    extras,
  };

  return response;
};

export const transformBookingOfferDocuments = (res: GetBookingOfferDocumentsRawResponse) => {
  const {
    _embedded: { document },
  } = res;

  const response: GetBookingOfferDocumentsResponse = {
    data: {
      documents: document.map(
        (i) =>
          ({
            id: i.uuid,
            jobId: i.job_uuid,
            filename: i.filename,
            description: i.description,
            createdAt: i.created_at,
            link: i.link,
            permission: i.permission,
          } as BookingOfferDocument)
      ),
    },
  };

  return response;
};
