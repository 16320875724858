import { Label, Radio, RadioGroup } from "@headlessui/react";
import { isEmpty } from "ramda";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DateInput, DropdownSelect, DropdownSelectOption, ErrorMessage, Icon, TextInput, Tooltip, Typography } from "@/components/atoms";
import { useOperator, useProfile } from "@/hooks";
import { useGetCountryStatesQuery } from "@/redux/apis/resource/resourceApi";
import { clsx } from "@/utils";
import { EditUnverifiedVehicleFormData } from "../form";

const colors = [
  { name: "Black", bgColor: "bg-black" },
  { name: "White", bgColor: "bg-neutral-gray" },
  { name: "Silver", bgColor: "bg-[#C0C0C0]" },
  { name: "Grey", bgColor: "bg-[#808080]" },
  { name: "Beige", bgColor: "bg-[#F5F5DC]" },
  { name: "Red", bgColor: "bg-[#FF0000]" },
  { name: "Blue", bgColor: "bg-[#0000FF]" },
  { name: "Green", bgColor: "bg-[#008000]" },
  { name: "Yellow", bgColor: "bg-[#FFD700]" },
];

export const VehicleRegistration = () => {
  const {
    register,
    control,
    formState: { errors },
    watch,
  } = useFormContext<EditUnverifiedVehicleFormData>();
  const { country } = useOperator();
  const { dateFormat } = useProfile();
  const { isFetching, data: response } = useGetCountryStatesQuery(country);

  const options = useMemo(
    () => response?.data.states.map((state) => ({ name: state.name, value: state.id })) ?? [],
    [response?.data.states]
  );

  const color = watch("registration.color");

  return (
    <div className="grid grid-cols-2 gap-5 gap-y-6">
      <label htmlFor="registration.number" className="flex flex-col">
        <Typography className="leading-[2rem]">Number Plate</Typography>
        <TextInput {...register("registration.number")} id="registration.number" type="text" placeholder="000-0000" />
      </label>
      <label htmlFor="registration.year" className="flex flex-col">
        <Typography className="leading-[2rem]">Vehicle Year</Typography>
        <Controller
          control={control}
          name="registration.year"
          render={({ field }) => (
            <DropdownSelect
              className="w-full [&_.dropdown-panel]:z-20"
              placeholder="Select Vehicle Year"
              options={Array.from({ length: new Date().getFullYear() - 2000 }, (_, i) => (i + 2000 + 1).toString())}
              value={field.value?.toString()}
              onChange={(value) => {
                field.onChange(value);
              }}
            />
          )}
        />
        <ErrorMessage errors={errors} name="registration" message={errors.registration?.year?.message} />
      </label>
      <label htmlFor="registration.expiry" className="flex flex-col [&_.react-datepicker-wrapper]:w-full">
        <Typography className="leading-[2rem]">Registration Expiry</Typography>
        <Controller
          name="registration.expiry"
          control={control}
          render={({ field }) => (
            <DateInput
              className="h-[46px] w-full"
              onChange={(date) => field.onChange(date)}
              selected={field.value}
              placeholderText="-- / -- / ----"
              hasError={!!errors.registration?.expiry}
              dateFormat={dateFormat}
            />
          )}
        />
      </label>
      <label htmlFor="registration.vin" className="flex flex-col">
        <Typography className="leading-[2rem]">VIN</Typography>
        <TextInput {...register("registration.vin")} id="registration.vin" type="text" placeholder="Enter VIN" />
        <ErrorMessage errors={errors} name="registration" message={errors.registration?.vin?.message} />
      </label>
      <label htmlFor="registration." className="flex flex-col">
        <Typography className="leading-[2rem]">State</Typography>

        <Controller
          control={control}
          name="registration.state"
          render={({ field }) => (
            <DropdownSelect
              className="w-full"
              placeholder="Select State"
              isNullable
              options={options}
              value={field.value}
              onChange={(value: DropdownSelectOption["value"]) => {
                field.onChange(value);
              }}
              hasError={!!errors.registration?.state}
              disabled={isEmpty(options) || isFetching}
            />
          )}
        />
      </label>
      <label></label>
      <label htmlFor="details" className="col flex flex-col">
        <Typography variant="paragraph">Vehicle Color</Typography>
        <Controller
          control={control}
          name="registration.color"
          render={({ field }) => (
            <RadioGroup {...field}>
              <div className="mt-2 flex gap-2.5">
                {colors.map((c) => (
                  <Tooltip key={c.name} content={c.name} placement="top">
                    <Radio
                      value={c.name}
                      className={({ checked }) =>
                        clsx(
                          "relative flex h-14 w-14 cursor-pointer items-center justify-center rounded-full p-1.5 focus:outline-none focus:ring-0",
                          { "border border-black": checked }
                        )
                      }
                    >
                      <Label as="span" className="sr-only">
                        {c.name}
                      </Label>
                      <span aria-hidden="true" className={clsx(c.bgColor, "h-11 w-11 flex-shrink-0 rounded-full")} />
                      <Icon isCustom name="check" size="sm" className={clsx("absolute", { hidden: color !== c.name })} />
                    </Radio>
                  </Tooltip>
                ))}
              </div>
            </RadioGroup>
          )}
        />
        <ErrorMessage errors={errors} name="registration" message={errors.registration?.color?.message} />
      </label>
    </div>
  );
};
