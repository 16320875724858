import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loading } from "@/components/atoms";
import { SignUpStep, UserType, UserTypeData } from "@/components/organisms";
import { userTypes } from "@/components/organisms/SignUp/fixtures";
import { NotFound } from "@/components/pages";
import { useProviderCode, useSiteConfig } from "@/hooks";
import { useLazyVerifyProviderQuery } from "@/redux/apis/auth/authApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { logOut } from "@/redux/slices/auth/authSlice";
import { accessTokenSelector } from "@/redux/slices/auth/selectors";
import { setActiveOperator } from "@/redux/slices/operator/operatorSlice";
import { activeOperatorSelector } from "@/redux/slices/operator/selectors";

interface SignUpState {
  userTypeData?: UserTypeData;
  setUserTypeData: React.Dispatch<React.SetStateAction<UserTypeData | undefined>>;
  step: SignUpStep;
  setStep: React.Dispatch<React.SetStateAction<SignUpStep>>;
  formStep: number;
  setFormStep: React.Dispatch<React.SetStateAction<number>>;
}

const SignUpContext = createContext<SignUpState>({
  userTypeData: undefined,
  setUserTypeData: () => ({}),
  step: "user-select",
  setStep: () => ({}),
  formStep: 1,
  setFormStep: () => ({}),
});

export const SignUpContextProvider = ({ children }: React.PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const { domain, apiUrl } = useSiteConfig();
  const providerCode = useProviderCode();
  const [verify, { isError }] = useLazyVerifyProviderQuery();
  const [params] = useSearchParams();
  const paramsUserType = params.get("type");
  const operator = useAppSelector(activeOperatorSelector);
  const accessToken = useAppSelector(accessTokenSelector);
  const [userTypeData, setUserTypeData] = useState<UserTypeData>();
  const [step, setStep] = useState<SignUpStep>("user-select");
  const [formStep, setFormStep] = useState(1);
  const userTypeList = ["passenger", "driver", "assistant", "manager", "operator"];
  const isClientType = paramsUserType === "passenger" || paramsUserType === "assistant" || paramsUserType === "manager";
  const isDriverType = paramsUserType === "driver";
  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken) dispatch(logOut());

    if (!operator)
      verify({ providerCode: providerCode || domain, apiUrl, forDriver: paramsUserType === "driver" })
        .unwrap()
        .then((operator) => {
          dispatch(setActiveOperator(operator));
          if (operator.singleTenant) {
            if ((isDriverType && operator.flags.driverSignUpsEnabled) || (isClientType && operator.flags.clientSignUpsEnabled)) {
              setUserTypeData(userTypes[paramsUserType as UserType]);
              setStep("signup");
            }
          } else {
            if (paramsUserType && userTypeList.includes(paramsUserType)) {
              setUserTypeData(userTypes[paramsUserType as UserType]);
              setStep("signup");
            }
          }
        })
        .catch((e) => {
          console.warn("(Signup) Error verifying provider: ", {
            providerCode: providerCode || domain,
            error: e,
          });

          navigate("/404");
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerCode]);

  useEffect(() => {
    if (operator) {
      if (operator.singleTenant) {
        if ((isDriverType && operator.flags.driverSignUpsEnabled) || (isClientType && operator.flags.clientSignUpsEnabled)) {
          setUserTypeData(userTypes[paramsUserType as UserType]);
          setStep("signup");
        }
      } else {
        if (paramsUserType && userTypeList.includes(paramsUserType)) {
          setUserTypeData(userTypes[paramsUserType as UserType]);
          setStep("signup");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsUserType]);

  if (isError) return <NotFound />;
  if (!operator) return <Loading />;

  return (
    <SignUpContext.Provider
      value={{
        userTypeData,
        setUserTypeData,
        step,
        setStep,
        formStep,
        setFormStep,
      }}
    >
      {children}
    </SignUpContext.Provider>
  );
};

export const useSignUpContext = () => {
  const context = useContext(SignUpContext);
  if (!context) throw new Error("useSignUpContext must be used within a SignUpContextProvider");

  return context;
};
