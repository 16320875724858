import { useClose } from "@headlessui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDebouncedEffect } from "@react-hookz/web";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { TextInput, Typography } from "@/components/atoms";
import { useBookingFiltersV2 } from "../../../hooks/useBookingFiltersV2";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";

const schema = z.object({
  driver: z.string(),
});

type FormData = z.infer<typeof schema>;

export const DriverFilter = () => {
  const {
    filters: { driver },
    setFilter,
  } = useBookingFiltersV2();

  const value = useMemo(() => driver, [driver]);

  const onClose = () => setFilter("driver", undefined);

  return (
    <FilterPopover name="driver" value={value} label="Driver" onClose={onClose}>
      <DriverFilterForm />
    </FilterPopover>
  );
};

interface DriverFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const DriverFilterDrawer = ({ open, onOpenChange }: DriverFilterDrawerProps) => {
  const {
    filters: { driver },
    setFilter,
  } = useBookingFiltersV2();

  const value = useMemo(() => driver, [driver]);

  const onClose = () => setFilter("driver", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="Driver" onClose={onClose}>
      <DriverFilterForm />
    </FilterDrawer>
  );
};

const DriverFilterForm = () => {
  const close = useClose();
  const { setFilter, filters } = useBookingFiltersV2();

  const { watch, register } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { driver: filters.driver ? filters.driver : "" },
  });

  const value = watch("driver");

  useDebouncedEffect(
    () => {
      setFilter("driver", value);
    },
    [value],
    300
  );

  return (
    <form
      className="contents"
      onSubmit={(event) => {
        event.preventDefault();
        close();
      }}
    >
      <Typography className="mb-4">Search Driver</Typography>
      <TextInput {...register("driver")} size="md" iconName="SearchNormal1" placeholder="Search Driver" />
    </form>
  );
};
