import { Userpilot } from "userpilot";
import { getApiHostUrl } from "@/helpers/apiHelpers";
import { Tab } from "./types";

export const getMenuNavigation = (operatorId: string) => {
  const apiHostUrl = getApiHostUrl();
  const isUATorStaging = apiHostUrl.includes("uat") || apiHostUrl.includes("staging");
  const navigation: Tab[] = [
    {
      name: "Bookings",
      to: "/bookings",
      icon: "ArchiveBook",
      badge: "16",
      permission: ["manage", "providerClient"],
      isDashboard: true,
    },
    {
      name: "Vehicles",
      to: isUATorStaging ? "/vehicles" : `/o/${operatorId}/config/vehicle-manager`,
      icon: "Car",
      badge: "4",
      permission: ["manage", "providerConfigVehicle"],
      isDashboard: isUATorStaging,
    },
    {
      name: "Drivers",
      to: "/drivers",
      icon: "UserSquare",
      badge: "12",
      permission: ["manage", "providerDriver"],
      isDashboard: true,
    },
    {
      name: "Clients",
      to: "/clients",
      icon: "Profile2User",
      badge: "5",
      permission: ["manage", "providerClient"],
      isDashboard: true,
    },
    {
      name: "Payments",
      to: "/payments",
      icon: "DollarCircle",
      isDashboard: true,
      permission: ["view", "providerPayments"],
    },
  ];

  return navigation;
};

export const getMoreNavigation = () => {
  const navigation: Tab[] = [
    {
      name: "Private Network",
      to: "/private-network",
      icon: "Global",
      permission: ["manage", "providerPrivateNetwork"],
      isDashboard: true,
    },
    {
      name: "External Suppliers",
      to: "/external-suppliers",
      icon: "People",
      badge: "5",
      permission: ["manage", "providerExternalSupplier"],
      isDashboard: true,
    },
    {
      name: "Companies",
      to: "/companies",
      icon: "Buildings",
      permission: ["view", "providerCompany"],
      isDashboard: true,
    },
    // {
    //   name: "Accreditations",
    //   to: "/accreditations",
    //   icon: "LikeTag",
    //   permission: ["manage", "providerAccreditations"],
    //   isDashboard: true,
    // },
    {
      name: "Rating Manager",
      to: "/rating-manager",
      icon: "Star1",
      permission: ["manage", "providerRatingDriver"],
      isDashboard: true,
    },
    {
      name: "Trip Reporting",
      to: "/trip-reporting",
      icon: "DocumentText",
      permission: ["manage", "providerReportingTrip"],
      isDashboard: true,
    },
    {
      name: "Settings",
      to: "/config",
      icon: "Setting2",
      permission: ["view", "providerConfig"],
      isDashboard: true,
    },
    {
      name: "Help",
      icon: "Lifebuoy",
      to: "",
      onClick: () => Userpilot.trigger("resource_center:6M4ZcjVqyD"),
    },
  ];

  return navigation;
};
