import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { DropdownSelect, type DropdownSelectOption, Typography } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { bookingFormOptionsSelector } from "@/redux/slices/booking/selectors";
import { BookingFilters } from "@/redux/slices/booking/types";
import { useBookingFiltersV2 } from "../../../hooks/useBookingFiltersV2";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";

const schema = z.object({
  transferType: z.string(),
});

type FormData = z.infer<typeof schema>;

const getValue = (transferType: BookingFilters["transferType"], transferTypes: DropdownSelectOption[]) => {
  if (!transferType) return transferType;
  return transferTypes.find((s) => s.value === transferType)?.name;
};

export const TransferTypeFilter = () => {
  const { transferTypes } = useAppSelector(bookingFormOptionsSelector);
  const {
    filters: { transferType },
    setFilter,
  } = useBookingFiltersV2();

  const value = useMemo(() => getValue(transferType, transferTypes), [transferType, transferTypes]);
  const onClose = () => setFilter("transferType", undefined);

  return (
    <FilterPopover className="overflow-visible" name="transferType" value={value} label="Transfer type" onClose={onClose}>
      <TransferTypeFilterForm />
    </FilterPopover>
  );
};

interface TransferTypeFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const TransferTypeFilterDrawer = ({ open, onOpenChange }: TransferTypeFilterDrawerProps) => {
  const { transferTypes } = useAppSelector(bookingFormOptionsSelector);
  const {
    filters: { transferType },
    setFilter,
  } = useBookingFiltersV2();

  const value = useMemo(() => getValue(transferType, transferTypes), [transferType, transferTypes]);
  const onClose = () => setFilter("transferType", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="Transfer type" onClose={onClose} minHeight={400}>
      <TransferTypeFilterForm />
    </FilterDrawer>
  );
};

const TransferTypeFilterForm = () => {
  const { transferTypes } = useAppSelector(bookingFormOptionsSelector);
  const { setFilter, filters } = useBookingFiltersV2();

  const {
    control,
    formState: { errors: formErrors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { transferType: filters.transferType ? filters.transferType : undefined },
  });

  return (
    <div className="contents">
      <Typography className="mb-4">Transfer Type</Typography>

      <Controller
        control={control}
        name="transferType"
        render={({ field }) => (
          <DropdownSelect
            size="md"
            placeholder="Select Transfer Type"
            isNullable
            options={transferTypes}
            value={field.value}
            onChange={(value: DropdownSelectOption["value"]) => {
              field.onChange(value);
              setFilter("transferType", value);
            }}
            hasError={!!formErrors.transferType}
            disabled={transferTypes.length === 0}
          />
        )}
      />
    </div>
  );
};
