import { MyVehiclesPanel } from "@/components/organisms";
import { AddVehicle } from "./subpages/AddVehicle";
import { EditVehicle } from "./subpages/EditVehicle";
import { ViewVehicle } from "./subpages/ViewVehicle";

export const MyVehicles = () => {
  return (
    <div className="mb-12 flex flex-1 flex-col">
      <MyVehiclesPanel />
    </div>
  );
};

MyVehicles.AddVehicle = AddVehicle;
MyVehicles.EditVehicle = EditVehicle;
MyVehicles.ViewVehicle = ViewVehicle;
