import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { CheckboxInput, Typography } from "@/components/atoms";
import { useGetBookingFormOptionsQuery } from "@/redux/apis/booking/bookingApi";
import { useAppSelector } from "@/redux/hooks";
import { bookingFormOptionsSelector } from "@/redux/slices/booking/selectors";
import { useBookingFiltersV2 } from "../../../hooks/useBookingFiltersV2";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";

const schema = z.object({
  state: z.array(z.string()),
});

type FormData = z.infer<typeof schema>;

export const StateFilter = () => {
  const {
    filters: { state },
    setFilter,
  } = useBookingFiltersV2();

  const value = useMemo(() => {
    if (!state) return undefined;

    return state.filter(Boolean).join(", ");
  }, [state]);

  const onClose = () => setFilter("state", undefined);

  return (
    <FilterPopover name="state" value={value} label="State" onClose={onClose}>
      <StateFilterForm />
    </FilterPopover>
  );
};

interface StateFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const StateFilterDrawer = ({ open, onOpenChange }: StateFilterDrawerProps) => {
  const {
    filters: { state },
    setFilter,
  } = useBookingFiltersV2();

  const value = useMemo(() => {
    if (!state) return undefined;

    return state.filter(Boolean).join(", ");
  }, [state]);

  const onClose = () => setFilter("state", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="State" onClose={onClose}>
      <StateFilterForm />
    </FilterDrawer>
  );
};

const StateFilterForm = () => {
  const { isFetching } = useGetBookingFormOptionsQuery();
  const { states } = useAppSelector(bookingFormOptionsSelector);
  const { setFilter, filters } = useBookingFiltersV2();

  const { control, setValue } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { state: (filters.state as string[]) ?? [] },
  });

  const handleCheckAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;

      if (checked) {
        setFilter(
          "state",
          states.map((state) => state.value)
        );
        setValue(
          "state",
          states.map((state) => state.value)
        );
      } else {
        setFilter("state", null);
        setValue("state", []);
      }
    },
    [setFilter, setValue, states]
  );

  const isAllStatesSelected = useMemo(() => {
    if (!filters.state) return false;
    return filters.state.length === states.length;
  }, [filters.state, states]);

  if (isFetching) return null;

  return (
    <div className="contents">
      <Typography className="mb-4">State</Typography>
      <CheckboxInput label="All States" onChange={handleCheckAll} variant="check" checked={isAllStatesSelected} />
      <div className="mt-4 grid gap-4 ">
        <Controller
          control={control}
          name="state"
          render={({ field }) => (
            <>
              {states.map((state) => (
                <CheckboxInput
                  key={state.value}
                  label={state.name}
                  value={state.value}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const newState = [...field.value, e.target.value];
                      field.onChange(newState);
                      setFilter("state", newState.length > 0 ? newState : null);
                    } else {
                      const newState = field.value.filter((i) => i !== e.target.value);
                      field.onChange(newState);
                      setFilter("state", newState.length > 0 ? newState : null);
                    }
                  }}
                  variant="check"
                  checked={field.value.includes(state.value)}
                />
              ))}
            </>
          )}
        />
      </div>
    </div>
  );
};
