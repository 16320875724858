import clsx from "classnames";
import { forwardRef } from "react";
import { slugify } from "@/utils/slugify";
import { Typography } from "../";

export type CheckboxSize = "sm" | "md" | "lg";

export type CheckboxVariant = "square" | "check";

export interface CheckboxInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size"> {
  label: string;
  variant?: CheckboxVariant;
  size?: CheckboxSize;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
}

export const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(
  ({ id, label, className, checked, variant = "square", size = "md", startIcon, endIcon, ...props }, ref) => {
    const squareSizeClass = {
      "h-[8px] w-[8px]": size === "sm",
      "h-[11px] w-[11px]": size === "md",
      "h-[14px] w-[14px]": size === "lg",
    };

    const checkSizeClass = {
      "h-[16px] w-[16px]": size === "sm",
      "h-[19px] w-[19px]": size === "md",
      "h-[22px] w-[22px]": size === "lg",
    };

    const getCheckboxId = () => {
      return id ? id : `checkbox-${slugify(label)}`;
    };

    return (
      <div className={clsx("checkbox group inline-flex items-center", className)}>
        <span
          className={clsx(
            `relative inline-flex rounded border border-neutral-black ${
              checked && variant === "square" ? "bg-primary-light" : "transparent"
            }`,
            { "p-[4px]": variant === "square" }
          )}
        >
          <input
            ref={ref}
            id={getCheckboxId()}
            type="checkbox"
            className={clsx(
              "cursor-pointer rounded-sm !border-none bg-transparent text-primary shadow-transparent",
              { "checked:bg-none": variant === "square" },
              variant === "square" && squareSizeClass,
              variant === "check" && checkSizeClass,
              className
            )}
            checked={checked}
            {...props}
          />
        </span>
        <label htmlFor={getCheckboxId()} className="ml-2.5 flex cursor-pointer flex-row items-center gap-x-1.5 ">
          {startIcon && startIcon}
          <Typography variant="paragraph" className="whitespace-nowrap">
            {label}
          </Typography>
          {endIcon && endIcon}
        </label>
      </div>
    );
  }
);

CheckboxInput.displayName = "CheckboxInput";
