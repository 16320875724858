import z from "zod";
import { getTimeMultiplier } from "./helpers";

export const modals = {
  exit: {
    title: "Discard Changes",
    description: "Changes will not be saved. Do you want to proceed?",
  },
  error: {
    title: "Unable to Save Dispatch Settings",
    description: "There is an error in one of the fields submitted. Please double check your inputs and try again.",
  },
};

export const formSchema = z
  .object({
    enabledAutomaticDispatch: z.boolean(),
    // dispatchTier0Time: z.string().nonempty("Field is required and can't be empty."),
    // dispatchTier0Timeframe: z.string(),
    dispatchTier1Time: z.string().nonempty("Field is required and can't be empty."),
    dispatchTier1Timeframe: z.string(),
    dispatchTier2Time: z.string().nonempty("Field is required and can't be empty."),
    dispatchTier2Timeframe: z.string(),
    dispatchTier3Time: z.string().nonempty("Field is required and can't be empty."),
    dispatchTier3Timeframe: z.string(),
    dispatchSpecialEventTier0Time: z.string().nonempty("Field is required and can't be empty."),
    dispatchSpecialEventTier0Timeframe: z.string(),
    dispatchSpecialEventTier1Time: z.string().nonempty("Field is required and can't be empty."),
    dispatchSpecialEventTier1Timeframe: z.string(),
    dispatchSpecialEventTier2Time: z.string().nonempty("Field is required and can't be empty."),
    dispatchSpecialEventTier2Timeframe: z.string(),
    dispatchSpecialEventTier3Time: z.string().nonempty("Field is required and can't be empty."),
    dispatchSpecialEventTier3Timeframe: z.string(),
    dispatchAutoOwnFleetRetryCount: z.string().nonempty("Field is required and can't be empty."),
    dispatchAutoPrivateNetworkRetryCount: z.string().nonempty("Field is required and can't be empty."),
    enabledHoldBeforeDispatch: z.boolean(),
    dispatchHoldBeforeDispatchTime: z.string().nonempty("Field is required and can't be empty."),
    dispatchHoldBeforeDispatchTimeframe: z.string(),
    dispatchAsapHoldBeforeDispatchTime: z.string().nonempty("Field is required and can't be empty."),
    dispatchAsapHoldBeforeDispatchTimeframe: z.string(),
    enabledAutomaticDispatchPrivateNetwork: z.boolean(),
    dispatchAutoPrivateNetworkTime: z.string().nonempty("Field is required and can't be empty."),
    dispatchAutoPrivateNetworkTimeframe: z.string(),
    enabledAutomaticDispatchPublicNetwork: z.boolean(),
    dispatchAutoPublicNetworkTime: z.string().nonempty("Field is required and can't be empty."),
    dispatchAutoPublicNetworkTimeframe: z.string(),
  })
  // .refine((data) => !(data.dispatchTier0Timeframe === "hour" && data.dispatchTier0Time && Number(data.dispatchTier0Time) < 4), {
  //   message: "Minimum dispatch time is 4 hours before pickup.",
  //   path: ["dispatchTier0Time"],
  // })
  .refine((data) => !(data.dispatchTier1Timeframe === "hour" && data.dispatchTier1Time && Number(data.dispatchTier1Time) < 4), {
    message: "Minimum dispatch time is 4 hours before pickup.",
    path: ["dispatchTier1Time"],
  })
  .refine((data) => !(data.dispatchTier2Timeframe === "hour" && data.dispatchTier2Time && Number(data.dispatchTier2Time) < 4), {
    message: "Minimum dispatch time is 4 hours before pickup.",
    path: ["dispatchTier2Time"],
  })
  .refine((data) => !(data.dispatchTier3Timeframe === "hour" && data.dispatchTier3Time && Number(data.dispatchTier3Time) < 4), {
    message: "Minimum dispatch time is 4 hours before pickup.",
    path: ["dispatchTier3Time"],
  })
  .refine(
    (data) =>
      !(
        data.dispatchSpecialEventTier0Timeframe === "hour" &&
        data.dispatchSpecialEventTier0Time &&
        Number(data.dispatchSpecialEventTier0Time) < 4
      ),
    {
      message: "Minimum dispatch time is 4 hours before pickup.",
      path: ["dispatchSpecialEventTier0Time"],
    }
  )
  .refine(
    (data) =>
      !(
        data.dispatchSpecialEventTier1Timeframe === "hour" &&
        data.dispatchSpecialEventTier1Time &&
        Number(data.dispatchSpecialEventTier1Time) < 4
      ),
    {
      message: "Minimum dispatch time is 4 hours before pickup.",
      path: ["dispatchSpecialEventTier1Time"],
    }
  )
  .refine(
    (data) =>
      !(
        data.dispatchSpecialEventTier2Timeframe === "hour" &&
        data.dispatchSpecialEventTier2Time &&
        Number(data.dispatchSpecialEventTier2Time) < 4
      ),
    {
      message: "Minimum dispatch time is 4 hours before pickup.",
      path: ["dispatchSpecialEventTier2Time"],
    }
  )
  .refine(
    (data) =>
      !(
        data.dispatchSpecialEventTier3Timeframe === "hour" &&
        data.dispatchSpecialEventTier3Time &&
        Number(data.dispatchSpecialEventTier3Time) < 4
      ),
    {
      message: "Minimum dispatch time is 4 hours before pickup.",
      path: ["dispatchSpecialEventTier3Time"],
    }
  )
  .refine((data) => validateAutoDispatchSettings(data), {
    message: "Private network time before pickup should be greater than public network.",
    path: ["dispatchAutoPrivateNetworkTime"],
  })
  .refine((data) => validateAutoDispatchSettings(data), {
    message: "Public network time before pickup should be less than public network.",
    path: ["dispatchAutoPublicNetworkTime"],
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validateAutoDispatchSettings = (data: any) => {
  if (data.enabledAutomaticDispatchPublicNetwork && data.enabledAutomaticDispatchPrivateNetwork) {
    const privatePickupDelay = Number(data.dispatchAutoPrivateNetworkTime) * getTimeMultiplier(data.dispatchAutoPrivateNetworkTimeframe);
    const publicPickupDelay = Number(data.dispatchAutoPublicNetworkTime) * getTimeMultiplier(data.dispatchAutoPublicNetworkTimeframe);

    return privatePickupDelay > publicPickupDelay;
  }
  return true;
};
