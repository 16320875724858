import { differenceInWeeks } from "date-fns";
import { Link } from "react-router-dom";
import { DropdownMenu, Icon, Typography } from "@/components/atoms";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { getAssetUrl } from "@/utils";
import { MyVehicleTableData } from "./types";

export const generateMyVehicleTableData = (
  operatorId: string,
  vehicles: Vehicle[],
  onArchiveClick: (vehicle: Vehicle) => void
): MyVehicleTableData[] => {
  const data = vehicles.map(
    (vehicle) =>
      ({
        numberPlate: renderNumberPlate(vehicle, operatorId),
        make: (
          <div className="flex items-center gap-2">
            <div className="flex h-6 w-6 items-center justify-center rounded bg-neutral-gray">
              <img
                src={getAssetUrl(vehicle.model.avatar, {
                  width: 36,
                  height: 36,
                  fit: "contain",
                  background: "transparent",
                })}
                alt={vehicle.model.make}
                className="h-[18px] w-[18px]"
              />
            </div>

            {vehicle.model.make}
          </div>
        ),
        model: vehicle.model.model,
        year: vehicle.year?.toString() ?? "-",
        color: (
          <div className="flex items-center gap-2">
            {vehicle.color}
            {vehicle.color && <div className="h-3 w-3 rounded-full border border-black" style={{ backgroundColor: vehicle.color }} />}
          </div>
        ),
        capacity: (
          <div className="flex items-center gap-2">
            <Icon name="Profile" variant="Bold" size="sm" className="text-neutral-dark-gray" />
            {vehicle.capacity.pax}
          </div>
        ),
        actions: renderActions(vehicle, operatorId, onArchiveClick),
      } as MyVehicleTableData)
  );

  return data;
};

const renderNumberPlate = (vehicle: Vehicle, operatorId: string) => {
  const { isVerified, verifiedExpiry } = vehicle.metadata;
  const expiryDate = verifiedExpiry ? new Date(verifiedExpiry) : null;

  let status: "unverified" | "expiring" | "expired" | "verified" = isVerified ? "verified" : "unverified";

  if (isVerified) {
    if (expiryDate) {
      if (differenceInWeeks(expiryDate, new Date()) <= 6 && differenceInWeeks(expiryDate, new Date()) > 0) {
        status = "expiring";
      } else if (expiryDate < new Date()) {
        status = "expired";
      } else {
        status = "verified";
      }
    }
  }
  const colors = {
    verified: "text-info",
    expiring: "text-warning",
    expired: "text-neutral-dark-gray",
    unverified: "hidden",
  };

  const link = `/operator/${operatorId}/vehicles/my-vehicle/${vehicle.uuid}`;

  return (
    <Link to={link} className="flex items-center gap-2 hover:text-info">
      {vehicle.registrationNumber}
      <Icon name="Verify" variant="Bold" size="sm" className={colors[status]} />
    </Link>
  );
};

const renderActions = (vehicle: Vehicle, operatorId: string, onArchiveClick: (client: Vehicle) => void) => {
  const link = `/operator/${operatorId}/vehicles/my-vehicles/${vehicle.uuid}`;

  return (
    <>
      <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
        <Link to={link}>
          <DropdownMenu.Item>
            <Icon name="ArrowRight" className="mr-2.5 text-primary-dark" size="sm" />
            <Typography className="text-primary-dark">View Vehicle</Typography>
          </DropdownMenu.Item>
        </Link>
        {!vehicle.metadata.isArchived && (
          <DropdownMenu.Item onClick={() => onArchiveClick(vehicle)}>
            <Icon name="Trash" className="mr-2.5 text-danger" size="sm" />
            <Typography className="text-primary-dark">Archive Vehicle</Typography>
          </DropdownMenu.Item>
        )}
      </DropdownMenu>
    </>
  );
};
