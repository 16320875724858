import { isEmpty } from "ramda";
import { useState } from "react";
import { Panel, Typography, Skeleton, Icon } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { useAppSelector } from "@/redux/hooks";
import { useGetAddOnsQuery, useGetPremiumAddOnsQuery } from "@/redux/apis/addOn/addOnApi";
import { premiumAddOnsSelector } from "@/redux/slices/addOn/selectors";
import { SelectedAddOn } from "./types";
import { AddOnItem } from "./AddOnItem";
import { AddOnsModals } from "./AddOnsModals";
import { addOnsDescription } from "./fixtures";

export const AddOnsPanel = () => {
  const { isFetching: isFetchingPremiumAddOns } = useGetPremiumAddOnsQuery();
  const { isFetching: isFetchingAddOns } = useGetAddOnsQuery();
  const premiumAddOns = useAppSelector(premiumAddOnsSelector);
  // const addOns = useAppSelector(addOnsSelector);
  const [selectedAddOn, setSelectedAddOn] = useState<SelectedAddOn | undefined>(undefined);
  const isFetching = isFetchingPremiumAddOns || isFetchingAddOns;

  return (
    <>
      {isFetching ? <Skeleton /> : (
        <div className="flex flex-col gap-y-5">
          <Panel>
            <div className="flex flex-row items-center gap-x-1.5">
              <Typography variant="h3">Premium Add Ons</Typography>
              <Icon isCustom name="diamond" size="lg" className="text-warning-light" />
            </div>
            {isEmpty(premiumAddOns) ? <EmptyState title="Add Ons" description="There are currently no add ons available" /> : (
              <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-2.5 mt-4">
                {premiumAddOns.map(addOn => (
                  <AddOnItem 
                    key={addOn.identifier}
                    item={addOn}
                    title={addOn.name}
                    description={addOnsDescription[addOn.identifier]}
                    onClick={() => setSelectedAddOn({
                      identifier: addOn.identifier,
                      type: addOn.type,
                    })}
                  />
                ))}
              </div>
            )}
          </Panel>
          {/* <Panel>
            <Typography variant="h3">All Add Ons</Typography>
            {isEmpty(addOns) ? <EmptyState title="Feature Activation" description="There are currently no features available" /> : (
              <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-2.5 mt-4">
                {addOns.map(addOn => (
                  <AddOnItem 
                    key={addOn.identifier}
                    item={addOn}
                    title={addOn.name}
                    description={addOnsDescription[addOn.identifier]}
                    onClick={() => setSelectedAddOn({
                      identifier: addOn.identifier,
                      type: addOn.type,
                    })}
                  />
                ))}
              </div>
            )}
          </Panel> */}
        </div>
      )}
      {selectedAddOn && <AddOnsModals selected={selectedAddOn} onClose={() => setSelectedAddOn(undefined)} />}
    </>
  );
};