import { ComponentProps } from "react";
import { IconButton, Typography } from "@/components/atoms";
import { clsx } from "@/utils";

interface VehicleTypeButtonProps extends Omit<ComponentProps<typeof IconButton>, "type" | "iconName"> {
  type: "sedan" | "suv" | "van" | "truck" | "bus" | "minibus";
  onClick: () => void;
  selected: boolean;
}

export const VehicleTypeButton = ({ type, onClick, className, ...props }: VehicleTypeButtonProps) => {
  return (
    <IconButton
      iconName={`vehicle-type-${type}`}
      onClick={onClick}
      variant="tertiary"
      isCustomIcon
      className={clsx("group flex h-[150px] w-[150px] flex-col rounded-lg pt-5 shadow", className)}
      iconSize={80}
      {...props}
    >
      <Typography variant="action" className="-translate-y-4 capitalize group-hover:text-primary">
        {type}
      </Typography>
    </IconButton>
  );
};
