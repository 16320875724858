import { ProviderPermissions } from "@/redux/slices/permissions/types";
import { GetPermissionsRawResponse, GetPermissionsResponse } from "./types";

export const transformPermissions = (res: GetPermissionsRawResponse) => {
  const rawPermissions = res._embedded.permissions;

  const permissions = rawPermissions.map((p) => {
    const {
      provider_uuid,
      role,
      permissions: {
        can_access_provider_clients,
        can_access_provider_config,
        can_access_provider_config_pricing,
        can_access_provider_config_profile_advanced,
        can_access_provider_drivers,
        can_access_provider_private_network,
        can_access_provider_team_directory,
        can_access_provider_vehicles,
        can_access_provider_accreditation,
        can_access_provider_accreditation_action,
      },
    } = p;

    return {
      id: provider_uuid,
      role,
      permissions: {
        hasConfigAccess: can_access_provider_config,
        hasAdminConfigAccess: can_access_provider_config_profile_advanced,
        hasPricingConfigAccess: can_access_provider_config_pricing,
        hasClientsAccess: can_access_provider_clients,
        hasDriversAccess: can_access_provider_drivers,
        hasPrivateNetworkAccess: can_access_provider_private_network,
        hasTeamDirectoryAccess: can_access_provider_team_directory,
        hasVehiclesAccess: can_access_provider_vehicles,
        hasAccreditationAccess: can_access_provider_accreditation,
        hasAccreditationActionAccess: can_access_provider_accreditation_action,
      },
    } as ProviderPermissions;
  });

  const response: GetPermissionsResponse = {
    data: {
      permissions,
    },
    links: res._links,
  };

  return response;
};
