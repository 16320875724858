import { useFormContext } from "react-hook-form";
import { Typography } from "@/components/atoms";
import { VehicleTypeButton } from "../common/VehicleTypeButton";
import { useAddVehicleContext } from "./context";
import { FormData } from "./form";

export const SelectVehicleType = () => {
  const { setValue } = useFormContext<FormData>();
  const { setStep } = useAddVehicleContext();

  const selectVehicle = (type: string) => {
    setValue("type", type);
    setStep("select-make");
  };

  return (
    <div className="flex flex-col items-center">
      <Typography variant="title" className="leading-8">
        Select Vehicle Type
      </Typography>

      <div className="mt-5 grid grid-cols-3 gap-5">
        <VehicleTypeButton type="sedan" onClick={() => selectVehicle("sedan")} selected={false} />
        <VehicleTypeButton type="suv" onClick={() => selectVehicle("suv")} selected={false} className="[&>.typography]:uppercase" />
        <VehicleTypeButton type="van" onClick={() => selectVehicle("van")} selected={false} />
        <VehicleTypeButton type="minibus" onClick={() => selectVehicle("minibus")} selected={false} />
        <VehicleTypeButton type="bus" onClick={() => selectVehicle("bus")} selected={false} />
        <VehicleTypeButton type="truck" onClick={() => selectVehicle("truck")} selected={false} />
      </div>
    </div>
  );
};
