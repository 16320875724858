import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { Button, DropdownSelect, Loading, Panel, TimeFrameInput, Toggle, Typography, Modal, Skeleton } from "@/components/atoms";
import { ConfigDetail } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { getDirtyValues } from "@/helpers/formHelpers";
import { usePrompt } from "@/hooks/usePrompt";
import { useGetDispatchSettingsQuery, useUpdateDispatchSettingsMutation } from "@/redux/apis/config/admin/dispatchSettings";
import { useAppSelector } from "@/redux/hooks";
import { dispatchSettingsSelector } from "@/redux/slices/admin/selectors";
import { DispatchSettings } from "@/redux/slices/admin/types";
import { addToast } from "@/utils/addToast";
import { formSchema, modals } from "./fixtures";

export const DispatchSettingsEdit = () => {
  const { isFetching } = useGetDispatchSettingsQuery();
  const { details } = useAppSelector(dispatchSettingsSelector);
  const [updateDispatchSettings, { isLoading }] = useUpdateDispatchSettingsMutation();
  const {
    handleSubmit,
    reset,
    control,
    watch,
    register,
    formState: { isDirty, dirtyFields, isSubmitted, errors },
    trigger,
  } = useForm<DispatchSettings>({
    resolver: zodResolver(formSchema),
    defaultValues: details,
    mode: "onChange",
  });
  const {
    enabledAutomaticDispatch,
    enabledHoldBeforeDispatch,
    enabledAutomaticDispatchPrivateNetwork,
    enabledAutomaticDispatchPublicNetwork,
  } = watch();

  const [activeModal, setActiveModal] = useState<"exit" | "error" | null>(null);
  const navigate = useNavigate();
  const { ref, entry, inView } = useInView({
    rootMargin: "-180px 0px 0px 0px",
  });

  useEffect(() => {
    reset(details);
  }, [details, reset]);

  const promptWhen = isDirty && activeModal !== "exit" && !isSubmitted;
  usePrompt({ when: promptWhen, message: "Changes will not be saved. Do you want to proceed?" });

  const onSubmit = handleSubmit((data) => {
    const dirtyValues = getDirtyValues(dirtyFields, data) as DispatchSettings;
    const {
      enabledAutomaticDispatch,
      // dispatchTier0Time,
      // dispatchTier0Timeframe,
      dispatchTier1Time,
      dispatchTier1Timeframe,
      dispatchTier2Time,
      dispatchTier2Timeframe,
      dispatchTier3Time,
      dispatchTier3Timeframe,
      dispatchSpecialEventTier0Time,
      dispatchSpecialEventTier0Timeframe,
      dispatchSpecialEventTier1Time,
      dispatchSpecialEventTier1Timeframe,
      dispatchSpecialEventTier2Time,
      dispatchSpecialEventTier2Timeframe,
      dispatchSpecialEventTier3Time,
      dispatchSpecialEventTier3Timeframe,
      enabledAutomaticDispatchPrivateNetwork,
      dispatchAutoPrivateNetworkTime,
      dispatchAutoPrivateNetworkTimeframe,
      enabledAutomaticDispatchPublicNetwork,
      dispatchAutoPublicNetworkTime,
      dispatchAutoPublicNetworkTimeframe,
      dispatchAutoOwnFleetRetryCount,
      dispatchAutoPrivateNetworkRetryCount,
      enabledHoldBeforeDispatch,
      dispatchHoldBeforeDispatchTime,
      dispatchHoldBeforeDispatchTimeframe,
      dispatchAsapHoldBeforeDispatchTime,
      dispatchAsapHoldBeforeDispatchTimeframe,
    } = { ...dirtyValues };

    setActiveModal(null);
    updateDispatchSettings({
      enabled_automatic_dispatch: enabledAutomaticDispatch,
      // dispatch_tier_0_time: dispatchTier0Time,
      // dispatch_tier_0_timeframe: dispatchTier0Timeframe,
      dispatch_tier_1_time: dispatchTier1Time,
      dispatch_tier_1_timeframe: dispatchTier1Timeframe,
      dispatch_tier_2_time: dispatchTier2Time,
      dispatch_tier_2_timeframe: dispatchTier2Timeframe,
      dispatch_tier_3_time: dispatchTier3Time,
      dispatch_tier_3_timeframe: dispatchTier3Timeframe,
      dispatch_special_event_tier_0_time: dispatchSpecialEventTier0Time,
      dispatch_special_event_tier_0_timeframe: dispatchSpecialEventTier0Timeframe,
      dispatch_special_event_tier_1_time: dispatchSpecialEventTier1Time,
      dispatch_special_event_tier_1_timeframe: dispatchSpecialEventTier1Timeframe,
      dispatch_special_event_tier_2_time: dispatchSpecialEventTier2Time,
      dispatch_special_event_tier_2_timeframe: dispatchSpecialEventTier2Timeframe,
      dispatch_special_event_tier_3_time: dispatchSpecialEventTier3Time,
      dispatch_special_event_tier_3_timeframe: dispatchSpecialEventTier3Timeframe,
      enabled_automatic_dispatch_private_network: enabledAutomaticDispatchPrivateNetwork,
      dispatch_auto_private_network_time: dispatchAutoPrivateNetworkTime,
      dispatch_auto_private_network_timeframe: dispatchAutoPrivateNetworkTimeframe,
      enabled_automatic_dispatch_public_network: enabledAutomaticDispatchPublicNetwork,
      dispatch_auto_public_network_time: dispatchAutoPublicNetworkTime,
      dispatch_auto_public_network_timeframe: dispatchAutoPublicNetworkTimeframe,
      dispatch_auto_own_fleet_retry_count: dispatchAutoOwnFleetRetryCount,
      dispatch_auto_private_network_retry_count: dispatchAutoPrivateNetworkRetryCount,
      enabled_hold_before_dispatch: enabledHoldBeforeDispatch,
      dispatch_hold_before_dispatch_time: dispatchHoldBeforeDispatchTime,
      dispatch_hold_before_dispatch_timeframe: dispatchHoldBeforeDispatchTimeframe,
      dispatch_asap_hold_before_dispatch_time: dispatchAsapHoldBeforeDispatchTime,
      dispatch_asap_hold_before_dispatch_timeframe: dispatchAsapHoldBeforeDispatchTimeframe,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Updated Dispatch Settings");
        navigate("../");
      })
      .catch(() => setActiveModal("error"));
  });

  const renderModal = () => {
    if (!activeModal) return;

    return (
      <Modal
        open={true}
        title={modals[activeModal].title}
        description={modals[activeModal].description}
        onClose={() => setActiveModal(null)}
        renderButtons={renderModalButtons}
      />
    );
  };

  const renderModalButtons = () => {
    switch (activeModal) {
      case "exit":
        return (
          <>
            <Button variant="secondary" onClick={() => setActiveModal(null)}>
              Cancel
            </Button>
            <Button onClick={() => navigate("../")}>Discard Changes</Button>
          </>
        );
      default:
        return (
          <Button onClick={() => setActiveModal(null)} className="px-8">
            Back
          </Button>
        );
    }
  };

  const renderFleetDispatchRetryCounts = () => {
    if (Number(details.dispatchAutoOwnFleetRetryCount) > 0 || Number(details.dispatchAutoPrivateNetworkRetryCount) > 0) {
      return (
        <>
          <ConfigDetail
            title="Fleet Dispatch Retry Counts"
            description="When utilising automated dispatch, how many retries before moving on from our own fleet / private network? Note: this may delay speed to offload."
          />
          <ConfigDetail title="Own Fleet - Retry Count" indentPadding="pl-4" isBullet description="Number of times to attempt dispatch">
            <Controller
              control={control}
              name="dispatchAutoOwnFleetRetryCount"
              render={({ field }) => (
                <DropdownSelect
                  options={[0, 1, 2, 3, 4].map((i) => ({ name: i.toString(), value: i.toString() }))}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  hasError={!!errors.dispatchAutoOwnFleetRetryCount}
                  className="w-48"
                />
              )}
            />
          </ConfigDetail>
          <ConfigDetail
            title="Private Network - Retry Count"
            indentPadding="pl-4"
            isBullet
            description="Number of times to attempt dispatch"
          >
            <Controller
              control={control}
              name="dispatchAutoPrivateNetworkRetryCount"
              render={({ field }) => (
                <DropdownSelect
                  options={[0, 1, 2, 3, 4].map((i) => ({ name: i.toString(), value: i.toString() }))}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  hasError={!!errors.dispatchAutoPrivateNetworkRetryCount}
                  className="w-48"
                />
              )}
            />
          </ConfigDetail>
        </>
      );
    }
  };

  if (isFetching) return <Skeleton />;

  return (
    <form onSubmit={onSubmit}>
      <PageHeader.Actions>
        {entry && !inView && (
          <div className="ml-auto flex animate-enter gap-3">
            <Button variant="secondary" onClick={() => (isDirty ? setActiveModal("exit") : navigate("../"))} disabled={isLoading}>
              Cancel
            </Button>
            <Button type="submit" disabled={!isDirty || isLoading}>
              Save
            </Button>
          </div>
        )}
      </PageHeader.Actions>
      <Panel>
        <div className="relative">
          {renderModal()}
          {isLoading && <Loading />}
          <div className="flex items-center gap-4">
            <div className="flex-1">
              <Typography variant="h3" className="leading-8">
                Dispatch Settings
              </Typography>
              <Typography className="text-neutral-dark-gray">Control the time when you want to dispatch a job for the Drivers.</Typography>
            </div>
            <div className="flex gap-3" ref={ref}>
              <Button variant="secondary" onClick={() => (isDirty ? setActiveModal("exit") : navigate("../"))}>
                Cancel
              </Button>
              <Button type="submit" disabled={!isDirty}>
                Save
              </Button>
            </div>
          </div>

          <ConfigDetail
            title="Hold Jobs Before Dispatch"
            description="Define holding periods before automated dispatch starts after a job being received or edited"
          >
            <Controller
              name="enabledHoldBeforeDispatch"
              control={control}
              render={({ field }) => <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />}
            />
          </ConfigDetail>
          <ConfigDetail title="Standard Hold Time" description="Time to hold standard jobs" indentPadding="pl-2">
            <TimeFrameInput
              className="w-48"
              min="0"
              max="60"
              timeName="dispatchHoldBeforeDispatchTime"
              timeFrameName="dispatchHoldBeforeDispatchTimeframe"
              register={register}
              options={["minute", "hour"]}
              disabled={!enabledHoldBeforeDispatch}
              error={errors.dispatchHoldBeforeDispatchTime?.message}
            />
          </ConfigDetail>
          <ConfigDetail title="ASAP Hold Time" description="Time to hold ASAP jobs" indentPadding="pl-2">
            <TimeFrameInput
              className="w-48"
              min="0"
              max="60"
              timeName="dispatchAsapHoldBeforeDispatchTime"
              timeFrameName="dispatchAsapHoldBeforeDispatchTimeframe"
              register={register}
              options={["minute", "hour"]}
              disabled={!enabledHoldBeforeDispatch}
              error={errors.dispatchAsapHoldBeforeDispatchTime?.message}
            />
          </ConfigDetail>

          <ConfigDetail title="Automatic dispatch to own fleet" description="Automatic dispatch of jobs to own fleet enabled?">
            <Controller
              name="enabledAutomaticDispatch"
              control={control}
              render={({ field }) => <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />}
            />
          </ConfigDetail>
          <ConfigDetail title="Dispatch Tier Times" description="Timing of when to dispatch to each tier." indentPadding="pl-2" />
          {/* <ConfigDetail title="Preferred Tier" indentPadding="pl-4" isBullet description="Set when the system will start dispatching before pickup for any jobs  with preferred drivers available">
            <TimeFrameInput
              className="w-48"
              min="0"
              max="999"
              timeName="dispatchTier0Time"
              timeFrameName="dispatchTier0Timeframe"
              register={register}
              options={["hour", "day"]}
              disabled={!enabledAutomaticDispatch}
              error={errors.dispatchTier0Time?.message}
            />
          </ConfigDetail> */}
          <ConfigDetail title="Tier 1" indentPadding="pl-4" isBullet description="Set when the system will start dispatching before pickup">
            <TimeFrameInput
              className="w-48"
              min="0"
              max="999"
              timeName="dispatchTier1Time"
              timeFrameName="dispatchTier1Timeframe"
              register={register}
              options={["hour", "day"]}
              disabled={!enabledAutomaticDispatch}
              error={errors.dispatchTier1Time?.message}
            />
          </ConfigDetail>
          <ConfigDetail title="Tier 2" indentPadding="pl-4" isBullet description="Set when the system will start dispatching before pickup">
            <div className="flex flex-row items-center gap-3">
              <TimeFrameInput
                className="w-48"
                min="0"
                max="999"
                timeName="dispatchTier2Time"
                timeFrameName="dispatchTier2Timeframe"
                register={register}
                options={["hour", "day"]}
                disabled={!enabledAutomaticDispatch}
                error={errors.dispatchTier2Time?.message}
              />
            </div>
          </ConfigDetail>
          <ConfigDetail title="Tier 3" indentPadding="pl-4" isBullet description="Set when the system will start dispatching before pickup">
            <TimeFrameInput
              className="w-48"
              min="0"
              max="999"
              timeName="dispatchTier3Time"
              timeFrameName="dispatchTier3Timeframe"
              register={register}
              options={["hour", "day"]}
              disabled={!enabledAutomaticDispatch}
              error={errors.dispatchTier3Time?.message}
            />
          </ConfigDetail>
          <ConfigDetail title="Preferred Special Event Tier" indentPadding="pl-4" isBullet description="Set when the system will start dispatching before pickup for any jobs  with preferred drivers available">
            <TimeFrameInput
              className="w-48"
              min="0"
              max="999"
              timeName="dispatchSpecialEventTier0Time"
              timeFrameName="dispatchSpecialEventTier0Timeframe"
              register={register}
              options={["hour", "day"]}
              disabled={!enabledAutomaticDispatch}
              error={errors.dispatchSpecialEventTier0Time?.message}
            />
          </ConfigDetail>
          <ConfigDetail title="Special Event Tier 1" indentPadding="pl-4" isBullet description="Set when the system will start dispatching before pickup">
            <TimeFrameInput
              className="w-48"
              min="0"
              max="999"
              timeName="dispatchSpecialEventTier1Time"
              timeFrameName="dispatchSpecialEventTier1Timeframe"
              register={register}
              options={["hour", "day"]}
              disabled={!enabledAutomaticDispatch}
              error={errors.dispatchSpecialEventTier1Time?.message}
            />
          </ConfigDetail>
          <ConfigDetail title="Special Event Tier 2" indentPadding="pl-4" isBullet description="Set when the system will start dispatching before pickup">
            <TimeFrameInput
              className="w-48"
              min="0"
              max="999"
              timeName="dispatchSpecialEventTier2Time"
              timeFrameName="dispatchSpecialEventTier2Timeframe"
              register={register}
              options={["hour", "day"]}
              disabled={!enabledAutomaticDispatch}
              error={errors.dispatchSpecialEventTier2Time?.message}
            />
          </ConfigDetail>
          <ConfigDetail title="Special Event Tier 3" indentPadding="pl-4" isBullet description="Set when the system will start dispatching before pickup">
            <TimeFrameInput
              className="w-48"
              min="0"
              max="999"
              timeName="dispatchSpecialEventTier3Time"
              timeFrameName="dispatchSpecialEventTier3Timeframe"
              register={register}
              options={["hour", "day"]}
              disabled={!enabledAutomaticDispatch}
              error={errors.dispatchSpecialEventTier3Time?.message}
            />
          </ConfigDetail>

          <ConfigDetail title="Auto Dispatch to Private Network" description="Set when the system will start dispatching before pickup">
            <Controller
              name="enabledAutomaticDispatchPrivateNetwork"
              control={control}
              render={({ field }) => (
                <Toggle
                  checked={field.value}
                  onChange={(checked) => {
                    field.onChange(checked);
                    trigger();
                  }}
                />
              )}
            />
          </ConfigDetail>
          <ConfigDetail title="Set when the system will start dispatching before pickup" indentPadding="pl-4" isBullet>
            <TimeFrameInput
              className="w-48"
              min="0"
              max="60"
              timeName="dispatchAutoPrivateNetworkTime"
              timeFrameName="dispatchAutoPrivateNetworkTimeframe"
              register={register}
              options={["hour", "minute", "day"]}
              disabled={!enabledAutomaticDispatchPrivateNetwork}
              error={errors.dispatchAutoPrivateNetworkTime?.message}
            />
          </ConfigDetail>

          <ConfigDetail title="Auto Dispatch to Public Network" description="Set when the system will start dispatching before pickup">
            <Controller
              name="enabledAutomaticDispatchPublicNetwork"
              control={control}
              render={({ field }) => (
                <Toggle
                  checked={field.value}
                  onChange={(checked) => {
                    field.onChange(checked);
                    trigger();
                  }}
                />
              )}
            />
          </ConfigDetail>
          <ConfigDetail title="Set when the system will start dispatching before pickup" indentPadding="pl-4" isBullet>
            <TimeFrameInput
              className="w-48"
              min="0"
              max="60"
              timeName="dispatchAutoPublicNetworkTime"
              timeFrameName="dispatchAutoPublicNetworkTimeframe"
              register={register}
              options={["hour", "minute", "day"]}
              disabled={!enabledAutomaticDispatchPublicNetwork}
              error={errors.dispatchAutoPublicNetworkTime?.message}
            />
          </ConfigDetail>

          {renderFleetDispatchRetryCounts()}
        </div>
      </Panel>
    </form>
  );
};
