import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { operatorApi } from "@/redux/apis/operator/operatorApi";
import { Operator, OperatorState } from "./types";

const initialState: OperatorState = {
  operators: [],
  activeOperator: undefined,
};

export const operatorSlice = createSlice({
  name: "operator",
  initialState,
  reducers: {
    setActiveOperator: (state, action: PayloadAction<Operator>) => {
      state.activeOperator = action.payload;
    },
    switchOperator: (state, action: PayloadAction<Operator>) => {
      state.activeOperator = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(operatorApi.endpoints.getOperators.matchFulfilled, (state, { payload }) => {
      state.operators = payload.data.operators;
    });
  },
});

export default operatorSlice.reducer;
export const { setActiveOperator, switchOperator } = operatorSlice.actions;
