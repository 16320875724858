import { Alert, Icon, Typography } from "@/components/atoms";
import { AddOnItem } from "@/redux/slices/addOn/types";
import { formatCurrency } from "@/utils";
import { format, isAfter, isEqual } from "date-fns";
import { addOnsIcon } from "./fixtures";

export const renderAddOnCost = (item?: AddOnItem) => {
  if (item) {
    const { cost } = item;
    let costDisplay = "";

    if (cost === 0) costDisplay = "Free Feature"; 
    else costDisplay = item.costType === "fixed" ? `${formatCurrency(item.cost, 2, item.currency)} per booking` : `${item.cost}% per booking`;

    return (
      <div className="flex flex-row items-center text-primary gap-x-1.5">
        <Icon name="DollarCircle" variant="Bold" />
        <Typography variant="action">{costDisplay}</Typography>
      </div>
    );
  }
};

export const renderAddOnIcon = (id?: string) => {
  if (id) {
    const icon = addOnsIcon[id];
    if (icon) return <Icon name={icon} size="xl" variant="Bold" className="text-white" />;
  }

  return <div className="w-6 h-6" />;
};

export const renderPreFreeTrialInfo = (item?: AddOnItem) => {
  if (item && item.freeTrialPeriod > 0 && !item.freeTrialExpiry) return <Alert type="info" message="Free trial available for 2 weeks after activation" className="mt-3" />;
};

export const renderPostFreeTrialInfo = (dateFormat: string, item?: AddOnItem) => {
  if (item) {
    const expiryDate = new Date(item.freeTrialExpiry);
    const currentDate = new Date();
    const isFreeTrialValid = item.freeTrialExpiry && (isEqual(expiryDate, currentDate) || isAfter(expiryDate, currentDate));

    if (isFreeTrialValid) return <Alert type="info" message={`Free through ${format(expiryDate, dateFormat)}`} className="mt-4" />;
  }
};