import { AddOnsPanel } from "@/components/organisms";

export const AddOns = () => {
  return (
    <div className="mb-12 flex flex-1 flex-col">
      {/* <PageHeader skipCrumbs={1} title="Add Ons Center" /> */}
      <AddOnsPanel />
    </div>
  );
};
