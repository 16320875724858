import { Controller, useFormContext } from "react-hook-form";
import { DateInput, FileInput, Typography } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { EditUnverifiedVehicleFormData } from "../form";

export const VehicleDocuments = ({ vehicle }: { vehicle: Vehicle }) => {
  const { dateFormat } = useProfile();
  const {
    control,
    formState: { errors },
  } = useFormContext<EditUnverifiedVehicleFormData>();

  const isVerified = vehicle.metadata.isVerified;

  return (
    <>
      <label htmlFor="documents.insuranceExpiry" className="flex flex-col [&_.react-datepicker-wrapper]:w-full">
        <Typography className="leading-[2rem]">Insurance Expiry</Typography>
        <Controller
          name="documents.insuranceExpiry"
          control={control}
          render={({ field }) => (
            <DateInput
              className="h-[46px] w-full"
              onChange={(date) => field.onChange(date)}
              selected={field.value}
              placeholderText="-- / -- / ----"
              hasError={!!errors.registration?.expiry}
              dateFormat={dateFormat}
            />
          )}
        />
      </label>
      <label htmlFor="documents.insuranceExpiry" className="flex flex-col">
        <Typography className="leading-[2rem]">Upload your vehicle insurance documents</Typography>
        <Controller
          control={control}
          name="documents.insuranceDocuments"
          render={({ field }) => (
            <FileInput
              id="documents.insuranceDocuments"
              className="flex h-[46px] rounded-lg border border-neutral-mid-gray p-2"
              multiple
              onChange={(e) => field.onChange([...Array.from(e.target.files ?? [])])}
            />
          )}
        />
      </label>
      {!isVerified && (
        <label htmlFor="documents.registrationPdf" className="col flex flex-col">
          <Typography className="leading-[2rem]">Upload your vehicle registration PDF</Typography>
          <Controller
            control={control}
            name="documents.registrationPdf"
            render={({ field }) => (
              <FileInput
                id="documents.registrationPdf"
                className="flex h-[46px] rounded-lg border border-neutral-mid-gray p-2"
                multiple
                onChange={(e) => field.onChange([...Array.from(e.target.files ?? [])])}
              />
            )}
          />
        </label>
      )}
      <label htmlFor="documents.accreditationDocuments" className="col flex flex-col">
        <Typography className="leading-[2rem]">Upload your vehicle accreditation documents</Typography>
        <Controller
          control={control}
          name="documents.accreditationDocuments"
          render={({ field }) => (
            <FileInput
              id="documents.accreditationDocuments"
              className="flex h-[46px] rounded-lg border border-neutral-mid-gray p-2"
              multiple
              onChange={(e) => field.onChange([...Array.from(e.target.files ?? [])])}
            />
          )}
        />
      </label>
    </>
  );
};
