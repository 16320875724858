import { zodResolver } from "@hookform/resolvers/zod";
import { createContext, PropsWithChildren, useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { schema } from "./form";

type Step =
  | "select-type"
  | "select-make"
  | "select-model"
  | "model-not-showing"
  | "check-eligibility"
  | "select-verification"
  | "auto-verification"
  | "manual-verification";

export interface IAddVehicleContext {
  step: Step;
  setStep: (step: Step) => void;
}
export const AddVehicleContext = createContext<IAddVehicleContext>({
  step: "select-type",
  setStep: () => ({}),
});

export const AddVehicleProvider = ({ children }: PropsWithChildren) => {
  const [step, setStep] = useState<Step>("select-type");

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  return (
    <AddVehicleContext.Provider value={{ step, setStep }}>
      <FormProvider {...form}>{children}</FormProvider>
    </AddVehicleContext.Provider>
  );
};

export const useAddVehicleContext = () => {
  const context = useContext(AddVehicleContext);
  if (!context) {
    throw new Error("useAddVehicleContext must be used within an AddVehicleProvider");
  }
  return context;
};
