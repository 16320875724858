import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { Icon, RadioInput, Tooltip, Typography } from "@/components/atoms";
import { BookingFilters } from "@/redux/slices/booking/types";
import { offloadOptions } from "../../../fixtures";
import { useBookingFiltersV2 } from "../../../hooks/useBookingFiltersV2";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";

const schema = z.object({
  offload: z.string(),
});

type FormData = z.infer<typeof schema>;

const getValue = (offload: BookingFilters["offload"]) => {
  if (!offload) return offload;
  return offloadOptions.find((s) => s.id === offload)?.name;
};

export const OffloadFilter = () => {
  const {
    filters: { offload },
    setFilter,
  } = useBookingFiltersV2();

  const value = useMemo(() => getValue(offload as BookingFilters["offload"]), [offload]);

  const onClose = () => setFilter("offload", undefined);

  return (
    <FilterPopover name="offload" value={value} label="Offload" onClose={onClose}>
      <OffloadFilterForm />
    </FilterPopover>
  );
};

interface OffloadFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const OffloadFilterDrawer = ({ open, onOpenChange }: OffloadFilterDrawerProps) => {
  const {
    filters: { offload },
    setFilter,
  } = useBookingFiltersV2();

  const value = useMemo(() => getValue(offload as BookingFilters["offload"]), [offload]);

  const onClose = () => setFilter("offload", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="Offload" onClose={onClose}>
      <OffloadFilterForm />
    </FilterDrawer>
  );
};

const OffloadFilterForm = () => {
  const { setFilter, filters } = useBookingFiltersV2();

  const { control } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { offload: filters.offload ? filters.offload : undefined },
  });

  return (
    <div className="contents">
      <Typography className="mb-4">
        Offloaded Jobs{" "}
        <Tooltip content="Both Public and Private Network">
          <Icon name="InfoCircle" variant="Bold" size="sm" className="-mt-1 ml-1 inline rotate-180 text-neutral-dark-gray" />
        </Tooltip>
      </Typography>

      <div className="flex flex-col gap-2">
        <Controller
          name="offload"
          control={control}
          render={({ field }) => (
            <>
              {offloadOptions.map((i) => (
                <RadioInput
                  key={i.name}
                  label={i.name}
                  value={i.id}
                  onChange={({ target }) => {
                    field.onChange(target.value);
                    setFilter("offload", target.value);
                  }}
                  checked={field.value === i.id}
                />
              ))}
            </>
          )}
        />
      </div>
    </div>
  );
};
