import { useState } from "react";
import { ErrorPanel, Skeleton } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useGetVehiclesQuery } from "@/redux/apis/vehicle/vehicleApi";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { MyVehiclesTable } from "./MyVehiclesTable";
import { ArchiveVehicleModal } from "./modals/ArchiveVehicleModal";

export const MyVehiclesPanel = () => {
  const { isLoading, data: response, error } = useGetVehiclesQuery();
  const [pendingArchive, setPendingArchive] = useState<Vehicle | undefined>();

  if (error) return <ErrorPanel messages={getErrorMessages(error)} />;
  if (isLoading) return <Skeleton />;

  if (response && response.vehicles.length > 1)
    return (
      <>
        <MyVehiclesTable items={response.vehicles ?? []} setPendingArchive={setPendingArchive} />
        <MyVehiclesTable.Pagination page={response.page} />
        <ArchiveVehicleModal pendingArchive={pendingArchive} setPendingArchive={setPendingArchive} />
      </>
    );

  return <EmptyState title="Vehicles" description="There are no vehicles matching your filter criteria at the moment." />;
};
