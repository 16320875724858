import { createSlice } from "@reduxjs/toolkit";
import { AddOnState } from "./types";
import { addOnApi } from "@/redux/apis";

const initialState: AddOnState = {
  premiumAddOns: [],
  addOns: [],
};

export const addOnSlice = createSlice({
  name: "addOn",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(addOnApi.endpoints.getPremiumAddOns.matchFulfilled, (state, { payload }) => {
      const { addOns } = payload.data;
      state.premiumAddOns = addOns;
    }),
    builder.addMatcher(addOnApi.endpoints.getAddOns.matchFulfilled, (state, { payload }) => {
      const { addOns } = payload.data;
      state.addOns = addOns;
    });
  },
});

export default addOnSlice.reducer;
