import { useWindowSize } from "@react-hookz/web";
import { flatten } from "ramda";
import { useCallback, useMemo, useState } from "react";
import { Button, Typography, Popover, Drawer } from "@/components/atoms";
import { useGetBookingFormOptionsQuery } from "@/redux/apis/booking/bookingApi";
import { BookingFilterKey, useBookingFiltersV2 } from "../../hooks/useBookingFiltersV2";
import { FilterNavButton } from "./common/FilterNavButton";
import { getFilterDrawer, getFilterPopover } from "./helpers";

export interface BookingsFilterPanelProps {
  open: boolean;
  onClose: () => void;
}

const BookingFiltersButton = () => {
  useGetBookingFormOptionsQuery();
  const { filters, setFilter } = useBookingFiltersV2();
  const numActiveFilters = useMemo(() => flatten(Object.values(filters).filter(Boolean)).length, [filters]);

  const toggleActiveFilters = useCallback(
    (key: BookingFilterKey) => {
      if (filters[key] === undefined) {
        setFilter(key, null);

        // to avoid adding more state of the popover state, we will just click the button to open the popover
        setTimeout(
          () => document.querySelectorAll(`[data-filter-name="${key}"]`).forEach((button) => (button as HTMLElement).click()),
          100
        );
      } else setFilter(key, undefined);
    },
    [filters, setFilter]
  );

  return (
    <Popover placement="bottom-end">
      <Button variant="tertiary" startIcon="Sort" className="h-[34px] sm:p-3 max-xl:[&>span]:hidden" size="sm">
        Filters {numActiveFilters > 0 && `(${numActiveFilters})`}
      </Button>
      <div className="flex w-[225px] flex-col-reverse rounded-lg bg-white shadow-dropdown">
        <nav className="flex flex-col">
          <div className="mt-3 flex list-none flex-col [&>button>.typography]:-mr-1">
            <FilterNavButton onClick={() => toggleActiveFilters("assignedVehicle")} isActive={filters.assignedVehicle !== undefined}>
              Assigned Vehicle
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("client")} isActive={filters.client !== undefined}>
              Client
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("company")} isActive={filters.company !== undefined}>
              Company
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("driver")} isActive={filters.driver !== undefined}>
              Driver
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("offload")} isActive={filters.offload !== undefined}>
              Offloaded Jobs
            </FilterNavButton>
            <FilterNavButton
              onClick={() => toggleActiveFilters("onlyShowUnvalidated")}
              isActive={filters.onlyShowUnvalidated !== undefined}
            >
              Only Unvalidated Jobs
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("passenger")} isActive={filters.passenger !== undefined}>
              Passenger
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("state")} isActive={filters.state !== undefined}>
              State
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("status")} isActive={filters.status !== undefined}>
              Status
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("transferType")} isActive={filters.transferType !== undefined}>
              Transfer Type
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("vehicleType")} isActive={filters.vehicleType !== undefined}>
              Vehicle Type
            </FilterNavButton>
          </div>
        </nav>
      </div>
    </Popover>
  );
};

const BookingActiveFilters = () => {
  useGetBookingFormOptionsQuery();

  const { sortedFilters, filters, clearFilters, resetFilters } = useBookingFiltersV2();
  const { width } = useWindowSize();

  const renderFilters = useCallback(
    () =>
      sortedFilters.map(([key, value]) => {
        if (value === undefined) return null;
        return width > 768 ? getFilterPopover(key) : (getFilterDrawer(key, true) as React.ReactElement);
      }),

    [sortedFilters, width]
  );

  const numActiveFilters = flatten(Object.values(filters).filter((f) => f !== undefined)).length;

  if (numActiveFilters === 0) return null;

  return (
    <div className="flex  max-sm:flex-col sm:gap-2 ">
      <div className="thin-scrollbar flex flex-1 items-center gap-2 overflow-x-auto pb-2">
        <Typography variant="action" className="shrink-0 text-neutral-dark-gray max-sm:hidden">
          Selected Filters:
        </Typography>
        <div className="flex flex-1 gap-2">{renderFilters()}</div>
      </div>
      <div className="shink-0 flex self-start max-sm:self-end">
        <Button onClick={clearFilters} variant="tertiary" size="xs" className="text-neutral-dark-gray">
          Clear
        </Button>
        <Button onClick={resetFilters} variant="tertiary" size="xs" className="text-neutral-dark-gray">
          Reset
        </Button>
      </div>
    </div>
  );
};

const BookingFiltersMenuDrawer = ({ onClose }: { onClose: () => void }) => {
  const { filters, setFilter } = useBookingFiltersV2();
  const [open, setOpen] = useState(false);
  const [openChild, setOpenChild] = useState<BookingFilterKey>();

  const toggleActiveFilters = useCallback(
    (key: BookingFilterKey) => {
      if (filters[key] !== null) {
        setFilter(key, null);
        setOpenChild(key);
      } else setFilter(key, undefined);
    },
    [filters, setFilter]
  );

  const DrawerComponent = openChild ? getFilterDrawer(openChild) : null;

  return (
    <Drawer
      asNested
      title="Select Filter"
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        if (!open) {
          setOpenChild(undefined);
          setOpen(false);
          onClose();
        }
      }}
    >
      <Button variant="tertiary" startIcon="Sort" className="h-[34px] w-full justify-start sm:p-3 " size="sm">
        Filters
      </Button>
      <>
        <nav className="flex flex-col">
          <div className="mt-3 flex list-none flex-col [&>button>.typography]:-mr-1">
            <FilterNavButton onClick={() => toggleActiveFilters("assignedVehicle")} isActive={filters.assignedVehicle !== undefined}>
              Assigned Vehicle
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("client")} isActive={filters.client !== undefined}>
              Client
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("company")} isActive={filters.company !== undefined}>
              Company
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("driver")} isActive={filters.driver !== undefined}>
              Driver
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("offload")} isActive={filters.offload !== undefined}>
              Offloaded Jobs
            </FilterNavButton>
            <FilterNavButton
              onClick={() => toggleActiveFilters("onlyShowUnvalidated")}
              isActive={filters.onlyShowUnvalidated !== undefined}
            >
              Only Unvalidated Jobs
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("passenger")} isActive={filters.passenger !== undefined}>
              Passenger
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("state")} isActive={filters.state !== undefined}>
              State
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("status")} isActive={filters.status !== undefined}>
              Status
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("transferType")} isActive={filters.transferType !== undefined}>
              Transfer Type
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("vehicleType")} isActive={filters.vehicleType !== undefined}>
              Vehicle Type
            </FilterNavButton>
          </div>
        </nav>
        {typeof DrawerComponent === "function" ? (
          <DrawerComponent
            open={!!openChild}
            onOpenChange={(open) => {
              setOpenChild(open ? openChild : undefined);
              if (!open) setOpen(false);
            }}
          />
        ) : null}
      </>
    </Drawer>
  );
};

export const BookingFiltersV2 = () => {
  throw new Error("Use Button or ActiveFilters instead of BookingFiltersV2");
};

BookingFiltersV2.Button = BookingFiltersButton;
BookingFiltersV2.ActiveFilters = BookingActiveFilters;
BookingFiltersV2.DrawerButton = BookingFiltersMenuDrawer;
