import { Alert, Button, Icon, Loading, Modal, Typography } from "@/components/atoms";
import { ReactComponent as FlightTrackingPreview } from "@/assets/images/config/flight-tracking-preview.svg";
import { useAppSelector } from "@/redux/hooks";
import { addToast } from "@/utils";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useState } from "react";
import { useToggleAddOnMutation } from "@/redux/apis/addOn/addOnApi";
import AddOnConfetti from "@/assets/images/config/add-on-confetti.svg";
import { premiumAddOnItemSelector } from "@/redux/slices/addOn/selectors";
import { renderAddOnCost, renderAddOnIcon, renderPostFreeTrialInfo, renderPreFreeTrialInfo } from "../helpers";
import { useProfile } from "@/hooks";
import { format, isAfter, isEqual } from "date-fns";

interface FlightTrackingModalProps {
  open: boolean;
  onClose: () => void;
  isAdmin: boolean;
}

export const FlightTrackingModal = ({ open, onClose, isAdmin }: FlightTrackingModalProps) => {
  const { dateFormat } = useProfile();
  const flightTracking = useAppSelector(premiumAddOnItemSelector("flight-tracking"));
  const [toggleAddOn, { isLoading }] = useToggleAddOnMutation();
  const [removeModal, setRemoveModal] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);

  const handleClose = () => {
    setAddSuccess(false);
    onClose();
  };

  const handleAdd = () => {
    toggleAddOn({ id: "flight-tracking", enabled: true })
      .unwrap()
      .then(() => setAddSuccess(true))
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  const handleRemove = () => {
    toggleAddOn({ id: "flight-tracking", enabled: false })
      .unwrap()
      .then(() => {
        addToast("info", "Flight Tracking has been removed.");
        setRemoveModal(false);
        handleClose();
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  const renderHeader = () => (
    <div className="flex flex-row items-center">
      <Typography variant="title" className="flex-1">
        Add-ons
      </Typography>
      <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={handleClose} />
    </div>
  );

  const renderSuccessAlert = () => {
    if (addSuccess) {
      return (
        <div className="flex flex-row items-center py-2.5 px-6 rounded-lg bg-success-light">
          <div className="flex flex-1 flex-row items-center gap-x-1.5 text-success">
            <Icon name="TickCircle" variant="Bold" />
            <Typography variant="action">Flight Tracking has been successfully added</Typography>
          </div>
          <img src={AddOnConfetti} />
        </div>
      );
    }
  };

  const renderButton = () => {
    if (isAdmin) {
      if (flightTracking?.enabled) return <Button variant="secondary" size="lg" startIcon="MinusCircle" iconVariant="Bold" iconClassName="text-danger" className="w-full mt-6" onClick={() => setRemoveModal(true)}>Remove Flight Tracking</Button>;
      else return (
        <div className="relative mt-6">
          {isLoading && <Loading />}
          <Button variant="primary" size="lg" className="w-full" onClick={handleAdd}>Add Flight Tracking</Button>
        </div>
      );
    } else return <Alert type="info" message="Request access from your admin for real-time flight tracking of bookings. Get instant updates and stay informed about your flights." className="w-full mt-6" />;
  };

  const renderStatus = () => {
    if (flightTracking) {
      const { freeTrialExpiry, enabled } = flightTracking;
      const expiryDate = new Date(freeTrialExpiry);
      const currentDate = new Date();
      const isFreeTrialValid = freeTrialExpiry && (isEqual(expiryDate, currentDate) || isAfter(expiryDate, currentDate));

      if (enabled) {
        return (
          <div className="absolute flex flex-row items-center justify-center rounded-t-lg bg-success text-white w-full p-2.5 top-0 left-0">
            <Typography variant="action" className="flex-1">Status: Active</Typography>
            {isFreeTrialValid && <Typography variant="action" className="flex">{`(Free until ${format(expiryDate, dateFormat)})`}</Typography>}
          </div>
        );
      }
    }
  };
  
  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="w-[85vw] md:w-[550px] lg:w-[550px]"
    >
      {renderHeader()}
      <div className="mt-3">
        {renderSuccessAlert()}
        {renderPreFreeTrialInfo(flightTracking)}
        <div className="flex flex-col md:flex-row md:items-center bg-neutral-surface-gray rounded-lg gap-y-2.5 py-2.5 pl-2.5 pr-5 mt-3">
          <div className="flex flex-1 items-center flex-row gap-x-4">
            <div className="flex items-center justify-center bg-primary-dark rounded-md p-2.5">
              {renderAddOnIcon(flightTracking?.identifier)}
            </div>
            <Typography variant="h3">Flight Tracking</Typography>
          </div>
          <div className="flex flex-col items-end">
            {renderAddOnCost(flightTracking)}
            <Typography variant="action" className="text-neutral-dark-gray -mt-1.5">with flights</Typography>
          </div>
        </div> 
        <div className="relative flex">
          {addSuccess && (
            <div className="absolute flex items-center justify-center h-full w-full">
              <Icon className="text-success" name="TickCircle" variant="Bold" size={100} />
            </div>
          )}
          <div className="relative bg-neutral-gray rounded-lg sm:pt-14 pt-12 my-4 w-full">
            {renderStatus()}
            <FlightTrackingPreview className="sm:w-full sm:h-[238px] sm:-mb-5 w-full h-[138px] -mb-3" />
          </div>
        </div>
        <Typography variant="paragraph">
          Enhance your pickup service with our live flight tracking feature. This tool activates 24 hours before a client&apos;s arrival and refreshes every 15 minutes, providing real-time updates directly in the booking modal. Stay informed and adjust pickup times efficiently to ensure prompt service.
        </Typography>
        <div className="mt-4">
          <div className="flex flex-row items-center gap-x-[5px]">
            <Icon isCustom name="check" size="lg" className="text-success" />
            <Typography variant="paragraph">Live Flight Updates</Typography>
          </div>
          <div className="flex flex-row items-center gap-x-[5px]">
            <Icon isCustom name="check" size="lg" className="text-success" />
            <Typography variant="paragraph">Operational Efficiency</Typography>
          </div>
          <div className="flex flex-row items-center gap-x-[5px]">
            <Icon isCustom name="check" size="lg" className="text-success" />
            <Typography variant="paragraph">Enhanced Client Service</Typography>
          </div>
        </div>
        {renderPostFreeTrialInfo(dateFormat, flightTracking)}
        {renderButton()}
      </div>
      <Modal 
        open={removeModal} 
        onClose={() => isLoading ? null : setRemoveModal(false)} 
        className="w-[80vw] md:w-[514px] lg:w-[514px]"
      >
        <div className="relative flex flex-col">
          {isLoading && <Loading />}
          <Typography variant="title">Remove Flight Tracking</Typography>
          <Typography variant="paragraph" className="mt-3">If you remove flight tracking, you will no longer have access to essential real-time flight information.</Typography>
          <div className="flex flex-row items-center justify-end gap-x-4 mt-[22px]">
            <Button variant="secondary" size="md" onClick={() => setRemoveModal(false)}>Cancel</Button>
            <Button variant="primary" size="md" className="bg-danger hover:bg-danger-dark" onClick={handleRemove}>Remove</Button>
          </div>
        </div>
      </Modal>
    </Modal>
  );
};
