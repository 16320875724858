import { PageHeader } from "../../PageHeader/PageHeader";

export const ViewVehiclePanel = () => {
  return (
    <div>
      <PageHeader.Actions />
      <div className="flex flex-col items-center p-5">view</div>
    </div>
  );
};
