import { Alert, Button, Icon, Loading, Modal, Typography } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { addToast } from "@/utils";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useState } from "react";
import { useToggleAddOnMutation } from "@/redux/apis/addOn/addOnApi";
import AddOnConfetti from "@/assets/images/config/add-on-confetti.svg";
import { addOnItemSelector } from "@/redux/slices/addOn/selectors";
import { renderAddOnCost, renderAddOnIcon } from "../helpers";

interface AddOnModalProps {
  identifier: string;
  open: boolean;
  onClose: () => void;
  isAdmin: boolean;
}

export const AddOnModal = ({ identifier, open, onClose, isAdmin }: AddOnModalProps) => {
  const item = useAppSelector(addOnItemSelector(identifier));
  const [toggleAddOn, { isLoading }] = useToggleAddOnMutation();
  const [removeModal, setRemoveModal] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const name = item ? item.name : "";

  const handleClose = () => {
    setAddSuccess(false);
    onClose();
  };

  const handleAdd = () => {
    toggleAddOn({ id: identifier, enabled: true })
      .unwrap()
      .then(() => setAddSuccess(true))
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  const handleRemove = () => {
    toggleAddOn({ id: identifier, enabled: false })
      .unwrap()
      .then(() => {
        addToast("info", `${name} has been removed.`);
        setRemoveModal(false);
        handleClose();
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  const renderHeader = () => (
    <div className="flex flex-row items-center">
      <Typography variant="title" className="flex-1">
        Add-ons
      </Typography>
      <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={handleClose} />
    </div>
  );

  const renderSuccessAlert = () => {
    if (addSuccess) {
      return (
        <div className="flex flex-row items-center py-2.5 px-6 rounded-lg bg-success-light">
          <div className="flex flex-1 flex-row items-center gap-x-1.5 text-success">
            <Icon name="TickCircle" variant="Bold" />
            <Typography variant="action">{`${name} has been successfully added`}</Typography>
          </div>
          <img src={AddOnConfetti} />
        </div>
      );
    }
  };

  const renderButton = () => {
    if (item) {
      if (isAdmin) {
        if (item.enabled) return <Button variant="secondary" size="lg" startIcon="MinusCircle" iconVariant="Bold" iconClassName="text-danger" className="w-full mt-8" onClick={() => setRemoveModal(true)}>Remove {item.name}</Button>;
        else return (
          <div className="relative mt-8">
            {isLoading && <Loading />}
            <Button variant="primary" size="lg" className="w-full" onClick={handleAdd}>Add {item.name}</Button>
          </div>
        );
      } else return <Alert type="info" message={`Request access from your admin for ${name.toLowerCase()}.`} className="w-full mt-4" />;
    }
  };
  
  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="w-[85vw] md:w-[550px] lg:w-[550px]"
    >
      {renderHeader()}
      <div className="mt-3">
        {renderSuccessAlert()}
        <div className="flex flex-col md:flex-row md:items-center bg-neutral-surface-gray rounded-lg gap-y-2.5 py-2.5 pl-2.5 pr-5 mt-3">
          <div className="flex flex-1 items-center flex-row gap-x-4">
            <div className="flex items-center justify-center bg-neutral-gray rounded-md p-2.5">
              {renderAddOnIcon(identifier)}
            </div>
            <Typography variant="h3">{name}</Typography>
          </div>
          {renderAddOnCost(item)}
        </div> 
        {renderButton()}
      </div>
      <Modal 
        open={removeModal} 
        onClose={() => isLoading ? null : setRemoveModal(false)} 
        className="w-[80vw] md:w-[514px] lg:w-[514px]"
      >
        <div className="relative flex flex-col">
          {isLoading && <Loading />}
          <Typography variant="title">Remove {name}</Typography>
          <Typography variant="paragraph" className="mt-3">{`If you remove ${name.toLowerCase()}, the feature will be disabled.`}</Typography>
          <div className="flex flex-row items-center justify-end gap-x-4 mt-[22px]">
            <Button variant="secondary" size="md" onClick={() => setRemoveModal(false)}>Cancel</Button>
            <Button variant="primary" size="md" className="bg-danger hover:bg-danger-dark" onClick={handleRemove}>Remove</Button>
          </div>
        </div>
      </Modal>
    </Modal>
  );
};
