import { matchPath, useLocation } from "react-router-dom";
import { Icon, LinkButton } from "@/components/atoms";
import { Tabs } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { useOperator } from "@/hooks";
import { RequireAccess } from "@/routes/RequireAccess/RequireAccess";
import { AffiliateVehicles } from "./AffiliateVehicles";
import { ArchivedVehicles } from "./ArchivedVehicles";
import { MyVehicles } from "./MyVehicles";

export const Vehicles = () => {
  const { pathname } = useLocation();
  const { id: operatorId } = useOperator();

  const pageTabs = [
    {
      name: "My Vehicles",
      to: "my-vehicles",
    },
    {
      name: "Affiliate Vehicles",
      to: "affiliate-vehicles",
    },
    {
      name: "Archived Vehicles",
      to: "archived-vehicles",
    },
    {
      name: "Vehicle Category",
      to: `/operator/${operatorId}/config/pricing/vehicle-categories`,
      icon: <Icon name="ExportSquare" size="md" className="ml-2" />,
    },
  ];

  const isParentPage = Boolean(
    matchPath({ path: "/vehicles/:slug" }, pathname) || matchPath({ path: "operator/:operatorId/vehicles/:slug" }, pathname)
  );

  return (
    <div className="flex flex-1 flex-col pb-12">
      <PageHeader title="Vehicles" skipCrumbs={1} />
      {isParentPage && (
        <Tabs className="no-scrollbar mb-5 border-b !border-neutral-dark-gray">
          {pageTabs.map((t, i) => (
            <Tabs.Tab key={i} to={t.to} name={t.name} icon={t.icon} />
          ))}

          <div className="flex flex-1 items-center justify-end gap-1">
            <LinkButton to={`${pathname}/?search=1`} variant="tertiary" size="sm" startIcon="SearchNormal1">
              Search
            </LinkButton>
            <LinkButton to={`${pathname}/?filter=1`} variant="tertiary" size="sm" startIcon="Sort">
              Filter
            </LinkButton>
            <LinkButton to={`${pathname}/add`} variant="primary" size="sm" startIcon="Add">
              Add Vehicle
            </LinkButton>
          </div>
        </Tabs>
      )}
      <RequireAccess action="view" subject="providerConfigVehicle" />
    </div>
  );
};

Vehicles.MyVehicles = MyVehicles;
Vehicles.AffiliateVehicles = AffiliateVehicles;
Vehicles.ArchivedVehicles = ArchivedVehicles;
