import { ComponentProps } from "react";
import { Button, Typography } from "@/components/atoms";
import { clsx } from "@/utils";

interface VehicleMakeButtonProps extends Omit<ComponentProps<typeof Button>, "type" | "iconName"> {
  name: string;
  src: string;
  onClick: () => void;
}

export const VehicleMakeButton = ({ onClick, className, name, src, ...props }: VehicleMakeButtonProps) => {
  return (
    <Button
      onClick={onClick}
      variant="tertiary"
      className={clsx("group flex !h-[150px] w-[150px] flex-col rounded-lg bg-white pt-5 shadow [&>span]:contents", className)}
      {...props}
    >
      <img src={src} alt={name} className="inline-block h-[56px] w-[56px] object-contain" />
      <Typography variant="action" className="capitalize group-hover:text-primary">
        {name}
      </Typography>
    </Button>
  );
};
