import { format } from "date-fns";
import { Breakdown, Typography } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { BookingHistoryActivity } from "@/redux/slices/booking/types";

export const JobHistoryActivityBreakdown = ({ activity }: { activity: BookingHistoryActivity }) => {
  const { fullTimeFormat } = useProfile();

  return (
    <div className="w-full space-y-3 bg-neutral-surface-gray p-5 sm:w-1/2">
      <Typography variant="action">Trip Driver Activity</Typography>
      <Breakdown className="[&_.typography]:leading-tight">
        <Breakdown.Item
          label="Driver On Way to Pickup:"
          value={activity?.driverOnWay ? format(new Date(activity.driverOnWay), fullTimeFormat) : "-"}
        />
        <Breakdown.Item label="Client Picked Up:" value={activity?.pickedUp ? format(new Date(activity.pickedUp), fullTimeFormat) : "-"} />
        <Breakdown.Item
          label="Driver Close to Destination:"
          value={activity?.closeToDestination ? format(new Date(activity.closeToDestination), fullTimeFormat) : "-"}
        />
        <Breakdown.Item
          label="Client Dropped Off:"
          value={activity?.droppedOff ? format(new Date(activity.droppedOff), fullTimeFormat) : "-"}
        />
        {activity?.cancelled ? (
          <Breakdown.Item label="Job Cancelled:" value={activity?.cancelled ? format(new Date(activity.cancelled), fullTimeFormat) : "-"} />
        ) : (
          <Breakdown.Item label="Driver Closed Job:" value={activity?.closed ? format(new Date(activity.closed), fullTimeFormat) : "-"} />
        )}
      </Breakdown>
    </div>
  );
};
