export const addOnsDescription: { [key: string]: string } = {
  "flight-tracking": "Keep your plans on track with live flight updates for your clients & drivers.",
};

export const addOnsIcon: { [key: string]: string } = {
  "flight-tracking": "Airplane",
  "company-manager": "Building",
  "invoicing-manager": "Receipt2",
  "offloading": "ArrangeVertical",
  "private-network": "Global",
  "rating-manager": "Star1",
  "trip-reporting": "DocumentText",
};