import { z } from "zod";

export const schema = z.object({
  type: z.string(),
  make: z.object({
    name: z.string(),
    avatar: z.string(),
  }),
  model: z.object({
    id: z.string(),
    name: z.string(),
    class: z.string(),
  }),
});

export type FormData = z.infer<typeof schema>;
