import { parse } from "tldts";

const DEFAULT_HOSTNAME = "app.rideminder.uat.fleetactions.com";
const DEV_DOMAIN = "fleetactions.com";
const appHost = import.meta.env.VITE_RIDEMINDER_APP_HOST;
const apiHost = import.meta.env.VITE_RIDEMINDER_API_HOST;
const phpHost = import.meta.env.VITE_RIDEMINDER_PHP_HOST;

export const getDefaultHeaders = (headers: Headers) => {
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");
  return headers;
};

export const getUrlStructureData = (prefix: string, providerCode?: string) => {
  if (import.meta.env.MODE === "development") {
    return {
      subdomain: `${prefix}.${providerCode ?? "rideminder"}.uat`,
      domain: DEV_DOMAIN,
    };
  } else {
    const { domain, subdomain } = parse(window.location.origin);
    return {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      subdomain: domain === DEV_DOMAIN ? subdomain!.replace("app", prefix) : prefix,
      domain: domain ?? "",
    };
  }
};

export const getApiHostUrl = (providerCode?: string) => {
  if (apiHost) return apiHost;

  const data: Record<string, string> = getUrlStructureData("api", providerCode);
  const url = "https://{subdomain}.{domain}".replace(/{\s*(\w+?)\s*}/g, (_, token) => data[token] ?? "");
  return url;
};

export const getPhpHostUrl = (providerCode?: string) => {
  if (phpHost) return phpHost;
  const data: Record<string, string> = getUrlStructureData("app", providerCode);
  const url = "https://{subdomain}.{domain}".replace(/{\s*(\w+?)\s*}/g, (_, token) => data[token] ?? "");
  return url;
};

export const getAppHostname = () => {
  return appHost ? appHost : import.meta.env.MODE === "production" ? window.location.hostname : DEFAULT_HOSTNAME;
};

export const getBaseUrl = () => import.meta.env.BASE_URL;

export const getGoogleApiKey = () => {
  return import.meta.env.VITE_RIDEMINDER_GOOGLE_API_KEY;
};

export const getStripePublishableKey = () => {
  return import.meta.env.VITE_RIDEMINDER_STRIPE_PUBLISHABLE_KEY;
};
