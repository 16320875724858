import { useFormContext } from "react-hook-form";
import AvatarPlaceholder from "@/assets/images/vehicle/avatar-placeholder.svg";
import { Button, Dropzone, Loading, Typography } from "@/components/atoms";
import { useAddAvatarMutation } from "@/redux/apis/vehicle/vehicleApi";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { getAssetUrl } from "@/utils";

export const VehicleImage = ({ vehicle }: { vehicle: Vehicle }) => {
  const [addAvatar, { isLoading }] = useAddAvatarMutation();
  const { setValue, watch } = useFormContext<{ avatar: string }>();
  const avatar = watch("avatar");

  const handleUpload = (file: File) => {
    const formData = new FormData();
    formData.append("avatar", file);

    addAvatar({ id: vehicle.uuid, body: formData })
      .unwrap()
      .then((res) => {
        setValue("avatar", res.avatar);
      });
  };

  return (
    <Dropzone
      accept={{
        "image/png": [".png"],
        "image/jpeg": [".jpeg", ".jpg"],
      }}
      maxSize={10000000}
      upload={handleUpload}
      className="-translate-x-2 self-start border-transparent p-5 transition-colors hover:border-neutral-dark-gray"
      detail={
        <div className="relative flex items-center gap-8">
          {isLoading && <Loading />}
          <img
            src={
              avatar
                ? getAssetUrl(avatar, {
                    width: 248,
                    height: 248,
                    fit: "contain",
                  })
                : AvatarPlaceholder
            }
            alt="Vehicle Image"
            className="h-[124px] w-[124px] grow-0 rounded-full"
          />
          <div className="flex max-w-[375px] flex-col">
            <Typography variant="action" className="text-black">
              Vehicle Image
            </Typography>
            <Typography className="text-neutral-dark-gray">
              Upload a vehicle image (PNG and JPG supported), we recommend a front view with the Number Plate on it
            </Typography>
            <Button variant="tertiary" size="sm" className="mt-3 -translate-x-3 self-start text-info">
              Upload
            </Button>
          </div>
        </div>
      }
    />
  );
};
