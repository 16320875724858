import { Icon, Typography } from "@/components/atoms";
import { useLayout } from "@/hooks";
import { clsx } from "@/utils";
import { SidebarLogo } from "./SidebarLogo";
import { SidebarNav } from "./SidebarNav";

export const Sidebar = ({ className, ...props }: React.ComponentProps<"aside">) => {
  const { setSidebarOpen, layout } = useLayout();
  const { sidebarOpen, fullScreen } = layout;
  const baseClass = "fixed left-0 top-0 z-50 flex h-screen transition-all sm:w-[200px] lg:w-[270px]";

  return (
    <aside
      data-testid="sidebar"
      className={clsx(
        baseClass,
        {
          "!hidden": fullScreen,
          "max-w-[70px] sm:max-w-[70px] lg:max-w-[85px]": !sidebarOpen,
        },
        className
      )}
      {...props}
    >
      <div className="flex flex-1 flex-col border-r border-neutral-gray bg-white">
        <div className="absolute right-0 top-0 z-50 -mr-4 mt-7 lg:-mr-4">
          <button
            type="button"
            className="ml-1 flex h-8 w-8 items-center justify-center rounded-full bg-white shadow"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            <Icon name={sidebarOpen ? "ArrowLeft" : "ArrowRight"} className="h-6 w-6 text-neutral-black" aria-hidden="true" />
          </button>
        </div>
        <div className="py-2.5">
          <SidebarLogo />
        </div>
        <div className="flex-1 no-scrollbar overflow-y-auto pb-2.5 h-0">
          {/* <SidebarOnboarding /> */}
          <SidebarNav />
        </div>
        <div className={clsx("flex flex-col px-5 pt-2.5 pb-[62px] gap-y-[30px]", { "pb-5": sidebarOpen })}>
          {/* <SidebarNavItem
            item={{
              name: "Add Ons",
              to: "/add-ons",
              isCustomIcon: true,
              icon: "rocket",
              isDashboard: true,
            }} 
            sidebarOpen={sidebarOpen} 
            className="bg-neutral-gray text-neutral-black"
          /> */}
          {sidebarOpen && (
            <Typography variant="small" className="text-center text-neutral-dark-gray !text-[10px]">
              © Copyright {new Date().getFullYear()} - Powered by RideMinder
            </Typography>
          )}
        </div>
      </div>
    </aside>
  );
};
