import { useState } from "react";
import { Button, Icon, Loading, Modal, TextInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useSubmitFeedbackMutation } from "@/redux/apis/content/contentApi";
import { addToast, clsx } from "@/utils";

interface ModelNotShowingModalProps extends React.HTMLAttributes<HTMLDivElement> {
  open: boolean;
  setOpen: (open: boolean) => void;
  onNotify?: () => void;
}

export const ModelNotShowingModal = ({ className, open, setOpen, onNotify, ...props }: ModelNotShowingModalProps) => {
  const [submitFeedback, { isLoading }] = useSubmitFeedbackMutation();
  const [modelName, setModelName] = useState<string>("");
  const [addtlInfo, setAddtlInfo] = useState<string>("");

  const handleNotifyClick = () => {
    if (!modelName) return;

    submitFeedback({
      for: "platform",
      category: "improvement",
      content: `Missing Vehicle:  Name of model: ${modelName} \n Additional Information: ${addtlInfo}`,
    })
      .unwrap()
      .then(() => {
        addToast("success", `Notification sent to RideMinder with the car model and details`);
        setOpen(false);
        onNotify?.();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)} className="max-w-[514px] !p-5">
      <div className={clsx("relative", className)} {...props}>
        {isLoading && <Loading />}
        <div className="flex max-w-lg flex-col ">
          <div className="mt-3 flex flex-row items-center">
            <Typography variant="title" className="flex-1">
              Model Not Showing
            </Typography>
            <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={() => setOpen(false)} />
          </div>
          <div className="mt-3 ">
            <Typography variant="paragraph">
              Please provide some details about this vehicle to help us understand its model and add it to our selection.
            </Typography>
          </div>
          <form className="mt-6 flex flex-col gap-4">
            <label htmlFor="model-name" className="flex flex-col gap-1">
              <Typography variant="paragraph">Name of the Model</Typography>
              <TextInput
                placeholder="Enter Vehicle Model"
                id="model-name"
                value={modelName}
                onChange={(e) => setModelName(e.target.value)}
                required
                autoFocus
              />
            </label>
            <label htmlFor="addtl-info" className="flex flex-col gap-1">
              <Typography variant="paragraph">Can you provide any additional information about this vehicle</Typography>
              <TextInput
                placeholder="Enter Answer"
                id="addtl-info"
                value={addtlInfo}
                onChange={(e) => setAddtlInfo(e.target.value)}
                required
              />
            </label>
          </form>
        </div>
        <div className="mt-6 ">
          <Button variant="primary" onClick={handleNotifyClick} size="lg" className="w-full">
            Notify RideMinder
          </Button>
        </div>
      </div>
    </Modal>
  );
};
