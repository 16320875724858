import { LngLatBounds } from "mapbox-gl";
import { BookingTripLog } from "@/redux/slices/booking/types";
import { BookingMarker, BookingTripPoint } from "./types";

export const extractMarkers = (tripLog: BookingTripLog): BookingMarker[] => {
  const {
    bookingStops: { pickupLocationData, waypointsLocationData, dropoffLocationData },
    tripStops: { enRouteToPickupLocationData, passengerDroppedOffLocationData, passengerPickedUpLocationData },
    driverCurrentLocationData,
  } = tripLog;

  const tripMarkers: BookingMarker[] = [];

  if (pickupLocationData) {
    tripMarkers.push({
      id: "pickup",
      type: "pickup",
      location: [pickupLocationData.lng, pickupLocationData.lat],
      address: pickupLocationData.address,
      title: "Pickup location",
    });
  }

  if (waypointsLocationData) {
    tripMarkers.push(
      ...waypointsLocationData.map(
        (waypoint, idx) =>
          ({
            id: "waypoint-" + idx,
            type: "stop",
            location: [waypoint.lng, waypoint.lat],
            address: waypoint.address,
            title: `Scheduled Stop ${idx + 1}`,
          } as BookingMarker)
      )
    );
  }

  if (dropoffLocationData) {
    tripMarkers.push({
      id: "dropoff",
      type: "destination",
      location: [dropoffLocationData.lng, dropoffLocationData.lat],
      address: dropoffLocationData.address,
      title: "Destination",
    });
  }

  if (driverCurrentLocationData) {
    tripMarkers.push({
      id: "driver",
      type: "driver",
      location: [driverCurrentLocationData.lng, driverCurrentLocationData.lat],
      address: "",
      dateTime: driverCurrentLocationData.dateTime,
      title: "Driver location",
    } as BookingMarker);
  }

  if (enRouteToPickupLocationData) {
    tripMarkers.push({
      id: "en-route-to-pickup",
      type: "en-route",
      location: [enRouteToPickupLocationData.lng, enRouteToPickupLocationData.lat],
      address: "",
      dateTime: enRouteToPickupLocationData.dateTime,
      title: "En route to pickup location",
    } as BookingMarker);
  }

  if (passengerDroppedOffLocationData) {
    tripMarkers.push({
      id: "client-dropped-off",
      type: "trip-dropoff",
      location: [passengerDroppedOffLocationData.lng, passengerDroppedOffLocationData.lat],
      address: "",
      dateTime: passengerDroppedOffLocationData.dateTime,
      title: "Client Dropped Off",
    } as BookingMarker);
  }

  if (passengerPickedUpLocationData) {
    tripMarkers.push({
      id: "client-picked-up",
      type: "trip-pickup",
      location: [passengerPickedUpLocationData.lng, passengerPickedUpLocationData.lat],
      address: "",
      dateTime: passengerPickedUpLocationData.dateTime,
      title: "Client Picked Up",
    } as BookingMarker);
  }

  return tripMarkers;
};

export const extractPolylines = (tripLog: BookingTripLog) => {
  const {
    polylineEncodedPaths: { onRoute, onTrip },
  } = tripLog;

  return {
    onRoute: onRoute ?? null,
    onTrip: onTrip ?? null,
  };
};

export const extractTripPoints = (locationData: BookingTripLog["locationData"], stopsData: BookingTripLog["tripStops"]) => {
  if (!locationData) return [];

  const passengerPickupDateTime = stopsData.passengerPickedUpLocationData?.dateTime
    ? new Date(stopsData.passengerPickedUpLocationData?.dateTime)
    : null;

  return locationData.map(
    (point, idx) =>
      ({
        ...point,
        id: `location-point-${idx}`,
        location: [point.lng, point.lat],
        type: passengerPickupDateTime && new Date(point.dateTime) < passengerPickupDateTime ? "on-trip" : "on-route",
      } as BookingTripPoint)
  );
};

//bounds helpers
export const getAllLocationBounds = (tripLog: BookingTripLog) => {
  const {
    locationData,
    bookingStops: { pickupLocationData, dropoffLocationData, waypointsLocationData },
  } = tripLog;

  const bounds = new LngLatBounds();

  if (locationData) {
    for (const location of locationData) {
      bounds.extend(location);
    }
  }

  if (pickupLocationData && pickupLocationData.lat && pickupLocationData.lng) {
    bounds.extend(pickupLocationData);
  }

  for (const location of waypointsLocationData) {
    if (location.lat && location.lng) {
      bounds.extend(location);
    }
  }

  if (dropoffLocationData && dropoffLocationData.lat && dropoffLocationData.lng) {
    bounds.extend(dropoffLocationData);
  }

  return bounds;
};

export const getDriverOnWayBounds = (tripLog: BookingTripLog) => {
  const {
    driverCurrentLocationData,
    bookingStops: { pickupLocationData },
  } = tripLog;

  const bounds = new LngLatBounds();

  if (driverCurrentLocationData) {
    bounds.extend(driverCurrentLocationData);
  }

  if (pickupLocationData) {
    bounds.extend(pickupLocationData);
  }

  return bounds;
};

export const getCommencedBounds = (tripLog: BookingTripLog) => {
  const bounds = new LngLatBounds();
  const points = getCommencedWaypoints(tripLog);

  if (!points) return bounds;

  for (const point of points) {
    bounds.extend({
      lng: point[0],
      lat: point[1],
    });
  }

  return bounds;
};

//waypoint helpers
export const getAllWayPoints = (tripLog: BookingTripLog) => {
  const { bookingStops } = tripLog;
  const { pickupLocationData, dropoffLocationData, waypointsLocationData } = bookingStops;

  if (!pickupLocationData || !dropoffLocationData) return null;

  const startPoint = [pickupLocationData.lng, pickupLocationData.lat];
  const wayPoints = waypointsLocationData.map((waypoint) => [waypoint.lng, waypoint.lat]);
  const endPoint = [dropoffLocationData.lng, dropoffLocationData.lat];
  return [startPoint, ...wayPoints, endPoint];
};

export const getDriverOnWayPoints = (tripLog: BookingTripLog) => {
  const {
    bookingStops: { pickupLocationData },
    driverCurrentLocationData,
  } = tripLog;

  if (!pickupLocationData) return null;
  if (!driverCurrentLocationData) return null;

  return [
    [driverCurrentLocationData.lng, driverCurrentLocationData.lat],
    [pickupLocationData.lng, pickupLocationData.lat],
  ];
};

export const getCommencedWaypoints = (tripLog: BookingTripLog) => {
  // Here we what to get the waypoints of the driver to the next destination,

  const {
    bookingStops: { pickupLocationData, dropoffLocationData, waypointsLocationData: stopsLocationData },
    driverCurrentLocationData,
  } = tripLog;

  if (!driverCurrentLocationData) return null;
  if (!pickupLocationData || !dropoffLocationData) return null;

  //the first stop without geofence entry is the next stop, return driver to next stop
  const nextStop = stopsLocationData.find((stop) => !stop.geofenceEntry);

  if (nextStop) {
    return [
      [driverCurrentLocationData.lng, driverCurrentLocationData.lat],
      [nextStop.lng, nextStop.lat],
    ];
  }

  //if all stops have geofence entry, return driver to the dropoff location
  return [
    [driverCurrentLocationData.lng, driverCurrentLocationData.lat],
    [dropoffLocationData.lng, dropoffLocationData.lat],
  ];
};

export const showBookingETA = (tripLog: BookingTripLog) => {
  const {
    job: { isInProgress, status },
    driverCurrentLocationData,
    bookingStops: { dropoffLocationData },
  } = tripLog;

  const excludedStatuses = ["on_way_to_job", "complete_awaiting_review", "closed", "cancelled"];

  return (
    isInProgress &&
    !excludedStatuses.includes(status) &&
    !driverCurrentLocationData &&
    dropoffLocationData &&
    !dropoffLocationData.geofenceEntry
  );
};
