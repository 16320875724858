import { createContext, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useLazyGetProviderQuery } from "@/redux/apis/auth/authApi";
import { useLazyGetPayInvoiceQuery } from "@/redux/apis/payInvoice/payInvoiceApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setActiveOperator } from "@/redux/slices/operator/operatorSlice";
import { payInvoiceSelector } from "@/redux/slices/payInvoice/selectors";
import { PayInvoice } from "@/redux/slices/payInvoice/types";

interface PayInvoicePageState {
  params: {
    providerId: string;
    companyId: string;
    invoiceId: string;
    invoiceKey: string;
  };
  payInvoice: PayInvoice;
  // activeModal: "accept" | "reject" | undefined;
  // setActiveModal: React.Dispatch<React.SetStateAction<"accept" | "reject" | undefined>>;
}

const PayInvoicePageContext = createContext<PayInvoicePageState | null>(null);

export const PayInvoicePageContextProvider = ({ children }: React.PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const { providerId, companyId, invoiceId, invoiceKey } = useParams();
  const [getProvider, { isError: isProviderError }] = useLazyGetProviderQuery();
  const [getPayInvoice, { isError: isPayInvoiceError }] = useLazyGetPayInvoiceQuery();
  const payInvoice = useAppSelector(payInvoiceSelector);
  // const [activeModal, setActiveModal] = useState<"accept" | "reject" | undefined>(undefined);

  if (!providerId || !companyId || !invoiceId || !invoiceKey) throw new Error("Missing invoice payment params");
  if (isProviderError) throw new Error("Provider not found");
  if (isPayInvoiceError) throw new Error("Invoice not found");

  const params = useMemo(() => {
    return { providerId, companyId, invoiceId, invoiceKey };
  }, [providerId, companyId, invoiceId, invoiceKey]);

  useEffect(() => {
    getProvider(providerId)
      .unwrap()
      .then((operator) => dispatch(setActiveOperator(operator)));
  }, [dispatch, getProvider, providerId]);

  useEffect(() => {
    getPayInvoice(params);
  }, [getPayInvoice, params]);

  return (
    <PayInvoicePageContext.Provider
      value={{
        params,
        payInvoice: payInvoice!,
        // activeModal,
        // setActiveModal,
      }}
    >
      {children}
    </PayInvoicePageContext.Provider>
  );
};

export const usePayInvoicePageContext = () => {
  const context = useContext(PayInvoicePageContext);
  if (!context) throw new Error("usePayInvoicePageContext must be used within PayInvoicePageContextProvider");

  return context;
};
