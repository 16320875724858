import { isEmpty } from "ramda";
import { useCallback, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, DropdownSelect, Icon, Loading, Modal, Spinner, TextInput, Typography } from "@/components/atoms";
import { getErrorData, getErrorMessages, isErrorResponse } from "@/helpers/reduxHelpers";
import { useOperator } from "@/hooks";
import { useGetCountryStatesQuery } from "@/redux/apis/resource/resourceApi";
import { useCreateVehicleMutation } from "@/redux/apis/vehicle/vehicleApi";
import { RawErrorResponse } from "@/redux/types";
import { addToast, clsx } from "@/utils";
import { VehicleInArchiveModal } from "./VehicleInArchiveModal";

interface ManualVerifyModalProps extends React.HTMLAttributes<HTMLDivElement> {
  modelMake: string;
  modelId: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSuccess: (vehicleId: string) => void;
}

export const ManualVerifyModal = ({ modelMake, modelId, className, open, setOpen, onSuccess, ...props }: ManualVerifyModalProps) => {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const { country, name: operatorName } = useOperator();
  const { isFetching, data: response } = useGetCountryStatesQuery(country);
  const [createVehicle, { isLoading, error, reset }] = useCreateVehicleMutation();
  const [numberPlate, setNumberPlate] = useState<string>("");
  const [state, setState] = useState<string>("");

  const options = useMemo(
    () => response?.data.states.map((state) => ({ name: state.name, value: state.id })) ?? [],
    [response?.data.states]
  );

  const errorType = useMemo(() => {
    if (!error || !isErrorResponse(error)) return undefined;

    const messages = getErrorMessages(error || {}).join(", ");
    if (messages.includes("Vehicle verification failed")) return "verification-failed";

    const errorData = getErrorData(error as RawErrorResponse);
    if (!errorData) return "verification-failed";

    const { error: msg, data } = errorData;

    if (msg === "VEHICLE_EXISTS" && data?.is_archived) return `vehicle-archived-${data.vehicle_uuid}`;
    else if (msg) return "vehicle-exists";
    else return "verification-failed";
  }, [error]);

  const renderAlert = useCallback(() => {
    switch (errorType) {
      case "vehicle-exists":
        return <Alert className="mt-4" type="warning" message={`Oops! Looks like this vehicle is already in ${operatorName}`} />;

      case "verification-failed":
        return <Alert className="mt-4" type="danger" message="Verification failed. Please verify the license plate and try again" />;

      default:
        return null;
    }
  }, [errorType, operatorName]);

  const handleReactivate = () => {
    if (!errorType) return;

    const vehicleId = errorType.split("-")[2];
    navigate(`../../archived-vehicles/?filter=${vehicleId}`);
  };

  const handleAddVehicle = () => {
    if (!numberPlate || !state) return;

    createVehicle({
      registration_number: numberPlate,
      state_uuid: state,
      vehicle_model_uuid: modelId,
      verify: false,
    })
      .unwrap()
      .then((data) => {
        addToast("success", `Vehicle has been successfully added in My Vehicles.`);
        onSuccess(data.uuid);
      })
      .catch((e) => {
        console.warn(e);
        inputRef.current?.focus();
      });
  };

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)} className="!max-w-[550px] !p-5">
        <div className={clsx("relative", className)} {...props}>
          {isLoading && <Loading />}
          <div className="flex justify-end">
            <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={() => setOpen(false)} />
          </div>
          <div className="flex max-w-lg flex-col">
            {renderAlert()}
            <Typography variant="title" className="mt-3 flex-1">
              Add Vehicle
            </Typography>
            <form className="mt-6 flex flex-col gap-4">
              <label htmlFor="model-name" className="flex flex-col gap-1">
                <Typography variant="paragraph">Enter your {modelMake} Number Plate number</Typography>
                <TextInput
                  placeholder="000-0000"
                  id="number-plate"
                  value={numberPlate}
                  onChange={(e) => setNumberPlate(e.target.value)}
                  hasError={errorType === "verification-failed"}
                  hasWarning={errorType === "vehicle-exists"}
                  required
                  autoFocus
                  ref={inputRef}
                />
              </label>
              <label htmlFor="state" className="flex flex-col gap-1">
                <Typography variant="paragraph">Select the State of your Vehicle</Typography>

                <DropdownSelect
                  className="w-full"
                  placeholder="Select State"
                  options={options}
                  value={state}
                  onChange={setState}
                  disabled={isEmpty(options) || isFetching}
                />
              </label>
            </form>
          </div>
          <div className="mt-6 ">
            <Button variant="primary" onClick={handleAddVehicle} size="lg" className="w-full" disabled={isLoading}>
              {isLoading && <Spinner />}
              Add Vehicle
            </Button>
          </div>
        </div>
      </Modal>
      <VehicleInArchiveModal
        open={Boolean(errorType && errorType.includes("vehicle-archived"))}
        onClose={() => reset()}
        onReactivate={handleReactivate}
      />
    </>
  );
};
