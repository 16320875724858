import { endOfDay, formatRFC3339 } from "date-fns";
import { useMemo } from "react";
import { usePagination, useSearch, useSorting } from "@/hooks";
import { GetClientsParams } from "@/redux/apis/client/types";
import { useBookingDates } from "./useBookingDates";
import { useBookingFiltersV2 } from "./useBookingFiltersV2";

export const useGetBookingsParams = () => {
  const { page, pageSize } = usePagination("bookings", 100);
  const { sorting, setSorting, sortKey, direction } = useSorting();
  const { params: filterParams, filters, setFilters } = useBookingFiltersV2();
  const [search, setSearch] = useSearch();
  const [selectedDates, setSelectedDates] = useBookingDates();

  const params: GetClientsParams = useMemo(
    () => ({
      page,
      page_size: pageSize,
      sort: sortKey,
      direction,
      start_date_time: formatRFC3339(selectedDates.dateFrom),
      end_date_time: formatRFC3339(endOfDay(selectedDates.dateTo)),
      search,
      ...filterParams,
    }),
    [page, pageSize, sortKey, direction, selectedDates, search, filterParams]
  );

  return {
    params,
    sorting,
    setSorting,
    sortKey,
    direction,
    filters,
    setFilters,
    search,
    setSearch,
    selectedDates,
    setSelectedDates,
  };
};
