import { useOperator } from "@/hooks";
import { AddOnModal } from "./modals/AddOnModal";
import { FlightTrackingModal } from "./modals/FlightTrackingModal";
import { SelectedAddOn } from "./types";

interface AddOnsModalsProps extends React.HTMLAttributes<HTMLDivElement> {
  selected: SelectedAddOn;
  onClose: () => void;
}

export const AddOnsModals = ({ selected, onClose, className, ...props }: AddOnsModalsProps) => {
  const { roles } = useOperator();
  const isAdmin = roles.includes("Fa_Access") || roles.includes("Owner") || roles.includes("Admin");

  // an array of addon/feature types which have a unique modal design
  const independentModals = ["flight-tracking"];

  return (
    <div className={className} {...props}>
      <FlightTrackingModal 
        open={selected.identifier === "flight-tracking"} 
        onClose={onClose} 
        isAdmin={isAdmin}
      />
      {selected.type === "addon" && (
        <AddOnModal
          identifier={selected.identifier}
          open={!independentModals.includes(selected.identifier)}
          onClose={onClose} 
          isAdmin={isAdmin}
        />
      )}
    </div>
  );
};
