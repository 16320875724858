import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { clsx } from "@/utils";
import { Icon } from "..";

export interface DropdownCheckbox extends React.HTMLAttributes<HTMLDivElement> {
  placeholder?: string;
  selectedCount?: number;
  hasError?: boolean;
  getSelectedLabel?: (selected?: number) => string;
}

export const DropdownCheckbox = ({
  placeholder = "Select",
  selectedCount,
  getSelectedLabel,
  hasError = false,
  className,
  children,
  ...props
}: DropdownCheckbox) => {
  const errorClass = {
    "!border-danger": hasError,
  };

  const label = getSelectedLabel ? getSelectedLabel(selectedCount) : `${selectedCount} Selected`;

  return (
    <Popover className={clsx("relative", className, errorClass)} {...props}>
      {() => (
        <>
          <Popover.Button className="group min-h-[48px] w-full cursor-pointer rounded-md border border-neutral-mid-gray bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:outline-0 sm:text-base">
            {selectedCount ? (
              <span className="block truncate font-medium">{label}</span>
            ) : (
              <span className="block truncate font-medium text-neutral-mid-gray">{placeholder}</span>
            )}
            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
              <Icon name="arrow-down" isCustom className="h-5 w-5 text-black" />
            </span>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="dropdown-panel absolute left-1/2 z-10 mt-3 w-full -translate-x-1/2 transform sm:px-0">
              <div className="mb-6 flex flex-col gap-6 overflow-hidden rounded bg-white p-5 shadow-md ring-1 ring-neutral-gray">
                {children}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
